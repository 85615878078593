import { makeObservable, observable } from 'mobx';

import DataMixin from 'utils/stores/DataMixin';

import { EntityModules } from 'core/constants';
import API from 'utils/api';
import RootStore from 'stores/Root';
import EntitiesActivities from 'stores/EntitiesActivities';

export default class EntityDetails extends DataMixin {
  entityType: EntityModules;

  path: string;

  detailsPath: (uuid: string) => string;

  @observable activities?: EntitiesActivities;

  prefix = '';

  constructor(
    rootStore: RootStore,
    api: typeof API,
    entityType: EntityModules,
    path: string,
    detailsPath: (uuid: string) => string
  ) {
    super(rootStore, api);
    makeObservable(this);
    this.entityType = entityType;
    this.path = path;
    this.detailsPath = detailsPath;
    this.prefix = `${entityType}__`;
  }

  reset(): void {
    this.isFailure = false;
    this.isLoaded = false;
  }
}
