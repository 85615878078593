import { Autocomplete, Checkbox, FilterOptionsState, createFilterOptions } from '@mui/material';
import React from 'react';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CancelIcon from '@mui/icons-material/Cancel';

import { CenteredCircularProgress } from 'components/Progress';

import { useSearch } from 'utils/hooks/search';

import { StyledTextField } from 'components/Input/styles';
import API from 'utils/api';

import Chip from 'components/Chip';

import { SearchResponse, UuidableName } from 'utils/api/types';

import { StyledOption } from '../styles';
import { TagsAutocompleteProps } from './types';

const loadingOption = { uuid: 'loading', name: 'Loading...' } as SearchResponse;
type FilteringType = UuidableName | { inputValue: string; name: string } | null;
const filterOptions = createFilterOptions<FilteringType>();

const TagsAutocomplete: React.FunctionComponent<TagsAutocompleteProps> = ({ tags, setFieldValue }) => {
  const {
    results: tagsSearchResults,
    searchQuery: tagsSearchQuery,
    loading: tagsLoading,
  } = useSearch(API.searchDeviceTags, undefined, { limit: 5 });

  return (
    <Autocomplete
      multiple
      id="tag"
      placeholder={tags.length === 0 ? 'Choose tags(s)' : ''}
      options={tagsSearchResults}
      value={tags}
      disableCloseOnSelect
      renderOption={(renderOption, option, state) => {
        if (option === null) {
          return null;
        }
        if (option.uuid === loadingOption.uuid) {
          return (
            <StyledOption>
              <CenteredCircularProgress />
            </StyledOption>
          );
        }
        return (
          <StyledOption {...renderOption}>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="medium" />}
              checkedIcon={<CheckBoxIcon fontSize="medium" />}
              checked={state.selected}
            />
            {option.name}
          </StyledOption>
        );
      }}
      getOptionLabel={(option) => {
        if (option === null) {
          return 'NULL';
        }
        if (typeof option === 'string') {
          return option;
        }
        // @ts-ignore
        if ('inputValue' in option) {
          return option.inputValue as string;
        }
        return option.name;
      }}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          placeholder={tags.length === 0 ? 'Choose option(s)' : ''}
          onChange={(e) => {
            tagsSearchQuery(e.target.value);
          }}
        />
      )}
      // @ts-ignore
      filterOptions={(options, params) => {
        const filtered = filterOptions(options, params as FilterOptionsState<FilteringType>);

        const { inputValue } = params;
        const exists = options.some((option) => option !== null && inputValue === option.name);
        if (inputValue !== '' && !exists) {
          filtered.push({
            inputValue,
            name: `Add "${inputValue}"`,
          });
        }

        return filtered;
      }}
      renderTags={(tagValue, getTagProps) => (
        <>
          {tagValue.map((option, index) =>
            option ? (
              <Chip
                {...getTagProps({ index })}
                label={`${option.name}`}
                size="small"
                colors={{
                  foreground: '#044B99',
                  background: 'rgba(7, 114, 229, 0.08)',
                }}
                iconRight={
                  <CancelIcon
                    style={{
                      color: 'rgba(7, 114, 229, 1)',
                      opacity: '0.7',
                    }}
                  />
                }
                iconRightDisableInnerMargin
              />
            ) : null
          )}
        </>
      )}
      loading={tagsLoading}
      onChange={(_, value) => {
        const mappedTags = value.map((tag) => {
          // @ts-ignore
          if ('inputValue' in tag) {
            return {
              name: tag.inputValue,
              uuid: crypto.randomUUID(),
            };
          }
          return tag;
        });
        setFieldValue('tags', mappedTags);
        tagsSearchQuery('');
      }}
      isOptionEqualToValue={(option: SearchResponse | null, val: SearchResponse | null) =>
        val?.uuid !== undefined && val?.uuid === option?.uuid
      }
      fullWidth
      freeSolo
      forcePopupIcon
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
    />
  );
};

export default TagsAutocomplete;
