import React from 'react';

import { observer } from 'mobx-react';

import EventItem from 'vatix-ui/lib/components/Feed/EventItem';

import { TaskStatusNames } from 'core/constants';

import { StatusActivityResponse, UuidableName } from 'utils/api/types';

interface StatusProps {
  activity: StatusActivityResponse;
  sessionUser: UuidableName;
}

const Status: React.FunctionComponent<StatusProps> = ({ activity, sessionUser }) => (
  <EventItem activity={activity} sessionUser={sessionUser}>
    changed status to {TaskStatusNames[activity.status]}
  </EventItem>
);

export default observer(Status);
