import React from 'react';

import { Box } from '@mui/material';

import { CenteredCircularProgress } from 'components/Progress';

import { useSearch } from 'utils/hooks/search';

import { TaskListResponse, UuidableSubject, UuidableName, SearchResponse } from 'utils/api/types';

import API from 'utils/api';

import { StyledAutocomplete, StyledTextField } from 'components/Input/styles';

import { StyledMenuItem, EditTaskRowName } from '../../EditTaskProperties/styles';

const loadingOption = { uuid: 'loading', name: 'Loading...' } as SearchResponse;
const nullOption = { uuid: 'null', name: '' } as SearchResponse;

const Row: React.FC<{ name: string; children: React.ReactNode; id?: string }> = ({
  name,
  children,
  id,
}): React.ReactElement => (
  <div key={id}>
    <EditTaskRowName>{name}</EditTaskRowName>
    <div>{children}</div>
  </div>
);

const IncidentField: React.FunctionComponent<{
  updateField: (field: string, value: string | UuidableSubject) => void;
  taskDetailsData: TaskListResponse;
}> = ({ updateField, taskDetailsData }): React.ReactElement => {
  const {
    results: incidentsList,
    loading: loadingIncidents,
    searchQuery: searchQueryIncidents,
  } = useSearch(API.searchIncidents, undefined, { limit: 10 });

  const [selectedIncident, setSelectedIncident] = React.useState<SearchResponse | null | undefined>(undefined);

  const onChangeSearch = async (s: string): Promise<void> => {
    await searchQueryIncidents(s);
  };

  React.useEffect(() => {
    setSelectedIncident({
      name: taskDetailsData.customIncident ? taskDetailsData.customIncident.subject : '',
      uuid: taskDetailsData.customIncident ? taskDetailsData.customIncident.uuid : '',
    });
  }, []);

  return (
    <Row name="Incident" id="field-2">
      <StyledAutocomplete
        key={`${selectedIncident?.name}_${selectedIncident?.uuid}`}
        id="incident-dropdown-field"
        options={incidentsList}
        value={selectedIncident === undefined ? nullOption : selectedIncident}
        renderOption={(props, option: UuidableName) => {
          const { ...restProps } = props;
          return option.uuid === loadingOption.uuid ? (
            <CenteredCircularProgress />
          ) : (
            <Box sx={{ alignItems: 'center' }}>
              <StyledMenuItem
                key={`${option.uuid}`}
                {...restProps}
                value={option.uuid}
                onClick={() => {
                  setSelectedIncident({
                    name: option.name,
                    uuid: option.uuid,
                  });
                  updateField('customIncident', { uuid: option.uuid, subject: option.name });
                }}
              >
                {' '}
                {option.name}{' '}
              </StyledMenuItem>
            </Box>
          );
        }}
        getOptionLabel={(option: SearchResponse) => option?.name as string}
        getOptionDisabled={(option: SearchResponse) =>
          option.uuid === loadingOption.uuid || option.uuid === selectedIncident?.uuid
        }
        renderInput={(params) => (
          <StyledTextField
            {...params}
            onChange={(e) => {
              onChangeSearch(e.target.value);
            }}
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
            placeholder="Search"
          />
        )}
        loading={loadingIncidents}
        fullWidth
        disableClearable={!selectedIncident?.uuid}
        onChange={() => {
          setSelectedIncident(undefined);
          searchQueryIncidents('');
        }}
        onInputChange={(_, __, reason) => {
          if (reason === 'clear') {
            updateField('customIncident', { uuid: '', subject: '' });
          }
        }}
      />
    </Row>
  );
};

export default IncidentField;
