import React from 'react';

import { WrapperWithOffset } from 'vatix-ui/lib/components/Container';

import DataError from '../DataError';

import { DataErrorProps } from '../DataError/types';

const WrappedDataError: React.FunctionComponent<Partial<DataErrorProps>> = (props): React.ReactElement => (
  <WrapperWithOffset>
    <DataError {...props} />
  </WrapperWithOffset>
);

export default WrappedDataError;
