import React from 'react';
import FeedComponent from 'vatix-ui/lib/components/Feed';
import { DefaultFeedMapping } from 'vatix-ui/lib/components/Feed/Feed';

// eslint-disable-next-line max-len
import RedAlertActivity from 'containers/AlarmDetails/components/AlarmActivities/AlarmActivitiesContent/components/RedAlertActivity';

import { SessionStoreWithUser } from 'stores/Session/types';
import { useStore } from 'utils/hooks/store';
import DeviceActivitiesStore from 'stores/DeviceDetails/DeviceActivities';
import { ActivityType } from 'utils/stores/Activities/constants';
import API from 'utils/api';
import { USER_TAG_BASE } from 'core/constants';

import { ActivityMapping } from 'utils/api/types';

import Assignment from './components/Assignment';
import TestDeviceActivity from './components/TestDeviceActivity';

const activityTypeToComponent = ({
  ...DefaultFeedMapping,
  [ActivityType.DeviceAssignment]: Assignment,
  [ActivityType.RedAlert]: RedAlertActivity,
  [ActivityType.TestDevice]: TestDeviceActivity,
} as unknown) as ActivityMapping;

const DeviceActivitiesContent: React.FunctionComponent = () => {
  const { deviceDetails, session: sessionRaw } = useStore();
  const session = sessionRaw as SessionStoreWithUser;
  const activities = deviceDetails.activities as DeviceActivitiesStore;

  return (
    <FeedComponent
      aria-label="device-activities"
      originalActivities={activities}
      onDeleteComment={activities.deleteMessage}
      onDeleteFile={activities.deleteFile}
      mapping={activityTypeToComponent}
      downloadFile={API.downloadFile}
      sessionUser={session.user}
      usersSearch={API.searchUsers}
      userTagBaseUrl={USER_TAG_BASE}
    />
  );
};

export default DeviceActivitiesContent;
