export const humanFileSize = (size: number, round?: boolean): string => {
  const i = size > 0 ? Math.floor(Math.log(size) / Math.log(1024)) : 0;

  let fileSize: number;
  if (round) {
    fileSize = Math.ceil(parseFloat((size / 1024 ** i).toFixed(2)));
  } else {
    fileSize = parseFloat((size / 1024 ** i).toFixed(2));
  }
  return `${fileSize} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
};
