import { action, observable, runInAction, makeObservable } from 'mobx';
import { eventBus, subscribe } from 'mobx-event-bus2';
import { AxiosError } from 'axios';

import API from 'utils/api';
import { EventType } from 'utils/events/constants';
import Logger from 'utils/logger';
import { DeviceDetailsResponse, DeviceUpdateData } from 'utils/api/types';
import { ActionEvent, ReverseGeocodedLocationPayload } from 'utils/events/types';
import CollapsibleMap from 'utils/stores/CollapsibleMap';
import LocationDetails from 'utils/stores/LocationDetails';
import LocationHistory from 'utils/stores/LocationHistory';
import { LoadLocations } from 'utils/stores/LocationHistory/types';
import DeviceActivities from 'stores/DeviceDetails/DeviceActivities';
import { LOCATION_API_REFRESH_INTERVAL, LOCATION_HISTORY_PERIOD } from 'core/constants';
import { isBadRequest, isNotFound } from 'utils/api/errors';

import RootStore from '../Root';

export default class DeviceDetailsStore {
  store: RootStore;

  api: typeof API;

  collapsibleMap: CollapsibleMap;

  @observable isLoaded = false;

  @observable error?: AxiosError;

  @observable details?: DeviceDetailsResponse;

  locationHistory?: LocationHistory;

  locationDetails: LocationDetails;

  costCentre: string | null = null;

  @observable activities?: DeviceActivities;

  constructor(rootStore: RootStore, api: typeof API) {
    makeObservable(this);
    this.store = rootStore;
    this.api = api;

    this.collapsibleMap = new CollapsibleMap(this.store);
    this.locationDetails = new LocationDetails(this.store, this.api);

    eventBus.register(this);
  }

  loadDeviceLocations(deviceId: string): LoadLocations {
    return (period?: keyof typeof LOCATION_HISTORY_PERIOD) =>
      this.api.loadDeviceLocations(deviceId, { period }, LOCATION_API_REFRESH_INTERVAL)();
  }

  @action.bound
  async loadDetails(deviceId: string): Promise<void> {
    this.isLoaded = false;

    try {
      const { data } = await this.api.loadDeviceDetails(deviceId)();
      runInAction(() => {
        this.error = undefined;
        this.details = data;
        this.locationHistory = new LocationHistory(this.loadDeviceLocations(data.uuid));
        this.activities = new DeviceActivities(this.store, this.api, deviceId);
      });
    } catch (e) {
      runInAction(() => {
        // @ts-ignore
        this.error = e;
      });
      // @ts-ignore
      if (!isNotFound(e)) {
        Logger.error(`Invalid load device details API response. Device id: ${deviceId}`, e);
      }
    } finally {
      runInAction(() => {
        this.isLoaded = true;
      });
    }
  }

  @action.bound
  async updateDevice(deviceData: DeviceUpdateData): Promise<boolean> {
    try {
      const { data } = await this.api.updateDevice(this.details?.uuid as string, deviceData)();
      this.store.notification.enqueueSuccessSnackbar('Device updated successfully');
      runInAction(() => {
        this.details = data;
      });
      return true;
    } catch (e) {
      // @ts-ignore
      if (!isBadRequest(e)) {
        Logger.error('Invalid update user details API response', e);
      }
      this.store.notification.enqueueErrorSnackbar('Could not update device details');
      throw e;
    }
  }

  @subscribe(EventType.ReverseGeocodedLocation)
  @action
  reverseGeocodedLocation({ payload }: ActionEvent<ReverseGeocodedLocationPayload>): void {
    const lastLocation = this.details?.lastLocation;
    if (!lastLocation || payload.uuid !== lastLocation.uuid) {
      return;
    }

    lastLocation.address = payload.address;
  }

  @subscribe(EventType.LoggedOut)
  @action
  reset(): void {
    this.isLoaded = false;
    this.error = undefined;
    this.details = undefined;

    this.collapsibleMap.reset();
    if (this.activities) {
      this.activities.reset();
      eventBus.unregister(this.activities);
      this.activities = undefined;
    }
    if (this.locationHistory) {
      this.locationHistory.reset();
      this.locationHistory = undefined;
    }
  }
}
