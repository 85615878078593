import React from 'react';
import DetailsLoader from 'vatix-ui/lib/components/DetailsLoader';

import Record from 'vatix-ui/lib/containers/Record/Record';

import { useRouteMatch } from 'react-router-dom';

import { CenteredCircularProgress } from 'components/Progress';

import { useNavBarHeight } from 'utils/hooks/navbar';

import { isNotFound } from 'utils/api/errors';
import { useStore } from 'utils/hooks/store';

import AlarmResponseProfile from 'components/AlarmResponseProfile';

import { AlarmDetailsResponse } from 'utils/api/types';

import DataError from 'containers/Error/DataError';

import { NotFound } from 'containers/Error/Pages';

import { TabsContainer, Wrapper } from './styles';
import AlarmCard from './components/AlarmCard';
import AlarmDetailsHeader from './components/AlarmDetailsHeader';
import AlarmActivitiesContent from './components/AlarmActivities/AlarmActivitiesContent';
import AlarmActivitiesFooter from './components/AlarmActivities/AlarmActivitiesFooter';

const AlarmDetails: React.FunctionComponent = (): React.ReactElement => {
  const { alarmDetails } = useStore();
  const match = useRouteMatch();

  const navBarHeight = useNavBarHeight();

  return (
    <DetailsLoader
      match={match}
      // @ts-ignore
      routeParam="alarmId"
      store={alarmDetails}
    >
      {({ isLoaded, error, reload }): React.ReactElement => {
        if (!isLoaded) {
          return <CenteredCircularProgress />;
        }

        if (error) {
          if (isNotFound(error)) {
            return <NotFound />;
          }
          return <DataError onReload={reload} />;
        }

        return (
          <Record
            Header={<AlarmDetailsHeader />}
            Content={() => (
              <Wrapper>
                <TabsContainer>
                  <AlarmCard details={alarmDetails.details as AlarmDetailsResponse} />
                  <AlarmResponseProfile alarmResponseProfile={alarmDetails.details?.alarmResponseProfile} />
                </TabsContainer>
              </Wrapper>
            )}
            Activities={{
              Content: <AlarmActivitiesContent />,
              Footer: <AlarmActivitiesFooter />,
            }}
            disableMaxContentHeight
            navBarHeight={navBarHeight}
          />
        );
      }}
    </DetailsLoader>
  );
};

export default AlarmDetails;
