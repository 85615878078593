import PageFactory from '../PageFactory';
import SystemErrorIllustration from './SystemErrorIllustration';

const SystemError = (): React.ReactNode =>
  PageFactory(SystemErrorIllustration, 'Something Went Wrong', [
    'The page is currently unavailable.',
    'Please try again later or contact support@vatix.com',
  ]);

export default SystemError;
