import styled from 'styled-components';

import Avatar from 'components/Avatar/Avatar';

export const UserSection = styled.div`
  display: flex;
  align-items: center;
`;

export const UserNameValue = styled.p`
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const StyledAvatar = styled(Avatar)`
  margin-right: 8px;
`;
