import React from 'react';
import { observer } from 'mobx-react';

import AddCommentActivities from 'vatix-ui/lib/components/AddCommentActivities';
import FeedComponent from 'vatix-ui/lib/components/Feed';
import { DefaultFeedMapping } from 'vatix-ui/lib/components/Feed/Feed';

import { CenteredCircularProgress } from 'components/Progress';

import DataError from 'containers/Error/DataError';

import { ScrollableContainer, CommentLayout } from 'containers/UserDetails/components/UserActivities/styles';

import API from 'utils/api';

import { USER_TAG_BASE } from 'core/constants';

import { ActivityMapping } from 'utils/api/types';

import { useStore } from 'utils/hooks/store';

import SessionUser from 'stores/Session/SessionUser';

import { Container, Title } from './styles';

const PADDING_BOTTOM_ACTIVITIES = 120;

const activityTypeToComponent = ({
  ...DefaultFeedMapping,
} as unknown) as ActivityMapping;

const ReporterComments: React.FunctionComponent = (): React.ReactElement => {
  const {
    session,
    eventDetails: { reporterActivities },
  } = useStore();

  if (!reporterActivities) {
    return <DataError />;
  }

  const scrollableRef = React.useRef<HTMLDivElement | null>(null);
  const [keepBottom, setKeepBottom] = React.useState(true);

  const onScroll = (e: React.UIEvent<HTMLDivElement>): void => {
    // @ts-ignore
    const bottom = e.target.clientHeight;
    // @ts-ignore
    const scrolledAt = e.target.scrollHeight - e.target.scrollTop;
    const shouldKeepBottom = scrolledAt - bottom <= PADDING_BOTTOM_ACTIVITIES;

    if (shouldKeepBottom !== keepBottom) {
      setKeepBottom(shouldKeepBottom);
    }
  };

  const onSizeChanged = (newHeight: number): void => {
    if (keepBottom && scrollableRef.current) {
      scrollableRef.current.scrollTop = newHeight;
    }
  };

  React.useEffect(() => {
    if (reporterActivities.activities === undefined && !reporterActivities.isLoading) {
      reporterActivities.loadActivities();
    }
  }, [reporterActivities.activities, reporterActivities.isLoading]);

  if (reporterActivities.activities === undefined) {
    return (
      <Container>
        <CenteredCircularProgress />
      </Container>
    );
  }

  return (
    <Container>
      <Title>Additional Comments</Title>
      <ScrollableContainer onScroll={onScroll} ref={scrollableRef}>
        <CommentLayout>
          <FeedComponent
            // @ts-ignore
            originalActivities={reporterActivities}
            mapping={activityTypeToComponent}
            onDeleteComment={reporterActivities.deleteMessage}
            onDeleteFile={reporterActivities.deleteFile}
            onSizeChanged={onSizeChanged}
            usersSearch={API.searchUsers}
            sessionUser={session.user as SessionUser}
            downloadFile={API.downloadFile}
            userTagBaseUrl={USER_TAG_BASE}
            disableAutoScroll
          />
        </CommentLayout>
      </ScrollableContainer>
      <AddCommentActivities
        borderless
        placeholder="Add any additional notes, pictures or files"
        uploadFile={reporterActivities.uploadFile}
        usersSearch={API.searchUsers}
        sendMessage={reporterActivities.addMessage}
      />
    </Container>
  );
};

export default observer(ReporterComments);
