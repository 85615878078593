import styled from 'styled-components';

import { Button } from 'components/Button';

import AlarmFilled from 'components/Icons/AlarmFilled';

import { AlarmDetailsResponse } from 'utils/api/types';

export const AlarmActionsContainer = styled.div`
  margin-top: 20px;
  margin-bottom: -10px;
`;

export const DurationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const PendingChip = styled.div<{ details?: AlarmDetailsResponse }>`
  align-items: center;
  background: linear-gradient(0deg, rgba(211, 47, 47, 0.12) 0%, rgba(211, 47, 47, 0.12) 100%), #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  padding: 10px 16px;
  height: 68px;
  justify-content: space-between;
`;

export const AcceptedChip = styled(PendingChip)`
  background: white;
  border: 1px solid #d32f2f;
`;

export const StyledAlarmsIcon = styled(AlarmFilled)`
  margin: 0 12px;
`;

export const Duration = styled.p`
  margin: 0;
  margin-left: 10px;
  color: #d32f2f;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.15px;
`;

export const StyledButton = styled(Button)`
  width: 162px;
`;
