import Select from '@mui/material/Select';
import { styled } from '@mui/system';

import vatixTheme from '../../theme/vatix';

export const Dropdown = styled(Select)`
  appearance: none;
  background-color: white;
  border: 1px solid ${vatixTheme.palette.grey[400]};
  border-radius: 8px;
  color: ${vatixTheme.palette.text.secondary};
  height: 40px;
  font-size: 1.5em;

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  .MuiSelect-select {
    background-color: initial;
    margin-left: 8px;
  }

  .MuiMenuItem {
    font-size: 1.5em;
  }

  &.Mui-disabled {
    color: black;
    background-color: ${vatixTheme.palette.secondary.main};
  }

  .MuiSelect-icon.Mui-disabled {
    color: black;
  }
`;
