import React from 'react';

import { observer } from 'mobx-react';

import { useHistory } from 'react-router-dom';

import { reverse } from 'named-urls';

import Record from 'vatix-ui/lib/containers/Record/Record';

import { CircularProgress } from '@mui/material';

import { ActivityType } from 'vatix-ui/lib/utils/api/types';

import MainHeader from 'components/MainHeader';
import { Button } from 'components/Button';

import routes from 'core/routes';

import { useStore } from 'utils/hooks/store';

import { useNavBarHeight } from 'utils/hooks/navbar';

import { ButtonWrapper, SubmissionContainer } from './styles';
import ReportingIncidentCommon from './components/ReportIncidentCommon';
import SubmissionActivity from './components/SubmissionActivity';
import SubmittedIncidentModal from './components/SubmittedIncidentModal';

const ReportIncident: React.FC = (): React.ReactElement => {
  const history = useHistory();
  const navBarHeight = useNavBarHeight();
  const [submitting, setSubmitting] = React.useState(false);

  const {
    incidentDetails: {
      customIncidentType,
      loadCustomIncidentType,
      createIncident,
      updateIncidentField,
      incidentContent,
      resetContent,
      showConfirmPopup,
      submissionActivities,
      missingRequiredValues,
    },
    userList,
  } = useStore();

  React.useEffect(() => {
    loadCustomIncidentType();
    if (!userList.isLoaded && !userList.isLoading) {
      userList.loadData();
    }
    return () => {
      resetContent();
    };
  }, []);

  const onUpdate = async (): Promise<void> => {
    setSubmitting(true);
    if (incidentContent && customIncidentType) {
      await createIncident(incidentContent, customIncidentType.schema);
      setSubmitting(false);
    }
  };

  return (
    <Record
      Header={
        <MainHeader
          title="New Submission"
          breadcrumbs={[
            { label: 'Incidents', link: String(routes.dashboard.incidents) },
            { label: 'Overview', link: String(routes.dashboard.incidents) },
            { label: 'New Submission', link: String(routes.dashboard.incidents.report) },
          ]}
        />
      }
      Content={() => (
        <SubmissionContainer>
          <ReportingIncidentCommon
            customIncidentType={customIncidentType}
            incidentContent={incidentContent}
            updateIncidentField={updateIncidentField}
          />
          <ButtonWrapper>
            {submitting ? (
              <Button
                disabled
                startIcon={<CircularProgress size={24} color="inherit" />}
                variant="contained"
                size="large"
              >
                Submitting...
              </Button>
            ) : (
              <Button
                disabled={
                  missingRequiredValues.length > 0 ||
                  !incidentContent ||
                  Object.values(incidentContent)
                    .map((v) => v && String(v).replaceAll('\n', ''))
                    .filter(Boolean).length === 0
                }
                onClick={onUpdate}
                id="submit_button"
                variant="contained"
                size="large"
              >
                Submit
              </Button>
            )}

            <Button
              onClick={() => history.push(reverse(routes.dashboard.incidents.toString()))}
              id="cancel_button"
              variant="outlined"
              size="large"
              data-testid="cancelButton"
            >
              Cancel
            </Button>
          </ButtonWrapper>
          <SubmissionActivity />
          <SubmittedIncidentModal
            open={showConfirmPopup}
            flowWithFile={submissionActivities?.activities?.filter((i) => i.type !== ActivityType.Message).length !== 0}
          />
        </SubmissionContainer>
      )}
      navBarHeight={navBarHeight}
    />
  );
};

export default observer(ReportIncident);
