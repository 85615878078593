import * as React from 'react';

import { Button } from 'components/Button';

import LockIcon from './LockIcon';

import { HeaderContainer, Header, LockedText, MainContainer, KeyFeatures, Info } from './styles';

const LockedPage: React.FunctionComponent<{ pageName: string; infoTitle: string; infoText: string; image: string }> = ({
  pageName,
  infoTitle,
  infoText,
  image,
}) => (
  <div style={{ padding: '20px', background: '#F5F5F5', margin: 0, height: '100%' }}>
    <HeaderContainer>
      <Header>{pageName}</Header>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <LockIcon />
        <LockedText>Locked</LockedText>
      </div>
    </HeaderContainer>

    <MainContainer>
      <img src={image} alt="key-features" style={{ maxHeight: '256px' }} />

      <KeyFeatures>{infoTitle}</KeyFeatures>
      <Info>{infoText}</Info>

      <Button
        style={{ padding: '6px 16px' }}
        onClick={() => {
          window.open('https://www.vatix.com/contact-us/', '_blank');
        }}
        variant="contained"
        color="primary"
      >
        <p style={{ margin: 0, textTransform: 'uppercase' }}>Talk to sales</p>
      </Button>
    </MainContainer>
  </div>
);

export default LockedPage;
