import React from 'react';

import { InitialAvatarContainer, InitialAvatarName } from './styles';
import { InitialAvatarProps } from './types';

const getColorFromInitials = (name: string, surname: string): string | null => {
  if (!name || !surname || name.length === 0 || surname.length === 0) {
    return null;
  }
  const nameInitial = name.charAt(0).charCodeAt(0);
  const surnameInitial = surname.charAt(0).charCodeAt(0);
  const maxRGBValue = 220;

  const red = (nameInitial * 123 + surnameInitial * 321) % maxRGBValue;
  const green = (nameInitial * 456 + surnameInitial * 654) % maxRGBValue;
  const blue = (nameInitial * 789 + surnameInitial * 987) % maxRGBValue;

  return `rgb(${red}, ${green}, ${blue})`;
};

const InitialAvatar: React.FunctionComponent<InitialAvatarProps> = ({ name, ...props }): React.ReactElement => {
  const { size } = props;
  const initials = React.useMemo(() => {
    const nameParts = name
      .trim()
      .toUpperCase()
      .split(' ');
    let initialParts = nameParts[0].substring(0, 1);

    if (nameParts.length > 1) {
      initialParts += nameParts[nameParts.length - 1].substring(0, 1);
    }
    return initialParts;
  }, [name]);

  const color = getColorFromInitials(initials[0], initials[1] || initials[0]);

  return (
    <InitialAvatarContainer $color={color || '#b616e4'} {...props}>
      <InitialAvatarName size={size}>{initials}</InitialAvatarName>
    </InitialAvatarContainer>
  );
};

InitialAvatar.defaultProps = {
  size: 'medium',
};

export default InitialAvatar;
