import React from 'react';
import { reverse } from 'named-urls';

import { useHistory } from 'react-router-dom';

import routes from 'core/routes';

import { useStore } from 'utils/hooks/store';

import { EntityModules, UserRole } from 'core/constants';

import SessionUser from 'stores/Session/SessionUser';

import { userPermissionForIncident } from 'utils/userPermissionForIncident';

import { CustomIncidentType } from 'utils/api/types';

import { Linked } from './styles';
import { TaskLinkedToCellProps } from './types';

const TaskLinkedToCell: React.FunctionComponent<TaskLinkedToCellProps> = ({ value: { value } }): React.ReactElement => {
  const history = useHistory();
  const { session, notification } = useStore();

  const navigateTo = (event: React.MouseEvent): void => {
    if (value.entity === 'incident') {
      if (
        userPermissionForIncident(value.extraData as CustomIncidentType, session.user as SessionUser) ||
        session.user?.role === UserRole.Manager ||
        session.user?.role === UserRole.Admin ||
        session.user?.role === UserRole.AccountManager
      ) {
        history.push(reverse(routes.dashboard.incidents.details, { incidentId: value.instance.uuid as string }));
      } else {
        notification.enqueueErrorSnackbar(`You don't have permission to see this incident`);
      }
    } else if (value.entity === 'inspection') {
      const {
        instance: { uuid },
      } = value;
      history.push(reverse(routes.dashboard.audits.forms.details, { formId: uuid as string }));
    } else if (Object.values(EntityModules).includes(value.entity as EntityModules)) {
      const module = value.entity as EntityModules;

      const { instance } = value;

      const path = reverse(routes.dashboard[module].details, {
        entityId: instance.uuid || '',
      });

      history.push(path);
    }
    event.stopPropagation();
  };

  return <Linked onClick={navigateTo}>{value.instance.name}</Linked>;
};

export default TaskLinkedToCell;
