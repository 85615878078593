import { observer } from 'mobx-react';
import { reverse } from 'named-urls';
import React from 'react';

import MainHeader from 'components/MainHeader';

import { Button } from 'components/Button';

import routes from 'core/routes';
import { MonitoringMethod } from 'core/constants';
import { useStore } from 'utils/hooks/store';

import { EscalationProfileDetailsNavbarProps } from './types';

const EscalationProfileDetailsNavbar = ({
  monitoringMethod,
  isNew,
}: EscalationProfileDetailsNavbarProps): React.ReactElement => {
  const { escalationProfileDetails, routing } = useStore();

  const onCancel = (): void => {
    routing.push(reverse(routes.dashboard.alarms.escalationProfiles.toString()));
  };

  const onSave = async (): Promise<void> => {
    if (isNew) {
      await escalationProfileDetails.create(monitoringMethod as MonitoringMethod);
    } else {
      await escalationProfileDetails.save(monitoringMethod as MonitoringMethod);
    }
  };

  const disabled = React.useMemo(() => {
    const { details, arcMonitoredDetails, selfMonitoredDetails } = escalationProfileDetails;

    if (!details?.name) return true;

    if (monitoringMethod === MonitoringMethod.ArcMonitored) {
      return (arcMonitoredDetails?.escalationInstructions ?? []).length <= 0;
    }

    if (monitoringMethod === MonitoringMethod.SelfMonitored) {
      return (selfMonitoredDetails?.actions ?? []).length <= 0;
    }

    return false;
  }, [
    escalationProfileDetails.details,
    escalationProfileDetails.arcMonitoredDetails,
    escalationProfileDetails.selfMonitoredDetails,
    monitoringMethod,
  ]);

  return (
    <MainHeader
      title={`Alarm Response Profile ${
        monitoringMethod === MonitoringMethod.ArcMonitored ? '(Vatix 24/7 Monitoring)' : '(Self-Monitoring)'
      }`}
      breadcrumbs={[
        { label: 'Lone Working', link: routes.dashboard.alarms.red.toString() },
        {
          label: 'Alarm Response Profiles',
          link: routes.dashboard.alarms.escalationProfiles.toString(),
        },
        { label: 'Configure', link: routes.dashboard.alarms.escalationProfiles.toString() },
      ]}
      action={
        <div>
          <Button onClick={onCancel} variant="outlined" size="large" style={{ marginRight: '10px' }}>
            Cancel
          </Button>
          <Button onClick={onSave} variant="contained" size="large" disabled={disabled}>
            {isNew ? 'Create' : 'Save'}
          </Button>
        </div>
      }
    />
  );
};

export default observer(EscalationProfileDetailsNavbar);
