import React, { SyntheticEvent } from 'react';
import FormControl from '@mui/material/FormControl';
import { useGridApiContext } from '@mui/x-data-grid';

import Search from 'components/Icons/Search';

import {
  ColumnMenuContainer,
  StyledOutlinedInput,
  ItemsContainer,
  StyledCheckbox,
  StyledFormControlLabel,
} from './styles';

const ColumnMenu: React.FunctionComponent<{ disableSelectAllColumns: boolean }> = ({ disableSelectAllColumns }) => {
  const context = useGridApiContext();
  const [search, setSearch] = React.useState('');

  const potentialColumns = Object.entries(context.current.state.columns.columnVisibilityModel).filter(
    ([key]) =>
      context.current.state.columns?.lookup[key]?.headerName &&
      (!search || key.toLowerCase().indexOf(search.toLowerCase().trim()) !== -1)
  );

  const allSelected = potentialColumns.reduce((previous, [, current]) => current && previous, true);
  const allUnselected = potentialColumns.reduce((previous, [, current]) => !current && previous, true);

  const onSelectAllClick = (event: SyntheticEvent<Element, Event>, value: boolean): void => {
    context.current.setColumnVisibilityModel(Object.fromEntries(potentialColumns.map(([key]) => [key, value])));
  };

  return (
    <ColumnMenuContainer>
      <FormControl fullWidth variant="outlined">
        <StyledOutlinedInput
          endAdornment={<Search />}
          fullWidth
          onChange={(event) => setSearch(event.target.value)}
          value={search}
          placeholder="Find column"
        />
      </FormControl>
      <ItemsContainer>
        {!disableSelectAllColumns && (
          <StyledFormControlLabel
            control={<StyledCheckbox checked={allSelected} indeterminate={!allSelected && !allUnselected} />}
            label="Select all"
            onChange={onSelectAllClick}
          />
        )}
        {potentialColumns.map(([key, value]) => (
          <StyledFormControlLabel
            key={key}
            control={<StyledCheckbox checked={value} />}
            label={context.current.state.columns?.lookup[key]?.headerName || key}
            onChange={(e) =>
              context.current.setColumnVisibility(
                key,
                (e as React.ChangeEvent<HTMLInputElement>).target.checked as boolean
              )
            }
          />
        ))}
      </ItemsContainer>
    </ColumnMenuContainer>
  );
};

export default ColumnMenu;
