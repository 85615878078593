import React from 'react';
import { observer } from 'mobx-react';

import AddCircleIcon from '@mui/icons-material/AddCircle';

import { Button } from 'components/Button';

import { CenteredCircularProgress } from 'components/Progress';

import { useSearch } from 'utils/hooks/search';

import { UserSearchResponse, TeamAssignedUserResponse } from 'utils/api/types';

import API from 'utils/api';

import UserCell from 'containers/Users/components/UserCell';

import { StyledAutocomplete, StyledTextField } from 'components/Input/styles';

import CustomModal from 'components/CustomModal';

import { AddMemberText, StyledOption } from './styles';
import MembersContainer from '../MembersContainer';

const loadingOption = { uuid: 'loading', name: 'Loading...' } as UserSearchResponse;
const nullOption = { uuid: 'null', name: '' } as UserSearchResponse;

const AddMembers: React.FC<{
  addMembers: (members: TeamAssignedUserResponse[]) => void;
  membersInTeam: TeamAssignedUserResponse[];
}> = ({ addMembers, membersInTeam }): React.ReactElement => {
  const [openModal, setOpenModal] = React.useState(false);

  const [members, setMembers] = React.useState<TeamAssignedUserResponse[]>([]);
  const [selectedUser, setSelectedUser] = React.useState<UserSearchResponse | null | undefined>(undefined);

  const { results: usersList, loading: loadingUsers, searchQuery: searchQueryUsers } = useSearch(
    API.searchUsers,
    undefined,
    { limit: 20 }
  );

  const autoClear = React.useRef(null);

  const getOptionDisabled = (option: UserSearchResponse): boolean =>
    option.uuid === loadingOption.uuid ||
    option.uuid === selectedUser?.uuid ||
    members.find((j) => j.uuid === option.uuid) !== undefined ||
    membersInTeam.find((j) => j.uuid === option.uuid) !== undefined;

  const onClose = (): void => {
    setOpenModal(false);
    setMembers([]);
  };

  return (
    <>
      <Button style={{ marginTop: '10px' }} onClick={() => setOpenModal(true)}>
        <AddCircleIcon color="primary" />
        <AddMemberText>Add Member</AddMemberText>
      </Button>
      <CustomModal
        openModal={openModal}
        onClose={onClose}
        modalTitle="Add Member"
        cancelButton={
          <Button id="cancel-action-button" onClick={onClose} variant="outlined" size="large">
            Cancel
          </Button>
        }
        submitButton={
          <Button
            id="add-action-button"
            onClick={() => {
              addMembers(members);
              onClose();
            }}
            disabled={members.length === 0}
            variant="contained"
            size="large"
          >
            Add
          </Button>
        }
      >
        <StyledAutocomplete
          ref={autoClear}
          key={`${selectedUser?.name}_${selectedUser?.uuid}`}
          id="user-dropdown-field"
          options={usersList}
          value={selectedUser === undefined ? nullOption : nullOption}
          renderOption={(props, option: UserSearchResponse) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { className, ...restProps } = props;

            return option.uuid === loadingOption.uuid ? (
              <StyledOption $disabled={false}>
                <CenteredCircularProgress />
              </StyledOption>
            ) : (
              <StyledOption
                $disabled={getOptionDisabled(option)}
                key={`option_${selectedUser?.name}_${selectedUser?.uuid}`}
                {...restProps}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <UserCell name={option.name} uuid={option.uuid} />
                </div>
                {getOptionDisabled(option) ? null : <AddCircleIcon color="primary" />}
              </StyledOption>
            );
          }}
          getOptionLabel={(option: UserSearchResponse | null) => option?.name as string}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              placeholder="Search for Members..."
              onChange={(e) => {
                searchQueryUsers(e.target.value);
              }}
            />
          )}
          loading={loadingUsers}
          noOptionsText="-"
          onChange={(_, rawValue: UserSearchResponse | null) => {
            setSelectedUser(undefined);
            searchQueryUsers('');
            // @ts-ignore
            const ele = autoClear.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
            if (ele) ele.click();

            if (rawValue !== null) {
              const newValue = { uuid: rawValue?.uuid as string, name: rawValue?.name as string, isAdmin: false };
              if (!getOptionDisabled({ uuid: rawValue?.uuid as string, name: rawValue?.name as string, email: '' })) {
                setMembers((prevState) => [...prevState, newValue]);
              }
            }
          }}
          getOptionDisabled={getOptionDisabled}
          isOptionEqualToValue={(option: UserSearchResponse | null, val: UserSearchResponse | null) =>
            val?.uuid !== undefined && val?.uuid === option?.uuid
          }
          fullWidth
        />
        {(members?.length || 0) > 0 ? <MembersContainer members={members} setMembers={setMembers} /> : null}
      </CustomModal>
    </>
  );
};

export default observer(AddMembers);
