import styled from 'styled-components';

import Avatar from 'components/Avatar/Avatar';

export const UserCellName = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
  margin-left: 8px;
`;

export const UserCellAvatar = styled(Avatar)`
  > img {
    width: 32px;
    height: 32px;
  }
`;

export const UserCellNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const UserCellSelfIndicator = styled.p`
  color: #6d7883;
  margin-left: 5px;
  font-size: 14px;
  line-height: 16px;
`;
