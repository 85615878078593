import React from 'react';

import Unauthorized from 'containers/Error/Pages/Unauthorized/Unauthorized';

import SecureRoute from '../SecureRoute';
import { UserRouteProps } from './types';

const UserRoute: React.FunctionComponent<UserRouteProps> = ({ isAuthorizedUser, ...props }): React.ReactElement => (
  <SecureRoute
    isAuthorized={(session) => !!session.user && isAuthorizedUser(session.user)}
    redirectComponent={Unauthorized}
    {...props}
  />
);

export default UserRoute;
