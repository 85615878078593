import styled from 'styled-components';
import MaterialUiPhoneNumber from 'material-ui-phone-number';

import { commonInputStyles } from 'components/Input/styles';

const PhoneInput = styled(MaterialUiPhoneNumber)`
  ${commonInputStyles}

  .MuiPhoneNumber-flagButton {
    margin-right: 8px;
    height: 21px;
    min-width: 21px;
  }

  .MuiPhoneNumber-flagButton:after {
    position: absolute;
    right: -12px;
    top: 6px;
    content: '▼';
    font-size: 6px;
    color: black;
  }

  .country {
    font-size: 16px;
  }

  .country-name {
    font-size: 16px;
  }
`;

export default PhoneInput;
