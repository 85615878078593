// eslint-disable-next-line import/no-anonymous-default-export
export default {
  palette: {
    primary: {
      main: '#0772E5',
      dark: '#223B65',
    },
    secondary: {
      main: '#F1F0F0',
      contrastText: '#595959', // 1
      light: '#A1A9B5', // 1
      100: '#555555', // 1
    },
    error: {
      main: '#FF0000', // 3
      light: '#FAE0E9', // 1
      secondary: '#CC0202',
    },
    background: {
      default: '#4285F4', // 1
    },
    common: {
      white: '#FFFFFF',
    },
    text: {
      secondary: '#101D31',
      hint: '#6D7883',
    },
    grey: {
      200: '#636363', // 1
      400: '#E0E1E9',
      500: '#9B9B9B', // 1
      900: '#737374', // 3
    },
  },
  typography: {
    body1: {
      fontSize: '1.4rem',
      lineHeight: '1.7rem',
      fontWeight: 'normal',
      color: '#000000',
    },
  },
  ui: {
    snackbar: {
      success: '#128805',
      warning: '#F5A623',
      info: '#4285F4',
      error: '#E20101',
    },
    profileAndSettings: {
      passwordChanged: '#7ED321',
    },
    select: {
      arrowDropDown: 'rgba(0, 0, 0, 0.54)',
    },
    avatar: {
      background: '#B616E4',
    },
    chip: {
      success: '#4BBD00',
      error: '#F30000',
      border: '#D3D6D9',
      grayText: '#6D7883',
      text: '#0772E5',
    },
    icon: {
      background: {
        secondary: '#E2E4E6',
        primary: '#0772E51A',
      },
    },
    button: {
      primaryInfo: {
        main: 'rgba(7, 114, 229, 1)',
        dark: 'rgba(1, 96, 199, 1)',
        light: 'rgba(7, 114, 229, 0.04)',
        outlinedBorder: 'rgba(7, 114, 229, 0.5)',
      },
    },
  },
};
