import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { Location } from 'history';

import { Button } from 'components/Button';

import CustomModal from 'components/CustomModal';

import { NavigationBlockerType } from './types';

const NavigationBlocker: React.FC<NavigationBlockerType> = ({
  navigate,
  when,
  shouldBlockNavigation,
  navigateMessage,
}) => {
  useEffect(() => {
    if (when) {
      window.addEventListener('beforeunload', alertUser);
    }
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  });

  const alertUser = (event: BeforeUnloadEvent): void => {
    event.preventDefault();
    event.returnValue = '';
  };

  const [modalVisible, updateModalVisible] = useState(false);
  const [lastLocation, updateLastLocation] = useState<Location>();
  const [confirmedNavigation, updateConfirmedNavigation] = useState(false);

  const showModal = (location: Location): void => {
    updateModalVisible(true);
    updateLastLocation(location);
  };

  const closeModal = (callBack?: () => void): void => {
    updateModalVisible(false);
    if (callBack) {
      callBack();
    }
  };

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && shouldBlockNavigation()) {
      showModal(nextLocation);
      return false;
    }
    return true;
  };
  const handleConfirmNavigationClick = (): void => {
    closeModal(() => {
      if (lastLocation) {
        updateConfirmedNavigation(true);
      }
    });
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.pathname);
      updateConfirmedNavigation(false);
    }
  }, [confirmedNavigation, lastLocation]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <CustomModal
        modalWidth="sm"
        openModal={modalVisible}
        onClose={() => closeModal()}
        modalTitle="Unsaved changes"
        buttonsSpacing="space-between"
        cancelButton={
          <Button id="cancel-action-button" onClick={() => closeModal()} variant="outlined" size="large">
            No, keep editing
          </Button>
        }
        submitButton={
          <Button
            id="add-action-button"
            onClick={handleConfirmNavigationClick}
            variant="contained"
            size="large"
            color="error"
          >
            Yes, discard changes
          </Button>
        }
      >
        <p
          style={{
            color: 'rgba(0, 0, 0, 0.87)',
            fontSize: '16px',
            letterSpacing: '0.15px',
            margin: '0',
          }}
        >
          {navigateMessage}
        </p>
      </CustomModal>
    </>
  );
};

export default NavigationBlocker;
