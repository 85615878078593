/* eslint-disable max-len */
import React, { useContext } from 'react';

import { Button } from 'components/Button';

import { Context } from '../../types';

function DragHandle({ SortableItemContext }: { SortableItemContext: React.Context<Context> }): JSX.Element {
  const { attributes, listeners, ref } = useContext(SortableItemContext);

  return (
    <Button
      data-testid="drag-handler"
      size="small"
      style={{ width: '24px', minWidth: '0', padding: 0, marginRight: '10px' }}
      {...attributes}
      {...listeners}
      ref={ref}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.0011 19.0005C14.0011 17.896 14.8961 16.9995 16.0006 16.9995C17.1051 16.9995 18.0001 17.896 18.0001 19.0005C18.0001 20.105 17.1051 21 16.0006 21C14.8961 21 14.0011 20.105 14.0011 19.0005ZM6 19.0005C6 17.896 6.89514 16.9995 8.00098 16.9995C9.10547 16.9995 10.0006 17.896 10.0006 19.0005C10.0006 20.105 9.10547 21 8.00098 21C6.89515 21 6 20.105 6 19.0005ZM14.0011 12C14.0011 10.8955 14.8961 10.0005 16.0006 10.0005C17.1051 10.0005 18.0001 10.8955 18.0001 12C18.0001 13.1045 17.1051 13.9995 16.0006 13.9995C14.8961 13.9995 14.0011 13.1045 14.0011 12ZM6 12C6 10.8955 6.89514 10.0005 8.00098 10.0005C9.10547 10.0005 10.0006 10.8955 10.0006 12C10.0006 13.1045 9.10547 13.9995 8.00098 13.9995C6.89515 13.9995 6 13.1045 6 12ZM14.0011 4.99951C14.0011 3.89502 14.8961 3 16.0006 3C17.1051 3 18.0001 3.89502 18.0001 4.99951C18.0001 6.104 17.1051 7.00049 16.0006 7.00049C14.8961 7.00049 14.0011 6.10401 14.0011 4.99951ZM6 4.99951C6 3.89502 6.89514 3 8.00098 3C9.10547 3 10.0006 3.89502 10.0006 4.99951C10.0006 6.104 9.10547 7.00049 8.00098 7.00049C6.89515 7.00049 6 6.10401 6 4.99951Z"
          fill="black"
          fillOpacity="0.54"
        />
      </svg>
    </Button>
  );
}

export default DragHandle;
