import React from 'react';

import { observer } from 'mobx-react';

import { GridActionsCellItem } from '@mui/x-data-grid';

import { Popover } from '@mui/material';

import { MoreVert } from '@mui/icons-material';

import { reverse } from 'named-urls';

import { useHistory } from 'react-router-dom';

import { Button } from 'components/Button';

import routes from 'core/routes';

import { useStore } from 'utils/hooks/store';

import { Task } from 'utils/api/types';

import { ColumnActions } from 'components/Grid/types';

import CustomModal from 'components/CustomModal';
import { ModalText } from 'containers/TaskDetails/styles';

import { MenuItem } from './styles';

const ActionCell: React.FunctionComponent<{ rowData: Task; actions: ColumnActions }> = ({ rowData, actions }) => {
  const { taskDetails } = useStore();
  const history = useHistory();

  const [deletionModalOpen, setDeletionModalOpen] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClose = (event: React.MouseEvent<HTMLLIElement>): void => {
    setAnchorEl(event.currentTarget);
    setAnchorEl(null);
  };

  const handleCloseModal = (): void => {
    if (!deleting) {
      setDeletionModalOpen(false);
      setAnchorEl(null);
    }
  };

  const handleEditClick = (event: React.MouseEvent<HTMLLIElement>): void => {
    setAnchorEl(event.currentTarget);
    setAnchorEl(null);
    history.push(reverse(routes.dashboard.tasks.details, { taskId: rowData.uuid }));
  };

  const onDelete = async (): Promise<void> => {
    setDeleting(true);
    await taskDetails.deleteTask(rowData.uuid);
    await actions.refreshData();
  };

  return (
    <>
      <GridActionsCellItem
        icon={<MoreVert />}
        label=""
        color="inherit"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem id="remove-button" onClick={handleEditClick}>
          Edit
        </MenuItem>
        <MenuItem id="remove-button" onClick={() => setDeletionModalOpen(true)}>
          Delete
        </MenuItem>

        <CustomModal
          openModal={deletionModalOpen}
          onClose={handleCloseModal}
          modalTitle="Delete Task"
          modalWidth="xs"
          cancelButton={
            <Button
              disabled={deleting}
              onClick={handleCloseModal}
              autoFocus
              id="cancel-button"
              variant="outlined"
              size="large"
            >
              Cancel
            </Button>
          }
          submitButton={
            <Button
              onClick={onDelete}
              disabled={deleting}
              id="confirm-delete-button"
              variant="contained"
              size="large"
              color="error"
            >
              Delete
            </Button>
          }
        >
          <ModalText>Are you sure you want to delete this record?</ModalText>
          <ModalText>Once removed, you will not be able to recover this data.</ModalText>
        </CustomModal>
      </Popover>
    </>
  );
};

export default observer(ActionCell);
