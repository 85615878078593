import { InfoBox } from '@react-google-maps/api';
import { observer } from 'mobx-react';
import React from 'react';
import Typography from '@mui/material/Typography';

import { formatDuration, formatDateTime } from 'utils/formatters/time';

import { LocationResponse, UserResponse } from 'utils/api/types';

import { StyledPaper, LocationPosition, LocationAddress, TimeToEnd } from './styles';

const AmberAlarmDetailsDialog: React.FunctionComponent<{
  user: UserResponse;
  activated: string;
  location: LocationResponse;
  endTime: string;
}> = ({ location, user, activated, endTime }): React.ReactElement | null => {
  const position = {
    lng: Number(location.lng),
    lat: Number(location.lat),
  };
  const [duration, setDuration] = React.useState('');

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (!endTime) return;
      const seconds = (new Date(endTime).getTime() - Date.now()) / 1000;
      if (seconds > 0) {
        setDuration(formatDuration(seconds));
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [endTime]);

  return (
    <InfoBox
      // @ts-ignore
      position={position}
      options={{
        closeBoxURL: ``,
        enableEventPropagation: true,
        maxWidth: 300,
        boxStyle: {
          overflow: 'visible',
        },
        alignBottom: true,
        pixelOffset: new google.maps.Size(25, -10),
      }}
    >
      <StyledPaper>
        <Typography color="initial" variant="body1" display="block">
          <strong>{`${user.name} | ${formatDateTime(activated)}`}</strong>
        </Typography>

        {duration && (
          <TimeToEnd>
            <b>{duration}</b> left to alarm{' '}
          </TimeToEnd>
        )}
        {location.address && <LocationAddress>{location.address}</LocationAddress>}
        <LocationPosition>{`${position.lng}, ${position.lat}`}</LocationPosition>
      </StyledPaper>
    </InfoBox>
  );
};

export default observer(AmberAlarmDetailsDialog);
