import styled from 'styled-components';

import vatixTheme from '../../theme/vatix';

export const HeaderWrapper = styled.div`
  align-items: center;
  background-color: ${vatixTheme.palette.grey};
  display: flex;
  justify-content: space-between;
  color: ${vatixTheme.palette.text.secondary};
  min-height: 49px;
  top: 48px;
`;

export const HeaderTitleWrapper = styled.div`
  align-items: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding-right: 80px;
  width: 80%;
`;

export const HeaderTitle = styled.h1`
  color: rgba(0, 0, 0, 0.87);
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.15px;
  margin: 0;
  margin-top: 2px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const BreadcrumbLink = styled.a`
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  letter-spacing: 0.4px;
  text-decoration: none;
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

export const ItemLabel = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
  letter-spacing: 0.4px;
  margin: 0;
`;
