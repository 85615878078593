import React from 'react';

import Record from 'vatix-ui/lib/containers/Record/Record';

import MainHeader from 'components/MainHeader';

import routes from 'core/routes';
import AlertFilled from 'components/Icons/AlertFilled';
import AccessTimeFilled from 'components/Icons/AccessTimeFilled';
import Tabs from 'components/Tabs';

import { useNavBarHeight } from 'utils/hooks/navbar';

import AmberAlarmList from './components/AmberAlarmList';
import AlarmList from './components/AlarmList';
import { AlarmsProps, AlarmType } from './types';

const Alarms: React.FunctionComponent<AlarmsProps> = ({ view }) => {
  const List = view === AlarmType.TimedAlerts ? AmberAlarmList : AlarmList;
  const navBarHeight = useNavBarHeight();
  return (
    <Record
      Header={
        <MainHeader
          title="Overview"
          breadcrumbs={[
            {
              label: 'Lone Working',
              link: routes.dashboard.alarms.red.toString(),
            },
            {
              label: 'Overview',
              link: routes.dashboard.alarms.red.toString(),
            },
          ]}
        />
      }
      Content={(onError) => (
        <List
          header={
            <Tabs
              options={[
                {
                  id: 'redAlarms',
                  label: 'Alarms',
                  icon: AlertFilled,
                  to: routes.dashboard.alarms.red.toString(),
                },
                {
                  id: 'timedAlerts',
                  label: 'Timed Alerts',
                  icon: AccessTimeFilled,
                  to: routes.dashboard.alarms.amber.toString(),
                },
              ]}
              value={view}
            />
          }
          onError={onError}
        />
      )}
      navBarHeight={navBarHeight}
    />
  );
};

export const RedAlarms: React.FunctionComponent = () => <Alarms view={AlarmType.RedAlarms} />;

export const AmberAlarms: React.FunctionComponent = () => <Alarms view={AlarmType.TimedAlerts} />;
