import React from 'react';
import { reverse } from 'named-urls';

import { GridEventListener, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';

import { formatDuration, formatDateForDisplay } from 'utils/formatters/time';

import routes from 'core/routes';
import { useStore } from 'utils/hooks/store';

import { RedAlertStatus } from 'core/constants';

import AlarmStatusChip from 'components/Chips/AlarmStatusChip';

import Grid, { defaultFormatters, defaultOperators, defaultRenderers } from 'components/Grid';
import { StyledCreated } from 'containers/Incidents/components/IncidentList/styles';
import { ColumnDefinition } from 'components/Grid/types';
import { AlarmStatusOperator } from 'components/Grid/operators/Status';
import { EventType } from 'utils/events/constants';

import { AlarmListProps } from './types';

const basicColumns = ([
  {
    field: 'subject',
    headerName: 'Alarm name',
    nativeFilter: 'subject',
    // ToDo: implement sorting on backend
    sortable: false,
  },
  {
    field: 'duration',
    headerName: 'Duration',
    valueGetter: ({ value }: GridValueGetterParams) => formatDuration(value),
    maxWidth: 80,
    filterable: false,
    // ToDo: implement sorting on backend
    sortable: false,
  },
  {
    field: 'user',
    headerName: 'Started by',
    filterOperators: defaultOperators.user(),
    renderCell: defaultRenderers.user,
    valueFormatter: defaultFormatters.user,
    // TODO It does not make sense, it is added by the Grid.
    valueGetter: ({ value }: GridValueGetterParams) => ({ value }),
    nativeFilter: 'started_by',
    // ToDo: implement sorting on backend
    sortable: false,
  },
  {
    field: 'responder',
    headerName: 'Responder',
    filterOperators: defaultOperators.user(),
    renderCell: defaultRenderers.user,
    valueFormatter: defaultFormatters.user,
    // TODO It does not make sense, it is added by the Grid.
    valueGetter: ({ value }: GridValueGetterParams) => ({ value }),
    nativeFilter: 'responders',
    // ToDo: implement sorting on backend
    sortable: false,
  },
  {
    field: 'alertStatus',
    headerName: 'Status',
    renderCell: ({ value }: { value: RedAlertStatus }) => <AlarmStatusChip status={value} size="medium" />,
    nativeFilter: 'statuses',
    filterOperators: AlarmStatusOperator(),
    // ToDo: implement sorting on backend
    sortable: false,
  },
  {
    field: 'activated',
    filterOperators: defaultOperators.date_time(),
    headerName: 'Raised at',
    renderCell: ({ value }: { value: string }) => <StyledCreated>{formatDateForDisplay(value)}</StyledCreated>,
    nativeFilter: 'start_date',
    valueFormatter: defaultFormatters.date,
  },
] as unknown) as ColumnDefinition[];

const AlarmList: React.FunctionComponent<AlarmListProps> = ({ header, onError }) => {
  const { routing, session } = useStore();
  const refreshRef = React.useRef<(() => void) | null>(null);

  const onRowClick: GridEventListener<'rowClick'> = (alarm: GridRowParams): void => {
    routing.push(reverse(routes.dashboard.alarms.details, { alarmId: alarm.row.uuid }));
  };

  React.useEffect(() => {
    const events = [EventType.CreatedRedAlert, EventType.AcceptedRedAlert, EventType.CompletedRedAlert];
    const onUpdate = (): void => {
      refreshRef.current?.();
    };
    events.forEach((event) => session.pusherNotifications.addEventListener(event, onUpdate));
    return () => {
      events.forEach((event) => session.pusherNotifications.removeEventListener(event, onUpdate));
    };
  }, []);

  return (
    <Grid
      basicColumns={basicColumns}
      dataURL="/api/alarms/"
      header={header}
      onError={onError}
      onRefreshCallback={(callback) => {
        refreshRef.current = callback;
      }}
      onRowClick={onRowClick}
      sortBy={{ field: 'activated', sort: 'desc' }}
      disableColumnSelector
    />
  );
};

export default AlarmList;
