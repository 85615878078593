import React from 'react';
import { IconButton } from '@mui/material';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { observer } from 'mobx-react';

import PhoneInput from 'components/PhoneInput';

import Delete from 'components/Icons/Delete';

import { StyledTextField } from 'components/Input/styles';

import {
  EscalationContainer,
  EscalationOrder,
  EscalationCell,
  CellHeader,
} from 'containers/EscalationProfileDetails/styles';

import { EscalationActionRowProps } from './types';
import DragHandle from '../SortableList/components/DragHandle';

const EscalationActionRow: React.FunctionComponent<EscalationActionRowProps> = ({
  action,
  SortableItemContext,
  index,
  removeAction,
  updateAction,
  duplicateAction,
  errors,
}) => {
  const [contactNameValue, setContactNameValue] = React.useState(action.contactName);
  const [phoneNumberValue, setPhoneNumberValue] = React.useState(action.phoneNumber);

  const updateActionProperty = (propertyName: string, propertyValue: string | undefined): void => {
    updateAction(index - 1, { [propertyName]: propertyValue });
  };

  return (
    <EscalationContainer>
      <EscalationOrder>{index}</EscalationOrder>
      <DragHandle SortableItemContext={SortableItemContext} />
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <EscalationCell width="25%">
          <CellHeader>Action</CellHeader>
          <StyledTextField style={{ backgroundColor: '#fff' }} value="Make a Call" aria-label="Action field" disabled />
        </EscalationCell>
        <EscalationCell width="25%">
          <CellHeader>Contact name</CellHeader>
          <StyledTextField
            style={{ backgroundColor: '#fff' }}
            value={contactNameValue}
            onChange={(event) => {
              setContactNameValue(event.target.value);
            }}
            onBlur={() => updateActionProperty('contactName', contactNameValue)}
            aria-label="Contact name field"
            placeholder="Enter name"
          />
          <p style={{ color: 'red' }}>{errors?.find((err) => err.key === 'contactName')?.message}</p>
        </EscalationCell>

        <EscalationCell width="50%">
          <CellHeader>Phone number</CellHeader>

          <PhoneInput
            style={{ width: '100%', backgroundColor: '#fff' }}
            defaultCountry="gb"
            preferredCountries={['gb', 'ie', 'us']}
            regions={(['north-america', 'europe', 'middle-east'] as unknown) as [string]}
            value={phoneNumberValue}
            onChange={(e) => {
              setPhoneNumberValue(e as string);
              updateActionProperty('phoneNumber', e as string);
            }}
            onBlur={() => updateActionProperty('phoneNumber', phoneNumberValue)}
            variant="outlined"
            disableAreaCodes
            placeholder="e.g. +44 0123456789"
            aria-label="Phone number field"
          />
          <p style={{ color: 'red' }}>{errors?.find((err) => err.key === 'phoneNumber')?.message}</p>
        </EscalationCell>
      </div>
      <IconButton
        onClick={() => {
          duplicateAction(action.uuid || '');
        }}
        aria-label="Duplicate action"
        size="large"
      >
        <ContentCopyIcon />
      </IconButton>
      <IconButton
        onClick={() => {
          removeAction(action.uuid || '');
        }}
        aria-label="Remove action"
        size="large"
      >
        <Delete />
      </IconButton>
    </EscalationContainer>
  );
};
export default observer(EscalationActionRow);
