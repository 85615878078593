/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

import { useCSVReader } from 'react-papaparse';

import UploadFileIcon from '@mui/icons-material/UploadFile';

import DeleteIcon from '@mui/icons-material/Delete';

import { observer } from 'mobx-react';

import { Button } from 'components/Button';

import { UploadIcon } from 'components/Icons/UploadIcon';

import { useStore } from 'utils/hooks/store';

import { UploadUserType } from 'utils/api/types';

import {
  FileContainer,
  FileName,
  InfoMessage,
  RemoveButton,
  SecondaryMessage,
  UploadContainer,
  BaseInfoText,
} from './styles';

import DownloadButton from '../DownloadButton';

const ClickAndDragUpload: React.FunctionComponent = () => {
  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);

  const { notification, userList } = useStore();

  return (
    <CSVReader
      multiple={false}
      config={{
        header: true,
        // change headers into camel case
        transformHeader: (header: string) =>
          header
            .toLowerCase()
            .replace(/(?:^\w|[A-Z]|\b\w)/g, (match, index) => (index === 0 ? match : match.toUpperCase()))
            .replace(/\s+/g, ''),
      }}
      maxSize={1000000}
      onUploadRejected={(file: File[]) => {
        if (file[0]) {
          // @ts-expect-error
          notification.enqueueErrorSnackbar(file[0].errors[0].message);
        } else {
          notification.enqueueErrorSnackbar('Error uploading file. Please try again.');
        }
        setZoneHover(false);
      }}
      onUploadAccepted={(results: { data: UploadUserType[] }) => {
        userList.loadUsersFromCSV(results.data);
        setZoneHover(false);
      }}
      onDragOver={(event: DragEvent) => {
        event.preventDefault();
        setZoneHover(true);
      }}
      onDragLeave={(event: DragEvent) => {
        event.preventDefault();
        setZoneHover(false);
      }}
    >
      {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps }: any) => (
        <>
          <BaseInfoText>
            <DownloadButton />, fill in the user information without changing the column names, and upload the file
            below.
          </BaseInfoText>
          <UploadContainer {...getRootProps()} $dragActive={zoneHover}>
            <UploadIcon />
            <InfoMessage>Choose a file or drag & drop it here</InfoMessage>
            <SecondaryMessage>CSV format (comma delimited)</SecondaryMessage>
            <Button variant="outlined" size="large" style={{ marginTop: '16px' }} disabled={acceptedFile !== null}>
              Browse Files
            </Button>
          </UploadContainer>

          {acceptedFile && userList.uploadErrors.length === 0 && (
            <FileContainer>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <UploadFileIcon
                    style={{ marginRight: '16px', width: '32px', height: '32px', color: 'rgba(0, 0, 0, 0.54)' }}
                  />
                  <FileName>{acceptedFile.name}</FileName>
                </div>
                <RemoveButton
                  {...getRemoveFileProps()}
                  onClick={(event) => {
                    getRemoveFileProps().onClick(event);
                    userList.clearUsersToUpload();
                  }}
                >
                  <DeleteIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                </RemoveButton>
              </div>

              <ProgressBar style={{ marginTop: '16px' }} />
            </FileContainer>
          )}
        </>
      )}
    </CSVReader>
  );
};

export default observer(ClickAndDragUpload);
