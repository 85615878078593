import styled from 'styled-components';

import { TextField, Autocomplete, Select, InputBase } from '@mui/material';

import { DatePicker, DateTimePicker, MobileDateTimePicker, MobileTimePicker, TimePicker } from '@mui/x-date-pickers';

import VatixTheme from 'theme/vatix';

export const SearchInput = styled(InputBase)`
  color: ${VatixTheme.palette.grey['500']};
  font-size: 1.3rem;
  flex-grow: 1;
`;

export const commonInputStyles = `
  .MuiInputBase-root {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);

    fieldset {
      border: 1px solid rgba(0, 0, 0, 0.23);
      border-radius: 4px;
    }

    &:hover fieldset {
      border: 1px solid rgba(0, 0, 0, 0.5);
    }

    &.Mui-focused fieldset {
      border: 2px solid rgba(7, 114, 229, 1);
    }
  }

  .MuiInputBase-input {
    font-size: 16px;

    &::placeholder {
      opacity: 1;
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .Mui-disabled {
    &.MuiInputBase-root {
      fieldset {
        border: 1px solid rgba(0, 0, 0, 0.06);
      }
    }
  }

  .Mui-error {
    &.MuiInputBase-root {
      fieldset {
        border: 2px solid rgba(239, 83, 80, 1);
      }
    }
    &.MuiFormHelperText-root {
      color: rgba(211, 47, 47, 1);
    }
  }

  .MuiFormHelperText-root {
    color: rgba(0, 0, 0, 0.60);
    font-size: 12px;
    font-weight: 400;
  }

  // to remove background input color in chrome and safari
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  }
`;

export const StyledTextField = styled(TextField)`
  ${commonInputStyles}
` as typeof TextField;

export const StyledDatePicker = styled(DatePicker)`
  ${commonInputStyles}
` as typeof DatePicker;

export const StyledDateTimePicker = styled(DateTimePicker)`
  ${commonInputStyles}
` as typeof DateTimePicker;

export const StyledMobileDateTimePicker = styled(MobileDateTimePicker)`
  ${commonInputStyles}
` as typeof MobileDateTimePicker;

export const StyledTimePicker = styled(TimePicker)`
  ${commonInputStyles}
` as typeof TimePicker;

export const StyledMobileTimePicker = styled(MobileTimePicker)`
  ${commonInputStyles}
` as typeof MobileTimePicker;

export const StyledAutocomplete = styled(Autocomplete)`
  ${commonInputStyles}

  // to remove second remove icon in chrome and safari
  input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
` as typeof Autocomplete;

export const StyledSelect = styled(Select)<{ $withoutBorder?: boolean; $withoutPadding?: boolean }>`
  ${commonInputStyles}
  height: 56px;
  .MuiSelect-select {
    max-height: 56px;
    display: flex;
    align-items: center;
    padding: ${(props) => (props.$withoutPadding ? '0' : '')};
  }
  .Mui-error {
    border: ${(props) => (props.$withoutBorder ? 'none' : ' 2px solid rgba(239, 83, 80, 1)')};
  }
  .MuiOutlinedInput-notchedOutline {
    border: ${(props) => props.$withoutBorder && 'none'};
  }
`;

export const StyledChipSelect = styled(StyledSelect)`
  .MuiSelect-select {
    padding: 9px;
  }
`;
