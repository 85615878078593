import React from 'react';
import { GridEventListener, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';

import { reverse } from 'named-urls';

import { VatixError } from 'components/Error/types';

import Grid, { defaultFormatters, defaultOperators, defaultRenderers } from 'components/Grid';
import { ColumnDefinition } from 'components/Grid/types';

import LinkedTasksCell from 'containers/Incidents/components/LinkedTasksCell';
import { LinkedTaskType } from 'utils/api/types';
import { formatFullDateTime } from 'utils/formatters/time';
import { useStore } from 'utils/hooks/store';
import routes from 'core/routes';

import { getEntityGridColumns } from 'components/Grid/components/EntitiesGridColumn/EntitiesGridColumn';

import { EntityModules } from 'core/constants';
import { InspectionStatusOperator } from 'components/Grid/operators/Status/Status';

export const formsListBasicColumns = ([
  {
    field: 'name',
    headerName: 'Name',
    nativeFilter: true,
    flex: 2,
  },
  {
    // ToDo: implement sorting on backend
    sortable: false,
    field: 'location',
    nativeFilter: true,
    headerName: 'Location',
    renderCell: defaultRenderers.location,
    valueFormatter: defaultFormatters.location,
    valueGetter: ({ value }: GridValueGetterParams) => ({ value }),
    flex: 1,
  },
  {
    field: 'reporter',
    headerName: 'Reporter',
    nativeFilter: 'reporters',
    renderCell: defaultRenderers.user,
    valueFormatter: defaultFormatters.user,
    filterOperators: defaultOperators.user(),
    valueGetter: ({ value }: GridValueGetterParams) => ({ value }),
    flex: 2,
  },
  {
    maxWidth: 100,
    // ToDo: implement sorting on backend
    sortable: false,
    headerName: 'Tasks',
    field: 'linkedTasks',
    renderCell: ({ value }: { value: LinkedTaskType }) => <LinkedTasksCell linkedTasks={value} />,
    flex: 1,
  },
  {
    field: 'status',
    headerName: 'Status',
    sortable: false,
    nativeFilter: 'submitted',
    renderCell: ({ value }: { value: string }) => value,
    valueGetter: ({ row }: { row: { submitted: null | string } }) => (row.submitted ? 'Submitted' : 'In progress'),
    filterOperators: InspectionStatusOperator(),
    flex: 1,
  },
  {
    field: 'submitted',
    headerName: 'Submitted',
    nativeFilter: 'submitted',
    filterOperators: defaultOperators.date(),
    renderCell: ({ value }: { value: string | null }) => (value !== null ? formatFullDateTime(value) : 'Not submitted'),
    flex: 1,
  },
  {
    sortable: false,
    field: 'created',
    headerName: 'Created',
    nativeFilter: 'created',
    defaultVisibility: false,
    filterOperators: defaultOperators.date(),
    renderCell: ({ value }: { value: string | null }) => (value !== null ? formatFullDateTime(value) : 'N/A'),
    flex: 1,
  },
] as unknown) as ColumnDefinition[];

const FormsList: React.FunctionComponent<{ onError: (error: VatixError) => void }> = ({
  onError,
}): React.ReactElement => {
  const {
    routing,
    session: { user },
  } = useStore();
  const onRowClick: GridEventListener<'rowClick'> = (form: GridRowParams): void => {
    routing.push(reverse(routes.dashboard.audits.forms.details, { formId: form.row.uuid }));
  };

  // get only sites suppliers and assets
  const entityModules = user?.activeEntityModules
    ? user.activeEntityModules.filter(
        (module) =>
          module === EntityModules.Sites || module === EntityModules.Suppliers || module === EntityModules.Assets
      )
    : [];

  return (
    <Grid
      entity="forms"
      onError={onError}
      onRowClick={onRowClick}
      sortBy={{ field: 'submitted', sort: 'desc' }}
      customNoRowsText="No forms have been created yet"
      dataURL={`${process.env.REACT_APP_WORKFLOWS_API_URL}inspections/`}
      basicColumns={[...formsListBasicColumns, ...getEntityGridColumns(entityModules, true, true)]}
    />
  );
};

export default FormsList;
