import React from 'react';

import Record from 'vatix-ui/lib/containers/Record/Record';

import { Button } from 'components/Button';

import { MainHeader } from 'components/MainHeader/MainHeader';

import { useStore } from 'utils/hooks/store';

import AddActionModal from 'containers/IncidentDetails/components/AddTaskModal';

import { UserRole } from 'core/constants';

import { useNavBarHeight } from 'utils/hooks/navbar';

import routes from 'core/routes';

import TasksTable from './components/TasksTable';

const Tasks: React.FunctionComponent = (): React.ReactElement => {
  const { session } = useStore();
  const refreshRef = React.useRef<Function | null>(null);
  const [openModal, setOpenModal] = React.useState(false);
  const navBarHeight = useNavBarHeight();

  const onRefreshCallback = (callback: Function): void => {
    refreshRef.current = callback;
  };

  const onClose = (created: boolean): void => {
    setOpenModal(false);
    if (refreshRef.current && created) {
      refreshRef.current();
    }
  };

  return (
    <Record
      navBarHeight={navBarHeight}
      Content={(onError) => <TasksTable onError={onError} onRefreshCallback={onRefreshCallback} />}
      Header={
        <MainHeader
          title="Tasks"
          breadcrumbs={[
            { label: 'Actions', link: routes.dashboard.tasks.all.toString() },
            { label: 'Tasks', link: routes.dashboard.tasks.all.toString() },
          ]}
          action={
            <>
              {session.user?.role !== UserRole.User ? (
                <Button
                  onClick={() => {
                    setOpenModal(true);
                  }}
                  size="large"
                  variant="contained"
                >
                  New
                </Button>
              ) : null}
              {openModal && <AddActionModal onClose={onClose} />}
            </>
          }
        />
      }
    />
  );
};

export default Tasks;
