import styled from 'styled-components';

import { center } from '../../../core/styles';

import { AvatarSize } from '../Avatar/types';

export const InitialAvatarContainer = styled.div<{ size?: AvatarSize; $empty?: boolean; $color?: string }>`
  height: ${(props) => (props.size === 'small' ? 24 : 32)}px;
  width: ${(props) => (props.size === 'small' ? 24 : 32)}px;
  background-color: ${(props) => (props.$empty ? '#BDBDBD' : props.$color)};
  border-radius: 50%;
  flex-shrink: 0;
  ${center};
`;

export const InitialAvatarName = styled.span<{ size?: AvatarSize }>`
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
`;
