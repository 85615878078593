import dayjs, { ConfigType } from 'dayjs';

import prettyMilliseconds from 'pretty-ms';

import {
  DEFAULT_DATE_FORMAT_WITH_YEAR_AND_HOUR,
  DEFAULT_DATE_FORMAT,
  DEFAULT_FULL_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
  DOTS_FULL_DATE_FORMAT,
  FULL_DATETIME_FORMAT,
} from 'core/constants';

export const msToTimeVerbose = (time: number): string => prettyMilliseconds(time, { secondsDecimalDigits: 0 });

export function formatDuration(duration: number | null | undefined): string {
  if (duration === undefined || duration === null) {
    return '';
  }

  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = Math.floor(duration % 60);

  const formatLeadingZero = (value: number): string => (value >= 10 ? value.toString() : `0${value}`);
  return `${hours}:${formatLeadingZero(minutes)}:${formatLeadingZero(seconds)}`;
}

export function formatDate(date: ConfigType): string {
  return dayjs(date).format(DEFAULT_DATE_FORMAT);
}

export function formatFullDateWithDots(date: ConfigType): string {
  return dayjs(date).format(DOTS_FULL_DATE_FORMAT);
}

export function formatFullDate(date: ConfigType): string {
  return dayjs(date).format(DEFAULT_FULL_DATE_FORMAT);
}

export function formatTime(date: ConfigType): string {
  if (date === undefined || date === null) {
    return '';
  }

  return dayjs(date).format(DEFAULT_TIME_FORMAT);
}

export function formatFullDateTime(date: ConfigType): string {
  return dayjs(date).format(FULL_DATETIME_FORMAT);
}

export function formatDateTime(date: ConfigType | null | undefined): string {
  if (date === undefined || date === null) {
    return '';
  }

  return dayjs(date).format(DEFAULT_DATE_FORMAT_WITH_YEAR_AND_HOUR);
}

export function formatDateForDisplay(date: ConfigType): string {
  return dayjs(date)
    .utc()
    .local()
    .format('DD MMM YYYY, HH:mm');
}

export function useTimeZone(): string {
  const offSet = new Date().getTimezoneOffset() / 60;
  if (offSet < 0) {
    return `Etc/GMT${offSet}`;
  }
  if (offSet > 0) {
    return `Etc/GMT%2B${offSet}`;
  }
  return 'Etc/GMT';
}
