/* eslint-disable max-len */
import React from 'react';

function LockIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        d="M13.5 6H12.75V4.5C12.75 2.43 11.07 0.75 9 0.75C6.93 0.75 5.25 2.43 5.25 4.5V6H4.5C3.675 6 3 6.675 3 7.5V15C3 15.825 3.675 16.5 4.5 16.5H13.5C14.325 16.5 15 15.825 15 15V7.5C15 6.675 14.325 6 13.5 6ZM9 12.75C8.175 12.75 7.5 12.075 7.5 11.25C7.5 10.425 8.175 9.75 9 9.75C9.825 9.75 10.5 10.425 10.5 11.25C10.5 12.075 9.825 12.75 9 12.75ZM11.325 6H6.675V4.5C6.675 3.2175 7.7175 2.175 9 2.175C10.2825 2.175 11.325 3.2175 11.325 4.5V6Z"
        fill="black"
        fillOpacity="0.38"
      />
    </svg>
  );
}

export default LockIcon;
