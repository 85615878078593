import React from 'react';

import { FormControlLabel } from '@mui/material';

import { ColumnActions } from 'components/Grid/types';

import { TemplateListResponse } from 'utils/api/types';

import { useStore } from 'utils/hooks/store';

import API from 'utils/api';

import Logger from 'utils/logger';

import { StyleMUISwitch } from './styles';

const ActiveColumn: React.FC<{ row: TemplateListResponse; actions: ColumnActions }> = ({ row, actions }) => {
  const { notification } = useStore();
  const { isActive, uuid } = row;
  const onClick = (event: React.SyntheticEvent): void => {
    onToggleActive();
    event.stopPropagation();
  };

  const onToggleActive = React.useCallback(async (): Promise<void> => {
    try {
      await API.updateTemplate(uuid, {
        isActive: !isActive,
      })();
      actions.refreshData();
      notification.enqueueSuccessSnackbar('Template has been updated');
    } catch (e) {
      notification.enqueueErrorSnackbar('Template cannot be updated right now.');
      Logger.error(`Invalid update form API response. Template ID: ${uuid}`, e);
    }
  }, [isActive, uuid]);

  return (
    <FormControlLabel
      control={<StyleMUISwitch aria-label="Mobile app Safety Tab switch" checked={isActive} onClick={onClick} />}
      label={isActive ? 'Active' : 'Active'}
    />
  );
};

export default ActiveColumn;
