import { configure } from 'mobx';
import React from 'react';
import { Provider as MobXProvider } from 'mobx-react';
import { syncHistoryWithStore } from '@superwf/mobx-react-router';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router';
import CssBaseline from '@mui/material/CssBaseline';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import mixpanel from 'mixpanel-browser';

import { injectStyle } from 'react-toastify/dist/inject-style';

import dayjs from 'dayjs';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { StyledToastContainer } from 'core/styles';

import SystemError from 'containers/Error/Pages/SystemError/SystemError';

import rootStore from '../../stores';
import Routing from './components/Routing';
import ErrorBoundary from '../../components/ErrorBoundary';
import Session from '../Session';
import StyleProvider from './components/StyleProvider';

injectStyle();

configure({ enforceActions: 'always' });

const browserHistory = createBrowserHistory();

const history = syncHistoryWithStore(browserHistory, rootStore.stores.routing);
history.listen((location) => {
  if (process.env.JEST_WORKER_ID === undefined) {
    mixpanel.track_pageview({ page: location.pathname });
  }
});

dayjs.extend(utc);
dayjs.extend(timezone);

const App: React.FunctionComponent = (): React.ReactElement => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <StyleProvider>
      <MobXProvider {...rootStore.stores}>
        <ErrorBoundary FallbackComponent={SystemError}>
          <CssBaseline />
          <Session>
            <Router history={history}>
              <Routing />
              <StyledToastContainer limit={5} />
            </Router>
          </Session>
        </ErrorBoundary>
      </MobXProvider>
    </StyleProvider>
  </LocalizationProvider>
);

export default App;
