import React from 'react';
import { inject, observer } from 'mobx-react';

import { FullHeightWrapper, SecondaryContainer } from 'vatix-ui/lib/components/Container';

import { CenteredCircularProgress } from 'components/Progress';

import DataError from 'containers/Error/DataError';

import { InjectedConfigProps } from './types';

@inject('config')
@observer
export default class Config extends React.Component<{ children?: React.ReactNode }> {
  componentDidMount(): void {
    const { config } = this.injected;

    config.loadConfig();
  }

  get injected(): InjectedConfigProps {
    return this.props as InjectedConfigProps;
  }

  render(): React.ReactNode {
    const { children } = this.props;
    const { config } = this.injected;

    if (!config.isLoaded) {
      return (
        <FullHeightWrapper>
          <CenteredCircularProgress />;
        </FullHeightWrapper>
      );
    }
    if (config.isFailure) {
      return (
        <SecondaryContainer>
          <DataError onReload={config.loadConfig} />
        </SecondaryContainer>
      );
    }

    return children;
  }
}
