import React from 'react';
import { observer } from 'mobx-react';
import AddCommentActivities from 'vatix-ui/lib/components/AddCommentActivities';
import FeedComponent from 'vatix-ui/lib/components/Feed';
import { DefaultFeedMapping } from 'vatix-ui/lib/components/Feed/Feed';

import { CenteredCircularProgress } from 'components/Progress';

import SubmissionActivitiesStore from 'stores/IncidentDetails/SubmissionActivities';

import { SessionStoreWithUser } from 'stores/Session/types';
import API from 'utils/api';
import { useStore } from 'utils/hooks/store';

import { ScrollableContainer, CommentLayout } from 'containers/UserDetails/components/UserActivities/styles';

import { USER_TAG_BASE } from 'core/constants';

import { ActivityMapping } from 'utils/api/types';

import { Container, SubmissionActivityContainer, SubmissionActivityWrapper } from './styles';

const PADDING_BOTTOM_ACTIVITIES = 120;

const activityTypeToComponent = ({
  ...DefaultFeedMapping,
} as unknown) as ActivityMapping;

const SubmissionActivity: React.FunctionComponent = (): React.ReactElement => {
  const scrollableRef = React.useRef<HTMLDivElement | null>(null);
  const [keepBottom, setKeepBottom] = React.useState(true);
  const { incidentDetails, session: sessionRaw } = useStore();
  const session = sessionRaw as SessionStoreWithUser;
  const activities = incidentDetails.submissionActivities as SubmissionActivitiesStore;

  const onScroll = (e: React.UIEvent<HTMLDivElement>): void => {
    // @ts-ignore
    const bottom = e.target.clientHeight;
    // @ts-ignore
    const scrolledAt = e.target.scrollHeight - e.target.scrollTop;
    const shouldKeepBottom = scrolledAt - bottom <= PADDING_BOTTOM_ACTIVITIES;

    if (shouldKeepBottom !== keepBottom) {
      setKeepBottom(shouldKeepBottom);
    }
  };

  const onSizeChanged = (newHeight: number): void => {
    if (keepBottom && scrollableRef.current) {
      scrollableRef.current.scrollTop = newHeight;
    }
  };

  if (activities === undefined || activities.activities === undefined) {
    return (
      <Container>
        <CenteredCircularProgress />
      </Container>
    );
  }

  return (
    <SubmissionActivityContainer>
      <SubmissionActivityWrapper>
        <Container>
          <ScrollableContainer
            onScroll={onScroll}
            ref={scrollableRef}
            style={{
              height: 'fit-content',
              maxHeight: activities.activities.length > 0 ? '500px' : '',
            }}
          >
            <CommentLayout style={{ padding: activities.activities.length > 0 ? '20px 24px 0px' : '0' }}>
              <FeedComponent
                // @ts-ignore
                originalActivities={{ ...activities, hasMore: false }}
                mapping={activityTypeToComponent}
                onSizeChanged={onSizeChanged}
                usersSearch={API.searchUsers}
                sessionUser={session.user || { name: 'Reporter', uuid: 'reporter-uuid' }}
                userTagBaseUrl={USER_TAG_BASE}
                onDeleteFile={activities.removeActivity}
                onDeleteComment={activities.removeActivity}
                nonEditable
              />
            </CommentLayout>
          </ScrollableContainer>
          <AddCommentActivities
            borderless
            placeholder="Add any additional notes, pictures or files"
            // @ts-ignore
            uploadFile={activities.addSubmissionFile}
            usersSearch={API.searchUsers}
            // @ts-ignore
            sendMessage={activities.addSubmissionMessage}
          />
        </Container>
      </SubmissionActivityWrapper>
    </SubmissionActivityContainer>
  );
};

export default observer(SubmissionActivity);
