import { prepareIcon } from './Icon';
import warning from '../../assets/icons/Warning.png';
import warning2x from '../../assets/icons/Warning@2x.png';
import warning3x from '../../assets/icons/Warning@3x.png';

const Warning = prepareIcon({
  alt: 'Warning',
  src: warning,
  srcSet: `
    ${warning2x} 2x,
    ${warning3x} 3x
  `,
});

export default Warning;
