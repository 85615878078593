import { observer } from 'mobx-react';
import React from 'react';

import { Divider, Grid, Switch } from '@mui/material';

import Menu from '@mui/material/Menu';

import MenuItem from '@mui/material/MenuItem';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import UserDetailsData from 'stores/UserDetails/UserDetailsData';

import { UserRole as UserRoleType } from 'core/constants';

import API from 'utils/api';

import RoleInfo from 'containers/Users/components/AddUsersModal/components/RoleInfo';

import { UpdatedUserPayload } from 'utils/events/types';

import { postMessage } from 'utils/events/broadcast';
import { EventType } from 'utils/events/constants';

import { SectionGrid } from 'components/BackgroundInformation/styles';

import Chip from 'components/Chip';

import Logger from 'utils/logger';

import { StyledSectionGrid, StyledRowTitle, RowValue, ProductLicencesGroup } from './styles';
import { useStore } from '../../../../utils/hooks/store';
import { TabTitle } from '../PersonalInformation/styles';

const UserRoleNames: Record<string, string> = Object.freeze({
  manager: 'Manager',
  account_manager: 'Account Owner',
  user: 'User',
  admin: 'Admin',
});

const Row: React.FC<{ name: string; children: React.ReactNode; isRoleRow?: boolean; id?: string }> = ({
  name,
  children,
  id,
  isRoleRow = false,
}): React.ReactElement => (
  <>
    <StyledSectionGrid item container xs={2} alignContent="flex-start" id={id} alignItems="center">
      <StyledRowTitle style={{ marginTop: '12px', marginBottom: '5px' }}>{name}</StyledRowTitle>
      {isRoleRow && <RoleInfo />}
    </StyledSectionGrid>
    <StyledSectionGrid item container xs={10}>
      {children}
    </StyledSectionGrid>
  </>
);

const ProductAccess: React.FunctionComponent = () => {
  const {
    userDetails: { details: detailsRaw },
    notification,
    session,
  } = useStore();
  const details = detailsRaw as UserDetailsData;
  const [updating, setUpdating] = React.useState(false);

  const onChangeLicense = async (licenseName: string, licenseValue: boolean): Promise<void> => {
    setUpdating(true);
    try {
      const response = await API.updateUserData(details.uuid, {
        licenses: { ...details.licenses, [licenseName]: licenseValue },
      })();
      const payload: UpdatedUserPayload = response.data;
      postMessage(EventType.UpdatedUser, payload);
      notification.enqueueSuccessSnackbar('Licence updated successfully!');
    } catch (e) {
      Logger.error('Invalid update user details API response', e);
      notification.enqueueErrorSnackbar('Could not update licence');
    }
    setUpdating(false);
  };

  const onRoleUpdate = async (role: UserRoleType): Promise<void> => {
    setUpdating(true);
    try {
      const response = await API.updateUserData(details.uuid, { role })();
      const payload: UpdatedUserPayload = response.data;
      postMessage(EventType.UpdatedUser, payload);
      notification.enqueueSuccessSnackbar('User role updated successfully!');
    } catch (e) {
      Logger.error('Invalid update user details API response', e);
      notification.enqueueErrorSnackbar('Could not update user role');
    }
    setUpdating(false);
  };

  const [roleMenuAnchorEl, setRoleMenuAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleOpenRoleMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setRoleMenuAnchorEl(event.currentTarget);
  };
  const handleCloseRoleMenu = (): void => {
    setRoleMenuAnchorEl(null);
  };
  const onSelectedOption = (role: UserRoleType): void => {
    onRoleUpdate(role);
    handleCloseRoleMenu();
  };

  return (
    <>
      <Grid
        container
        style={{ padding: '16px 20px', borderBottom: '1px solid rgba(0, 0, 0, 0.12)', minHeight: '68px' }}
        alignItems="center"
      >
        <SectionGrid item container xs={12} alignContent="flex-start">
          <TabTitle>Product Access</TabTitle>
        </SectionGrid>
      </Grid>
      <Grid container rowSpacing="16px" style={{ padding: '20px' }}>
        <Row name="Role" isRoleRow>
          <RowValue>
            <Chip
              disabled={session.user?.uuid === details.uuid}
              label={UserRoleNames[details.role]}
              variant="outlined"
              size="medium"
              fontSize={14}
              iconRight={
                roleMenuAnchorEl ? (
                  <ExpandLessIcon style={{ color: 'rgba(0,0,0,0.54)' }} />
                ) : (
                  <ExpandMoreIcon style={{ color: 'rgba(0,0,0,0.54)' }} />
                )
              }
              iconRightDisableInnerMargin
              onClick={handleOpenRoleMenu}
              colors={{
                background: '#FFF',
                border: 'rgba(0, 0, 0, 0.26)',
                hoverBackground: 'rgba(0, 0, 0, 0.12)',
              }}
              enableHover
            />
            <Menu
              keepMounted
              anchorEl={roleMenuAnchorEl}
              onClose={handleCloseRoleMenu}
              open={Boolean(roleMenuAnchorEl)}
            >
              {[UserRoleType.AccountManager, UserRoleType.Manager, UserRoleType.User].map((role) => (
                <MenuItem onClick={() => onSelectedOption(role)}>{UserRoleNames[role]}</MenuItem>
              ))}
            </Menu>
          </RowValue>
        </Row>
        <Row name="Product licences">
          <ProductLicencesGroup>
            <div>
              <p>Lone Working</p>
              <Switch
                disabled={updating}
                size="small"
                id="productLicenses.alarms"
                checked={details.licenses.protectorAlarms}
                onChange={(e) => onChangeLicense('protectorAlarms', e.target.checked)}
              />
            </div>
            <Divider style={{ width: '100%' }} />

            {session.hasEventsLicense ? (
              <div>
                <p>Events</p>
                <Switch
                  disabled={updating}
                  size="small"
                  id="productLicenses.events"
                  checked={details.licenses.events}
                  onChange={(e) => onChangeLicense('events', e.target.checked)}
                />
              </div>
            ) : (
              <div>
                <p>Incidents</p>
                <Switch
                  disabled={updating}
                  size="small"
                  id="productLicenses.incidents"
                  checked={details.licenses.protectorIncidents}
                  onChange={(e) => onChangeLicense('protectorIncidents', e.target.checked)}
                />
              </div>
            )}

            <Divider style={{ width: '100%' }} />
            <div>
              <p>Audits</p>
              <Switch
                disabled={updating}
                size="small"
                id="productLicenses.audits"
                checked={details.licenses.workflows}
                onChange={(e) => onChangeLicense('workflows', e.target.checked)}
              />
            </div>
          </ProductLicencesGroup>
        </Row>
      </Grid>
    </>
  );
};

export default observer(ProductAccess);
