import React from 'react';
import { CircularProgress, Collapse, Grid } from '@mui/material';
import { ExpandMore, ExpandLess, Edit } from '@mui/icons-material';

import { Button } from 'components/Button';

import CustomFieldDisplayValue from 'containers/IncidentDetails/components/CustomFieldDisplayValue';
import IncidentFormField from 'containers/IncidentDetails/components/IncidentFormField/IncidentFormField';
import { SectionGrid } from 'components/BackgroundInformation/styles';
import { AnswerType, AnswerTypes, JSONSchemaType, ProtectorType } from 'utils/api/types';

import { useStore } from 'utils/hooks/store';

import Row from 'containers/IncidentDetails/components/IncidentInformation/components/Row/Row';

import {
  RowContainer,
  RowWrapper,
  StyledDivider,
} from 'containers/IncidentDetails/components/IncidentInformation/styles';

import { UserRole } from 'core/constants';

import { CardContainer, SectionContainerHeader, Title, Section, EditFieldDescription, TitleWrapper } from './styles';

const EditRow: React.FC<{ name: string; children: React.ReactNode; id?: string }> = ({
  name,
  children,
  id,
}): React.ReactElement => (
  <>
    <Grid item container xs={12} alignContent="flex-start" id={id}>
      <EditFieldDescription>{name}</EditFieldDescription>
    </Grid>
    <Grid item container xs={12}>
      {children}
    </Grid>
  </>
);

const CustomFieldContainer: React.FunctionComponent<{
  onUpdate: (newValue: AnswerType) => Promise<boolean>;
  schema: JSONSchemaType;
  content: AnswerType;
}> = ({ schema, content, onUpdate }): React.ReactElement => {
  const { incidentDetails, session } = useStore();
  const [data, setData] = React.useState(content);
  const [expanded, setExpanded] = React.useState(true);
  const [editing, setEditing] = React.useState(false);
  const [saving, setSaving] = React.useState(false);

  const onCancel = (): void => {
    incidentDetails.setEditedContent(null);
    setData(content);
    setEditing(false);
  };

  const handleExpandClick = (): void => {
    setExpanded((prevState) => !prevState);
  };

  const onSave = async (): Promise<void> => {
    setSaving(true);
    if (incidentDetails.checkContent(data, schema)) {
      const updateResult = await onUpdate(data);

      if (updateResult) {
        setEditing(false);
      }
    }
    setSaving(false);
  };

  const onChange = (i: string, value: AnswerTypes): void => {
    setData({
      ...data,
      [i]: value,
    } as AnswerType);
    incidentDetails.setEditedContent({
      ...data,
      [i]: value,
    } as AnswerType);
  };

  return (
    <Section>
      <SectionContainerHeader $expanded={expanded}>
        <TitleWrapper onClick={handleExpandClick}>
          {expanded ? (
            <ExpandLess aria-labelledby="show less" fillOpacity="0.54" />
          ) : (
            <ExpandMore aria-labelledby="show more" fillOpacity="0.54" />
          )}
          <Title id="section-description">{schema.description}</Title>
        </TitleWrapper>
        <SectionGrid item container xs={2} justifyContent="flex-end">
          {!editing && Object.keys(schema.properties || {}).length > 0 && !session?.user?.readOnly && (
            <Button onClick={() => setEditing(true)}>
              <Edit style={{ width: 20, height: 20, marginRight: '8px' }} />
              Edit
            </Button>
          )}
        </SectionGrid>
      </SectionContainerHeader>
      <Collapse in={expanded} style={{ padding: '0 16px' }}>
        <CardContainer>
          <Grid container spacing={0}>
            {schema.order.map((key, index) => {
              const properties = schema.properties[key];
              if (properties === undefined) {
                if (session?.user?.role === UserRole.Admin) {
                  // information for admins that schema is not valid
                  // eslint-disable-next-line no-console
                  console.error('undefined properties', key);
                }
                return null;
              }
              const type = properties.protectorType as ProtectorType;

              return (
                <React.Fragment key={key}>
                  {!editing ? (
                    <RowWrapper>
                      <RowContainer>
                        <Row
                          name={properties.description}
                          key={`display-value-${key}`}
                          id={`field-${schema.order.indexOf(key) + 1}`}
                        >
                          <CustomFieldDisplayValue type={type} value={data[key]} />
                        </Row>
                      </RowContainer>
                      {index !== schema.order.length - 1 && <StyledDivider />}
                    </RowWrapper>
                  ) : (
                    <EditRow
                      name={properties.description}
                      key={`edit-value-${key}`}
                      id={`field-${schema.order.indexOf(key) + 1}`}
                    >
                      <IncidentFormField
                        type={type}
                        value={data[key] || ''}
                        onChange={(value) => onChange(key, value)}
                        description={properties.description}
                        items={properties.items}
                        maximum={properties.maximum}
                        minimum={properties.minimum}
                        requiredNotFilled={false}
                        lookupType={properties.lookupType}
                      />
                    </EditRow>
                  )}
                </React.Fragment>
              );
            })}
            {editing && (
              <SectionGrid item container xs={12} marginTop={2} width="auto" justifyContent="flex-end">
                <Button onClick={onCancel} style={{ marginRight: '8px' }} variant="outlined" size="large">
                  Cancel
                </Button>
                {saving ? (
                  <Button
                    disabled
                    startIcon={<CircularProgress size={24} color="inherit" />}
                    variant="contained"
                    size="large"
                  >
                    Saving...
                  </Button>
                ) : (
                  <Button onClick={onSave} variant="contained" size="large">
                    Save
                  </Button>
                )}
              </SectionGrid>
            )}
          </Grid>
        </CardContainer>
      </Collapse>
    </Section>
  );
};
export default CustomFieldContainer;
