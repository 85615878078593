/* eslint-disable max-len */
import React from 'react';
import { observer } from 'mobx-react';

import { CircularProgress } from '@mui/material';

import { CenteredCircularProgress } from 'components/Progress';

import { useStore } from 'utils/hooks/store';

import { MainContainer, DayTitle, ItemContainer } from './styles';
import EmptyInboxContainer from '../EmptyInboxContainer';
import NotificationItem from '../NotificationItem';

const NotificationsList: React.FunctionComponent = (): React.ReactElement => {
  const {
    inbox: {
      initialSync,
      loadInitialData,
      notifications,
      isLoading,
      loadNotifications,
      isDismissing,
      dismissNotifications,
    },
  } = useStore();
  const containerRef = React.useRef(null);

  const handleScroll = (): void => {
    const container = containerRef.current;
    if (container) {
      // @ts-ignore
      const containerHeight = container.offsetHeight;
      const scrollY = window.innerHeight + window.scrollY;
      if (scrollY >= containerHeight) {
        loadNotifications();
      }
    }
  };

  React.useEffect(() => {
    if (initialSync === undefined) {
      loadInitialData();
    }
  }, []);

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  React.useEffect(
    () => () => {
      if (!isDismissing) {
        dismissNotifications();
      }
    },
    [isDismissing]
  );

  if (!initialSync && isLoading) {
    return <CenteredCircularProgress data-testid="loading" />;
  }

  if (notifications === undefined || notifications.length === 0) {
    return <EmptyInboxContainer />;
  }

  const msToDays = (time: number): number => Math.floor(time / 1000 / 60 / 60 / 24);

  const sections = [
    {
      title: 'TODAY',
      data: notifications.filter((item) => msToDays(new Date().getTime() - new Date(item.created).getTime()) === 0),
    },
    {
      title: 'YESTERDAY',
      data: notifications.filter((item) => msToDays(new Date().getTime() - new Date(item.created).getTime()) === 1),
    },
    {
      title: 'PAST 7 DAYS',
      data: notifications.filter((item) => {
        const daysPassed = msToDays(new Date().getTime() - new Date(item.created).getTime());
        return daysPassed > 1 && daysPassed <= 7;
      }),
    },
    {
      title: 'EARLIER',
      data: notifications.filter((item) => msToDays(new Date().getTime() - new Date(item.created).getTime()) > 7),
    },
  ];

  return (
    <MainContainer ref={containerRef} data-testid="notification-list">
      {sections.map(({ title, data }) => (
        <div key={`${title}-${data.length}`}>
          {data.length > 0 ? <DayTitle key={`title-${title}`}>{title}</DayTitle> : null}
          {data.map((i) => (
            <NotificationItem item={i} />
          ))}
        </div>
      ))}
      {isLoading ? (
        <ItemContainer>
          <CircularProgress />
        </ItemContainer>
      ) : null}
    </MainContainer>
  );
};

export default observer(NotificationsList);
