import React from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';

import WarningIcon from 'components/Icons/Warning';

import Error from 'components/Error/Error';

import { ErrorLink } from 'components/Error/styles';

import { DataErrorProps } from './types';

function DataError({ onReload, message, icon, reloadText, ...props }: DataErrorProps): React.ReactElement {
  return (
    <Error message={message} icon={icon} {...props}>
      {onReload && (
        <ErrorLink onClick={onReload}>
          <RefreshIcon color="primary" />
          {reloadText}
        </ErrorLink>
      )}
    </Error>
  );
}

DataError.defaultProps = {
  message: 'We are unable to load data',
  reloadText: 'Reload',
  icon: WarningIcon,
};

export default DataError;
