import styled from 'styled-components';

export const Container = styled.div`
  margin: 20px;
  display: flex;
  padding: 80px 8px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;

  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  background: #fff;
`;

export const IllustrationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h2`
  margin: 0;
  margin-top: 10px;
  margin-bottom: 12px;
  color: #223b65;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.15px;
  text-align: center;
`;

export const Subtitle = styled.p`
  color: rgba(0, 0, 0, 0.6);
  margin: 0;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-align: center;
`;

export const Controls = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;
`;
