import React from 'react';

import { observer } from 'mobx-react';

import EventItem from 'vatix-ui/lib/components/Feed/EventItem';

import { AssignmentActivityResponse, UuidableName } from 'utils/api/types';

interface AssignmentProps {
  activity: AssignmentActivityResponse;
  sessionUser: UuidableName;
}

const Assignment: React.FunctionComponent<AssignmentProps> = ({ activity, sessionUser }) => (
  <EventItem activity={activity} sessionUser={sessionUser}>
    assigned task to {activity.user?.name}
  </EventItem>
);

export default observer(Assignment);
