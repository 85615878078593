import React from 'react';

import { LuzmoDashboardComponent } from '@luzmo/react-embed';

import { useRouteMatch } from 'react-router-dom';

import Record from 'vatix-ui/lib/containers/Record/Record';

import { MainHeader } from 'components/MainHeader/MainHeader';

import API from 'utils/api';
import { DashboardDetails } from 'utils/api/types';

import { useNavBarHeight } from 'utils/hooks/navbar';
import routes from 'core/routes';

const Analytics = (): React.ReactElement => {
  const { params } = useRouteMatch<{ dashboardId: string }>();
  const navBarHeight = useNavBarHeight();
  const [dashboard, setDashboard] = React.useState<DashboardDetails | null>();

  React.useEffect(() => {
    (async () => {
      const response = await API.loadDashboard(params.dashboardId)();
      setDashboard(response.data);
    })();
  }, [params.dashboardId]);

  return (
    <Record
      navBarHeight={navBarHeight}
      disableMaxContentHeight
      Content={() =>
        dashboard ? (
          <LuzmoDashboardComponent
            authKey={dashboard.authKey}
            authToken={dashboard.token}
            dashboardSlug={dashboard.dashboardId}
            loaderBackground="#edecf2"
          />
        ) : (
          <></>
        )
      }
      Header={
        <MainHeader
          title={dashboard?.name || 'Analytics'}
          breadcrumbs={[
            { label: 'Analytics', link: String(routes.dashboard.analytics) },
            { label: dashboard?.name || 'Overview', link: `/analytics/${params.dashboardId}/` },
          ]}
        />
      }
    />
  );
};

export default Analytics;
