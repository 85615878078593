import { observer } from 'mobx-react';
import React from 'react';

import { DataLoaderProps } from './types';

const DataLoader: React.FunctionComponent<DataLoaderProps> = ({
  children,
  store: { isLoaded, isFailure, loadData },
}) => {
  React.useEffect(() => {
    if (!isLoaded) {
      loadData();
    }
  }, [isLoaded, loadData]);

  return children({
    isLoaded,
    isFailure,
  });
};

export default observer(DataLoader);
