import { observer } from 'mobx-react';

import React from 'react';

import Record from 'vatix-ui/lib/containers/Record/Record';

import { Divider, Tooltip } from '@mui/material';

import MainHeader from 'components/MainHeader';

import routes from 'core/routes';

import { useNavBarHeight } from 'utils/hooks/navbar';
import { useStore } from 'utils/hooks/store';
import CustomModal from 'components/CustomModal';

import ChangePasswordForm from './components/ChangePasswordForm';
import TwoFactorAuthSetupForm from './components/TwoFactorAuthSetupForm';

import {
  Cell,
  ChangePasswordButton,
  Container,
  DisabledSettingsDescription,
  Enable2FAButton,
  Enable2FAButtonWrapper,
  SettingTitle,
  SettingsDescription,
  VerticalContainer,
} from './styles';

const ProfileSecuritySettings: React.FunctionComponent = () => {
  const {
    session: { user },
  } = useStore();
  const navBarHeight = useNavBarHeight();
  const [isPasswordModalOpened, setIsPasswordModalOpened] = React.useState(false);
  const [is2faModalOpened, setIs2faModalOpened] = React.useState(false);
  const [twoFactorModalDescription, setTwoFactorModalDescription] = React.useState(
    'To setup two-factor authentication, please provide your phone number.'
  );

  return (
    <Record
      Header={
        <MainHeader
          title={user ? user.name || user.email : 'Profile Settings'}
          breadcrumbs={[
            {
              label: 'Profile Settings',
              link: routes.dashboard.profileSettings.notifications.toString(),
            },
            {
              label: 'Security',
              link: routes.dashboard.profileSettings.notifications.toString(),
            },
          ]}
        />
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      Content={(_) => (
        <Container>
          <Cell>
            <VerticalContainer>
              <SettingTitle>Password</SettingTitle>
              {!user?.ssoUser ? (
                <SettingsDescription>Set a permanent password to log in to your account</SettingsDescription>
              ) : (
                <DisabledSettingsDescription>
                  Password section is not available for your account because you login with Single-Sign-On (SSO)
                </DisabledSettingsDescription>
              )}
            </VerticalContainer>
            <ChangePasswordButton
              onClick={() => {
                setIsPasswordModalOpened(true);
              }}
              disabled={user?.ssoUser}
            >
              Change Password
            </ChangePasswordButton>
            {isPasswordModalOpened && (
              <CustomModal
                openModal
                onClose={() => {
                  setIsPasswordModalOpened(false);
                }}
                modalTitle="Change Password"
                modalDescription="Use a password at least 8 characters long with both letters and numbers."
              >
                <ChangePasswordForm
                  onClose={() => {
                    setIsPasswordModalOpened(false);
                  }}
                />
              </CustomModal>
            )}
          </Cell>
          <Cell>
            <VerticalContainer>
              <SettingTitle>2-Factor Authentication</SettingTitle>
              {!user?.ssoUser ? (
                <SettingsDescription>
                  2-Factor Authentication (2FA) adds an extra layer of security by requiring a second verification step
                  in addition to your password
                </SettingsDescription>
              ) : null}
              <Divider
                sx={{
                  ...(!user?.ssoUser
                    ? {}
                    : {
                        marginTop: '14px',
                        marginBottom: '16px',
                      }),
                }}
              />
              {!user?.ssoUser ? (
                <Tooltip
                  describeChild
                  title={
                    user !== undefined && user?.twoFactorAuthAllowed === false
                      ? // eslint-disable-next-line max-len
                        "Unable to enable Two-Factor Authentication: this feature is currently restricted by your organisation's settings. For more information, please contact your admin."
                      : undefined
                  }
                >
                  <Enable2FAButtonWrapper>
                    <Enable2FAButton
                      onClick={() => {
                        setIs2faModalOpened(true);
                      }}
                      disabled={user === undefined || user?.twoFactorAuthAllowed === false}
                    >
                      {user?.twoFactorAuthEnabled ? 'Change' : 'Enable'}
                    </Enable2FAButton>
                  </Enable2FAButtonWrapper>
                </Tooltip>
              ) : (
                <DisabledSettingsDescription>
                  Two-Factor Authentication is not available for your account because you login with Single-Sign-On
                  (SSO)
                </DisabledSettingsDescription>
              )}

              {is2faModalOpened && (
                <CustomModal
                  openModal
                  onClose={() => {
                    setIs2faModalOpened(false);
                  }}
                  modalTitle="Two-Factor Authentication"
                  modalDescription={twoFactorModalDescription}
                >
                  <TwoFactorAuthSetupForm
                    onClose={() => {
                      setIs2faModalOpened(false);
                    }}
                    onInitialVerificationCodeSent={() => {
                      setTwoFactorModalDescription(
                        "We've sent a 6-digit code to your phone number. Please enter it below."
                      );
                    }}
                    onVerificationCodeResent={() => {
                      setTwoFactorModalDescription("Confirm that it's you. We sent a message with confirmation code.");
                    }}
                  />
                </CustomModal>
              )}
            </VerticalContainer>
          </Cell>
        </Container>
      )}
      navBarHeight={navBarHeight}
    />
  );
};

export default observer(ProfileSecuritySettings);
