import React from 'react';

import { ErrorMessage, ErrorWrapper } from './styles';
import { ErrorProps } from './types';

const Error: React.FunctionComponent<ErrorProps> = (props): React.ReactElement => {
  const { message, icon: IconComponent, children, ...otherProps } = props;

  return (
    <ErrorWrapper {...otherProps}>
      <IconComponent />

      <ErrorMessage variant="h1">{message}</ErrorMessage>

      {children}
    </ErrorWrapper>
  );
};

export default Error;
