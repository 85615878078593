import React from 'react';

import withGoogMaps from 'vatix-ui/lib/components/Map/withGoogleMaps';
import Map from 'vatix-ui/lib/components/Map';

import { CircularProgress } from '@mui/material';

import { Button } from 'components/Button';

import MapControls from 'containers/UserDetails/components/PersonalMap/components/MapControls';

import api from 'utils/api';

import { AlertStatus } from 'core/constants';

import BreadCrumbs from './components/BreadCrumbs/BreadCrumbs';
import { UuidableLocation, UuidableLocationRedAmberAlert } from './types';
import Row from '../Row';
import { Value } from '../AlarmCard/styles';

const MapComponentWithLoader = withGoogMaps(Map, (process.env.REACT_APP_GOOGLE_MAPS_API_KEY as unknown) as string);

const AlarmLocation: React.FunctionComponent<{
  alarmId: string;
}> = ({ alarmId }): JSX.Element => {
  const [showMap, setShowMap] = React.useState(true);
  const toggleMap = (): void => {
    setShowMap((prev) => !prev);
  };

  const [moved, setMoved] = React.useState(false);
  const [locationHistory, setLocationHistory] = React.useState<UuidableLocation[] | undefined>(undefined);

  const getAlertStatus = (location: UuidableLocationRedAmberAlert): AlertStatus => {
    if (location.hasRedAlert) {
      return AlertStatus.RedAlert;
    }
    if (location.hasAmberAlert) {
      return AlertStatus.AmberAlert;
    }
    return AlertStatus.NoAlert;
  };

  React.useEffect(() => {
    const loadDeviceLocationHistory = async (): Promise<void> => {
      const { data } = await api.loadAlarmLocations(alarmId)();
      const formattedData: UuidableLocation[] = data.results
        .map((el) => ({
          ...el,
          lat: Number(el.lat),
          lng: Number(el.lng),
          alertsStatus: getAlertStatus(el),
        }))
        .reverse();

      setLocationHistory(formattedData);
    };
    loadDeviceLocationHistory();
    const intervalId = setInterval(loadDeviceLocationHistory, 60000);

    return () => {
      clearInterval(intervalId);
    };
  }, [alarmId]);

  const positionOfLastElement = {
    lat: Number(locationHistory?.[locationHistory.length - 1]?.lat || 0),
    lng: Number(locationHistory?.[locationHistory.length - 1]?.lng || 0),
  };
  return (
    <>
      <Row name="Locations" id="alarm-location">
        {locationHistory && locationHistory.length > 0 ? (
          <Button onClick={toggleMap} variant="text" style={{ padding: 0 }}>
            {showMap ? 'Hide Map' : 'Show Map'}
          </Button>
        ) : (
          <Value>No location available</Value>
        )}
      </Row>

      {locationHistory && locationHistory.length > 0 ? (
        <>
          {showMap &&
            (locationHistory ? (
              <MapComponentWithLoader
                aria-label="alarm-location-map"
                radius={120}
                center={moved ? undefined : positionOfLastElement}
                onDrag={() => setMoved(true)}
                mapContainerStyle={{
                  minWidth: '100%',
                  minHeight: 500,
                }}
              >
                {locationHistory.length > 0 ? <BreadCrumbs path={locationHistory} /> : null}
                <MapControls position={positionOfLastElement} />
              </MapComponentWithLoader>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                <CircularProgress />
              </div>
            ))}
        </>
      ) : null}
    </>
  );
};

export default AlarmLocation;
