import React from 'react';

import Record from 'vatix-ui/lib/containers/Record/Record';

import { observer } from 'mobx-react';

import MainHeader from 'components/MainHeader';

import { UserRole } from 'core/constants';
import { useStore } from 'utils/hooks/store';
import { useNavBarHeight } from 'utils/hooks/navbar';
import routes from 'core/routes';

import { Container, Header, SettingsDescription, SettingsName, SettingsRecap, SettingsRow } from './styles';

import NotificationSettingsSwitch from './components/NotificationSettingsSwitch';

const ProfileNotificationsSettings = (): React.ReactElement => {
  const navBarHeight = useNavBarHeight();
  const {
    session: { user },
    notificationSettings: {
      loadData,
      notificationsSettings,
      isLoading,
      isFailure,
      isMentionsEmailSettingEnabled,
      updateMentionsSettings,
      isNewIncidentsEmailSettingEnabled,
      updateNewIncidentsSettings,
      isNewAssignmentsEmailSettingsEnabled,
      updateNewAssignmentsSettings,
      isNewAuditsEmailSettingEnabled,
      updateNewAuditsSettings,
      updateNewEventsSettings,
      isNewEventsEmailSettingEnabled,
    },
  } = useStore();

  React.useEffect(() => {
    if (!isLoading && !isFailure && notificationsSettings === undefined) {
      loadData();
    }
  }, [isLoading, isFailure, notificationsSettings]);

  const showNewIncidentsSettings =
    user?.licenses.protectorIncidents &&
    [UserRole.Admin, UserRole.AccountManager, UserRole.Manager].includes(user.role);

  const showNewAuditsSettings =
    user?.licenses.workflows && [UserRole.Admin, UserRole.AccountManager, UserRole.Manager].includes(user.role);

  const showNewEventSettings =
    user?.licenses.events && [UserRole.Admin, UserRole.AccountManager, UserRole.Manager].includes(user.role);

  return (
    <Record
      Header={
        <MainHeader
          title="Notifications"
          breadcrumbs={[
            {
              label: 'Profile Settings',
              link: routes.dashboard.profileSettings.security.toString(),
            },
            {
              label: 'Notifications',
              link: routes.dashboard.profileSettings.notifications.toString(),
            },
          ]}
        />
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      Content={(_) => (
        <Container>
          <Header>
            <h1>Email Preferences</h1>
            <p>Manage your preferences for receiving email notifications</p>
          </Header>
          <SettingsRow>
            <NotificationSettingsSwitch
              value={isNewAssignmentsEmailSettingsEnabled}
              onChange={(checked) => {
                updateNewAssignmentsSettings({ email: checked });
              }}
            />
            <SettingsRecap>
              <SettingsName>New assignment</SettingsName>
              <SettingsDescription>
                You are assigned as the owner of an incident or an action is assigned to you
              </SettingsDescription>
            </SettingsRecap>
          </SettingsRow>

          <SettingsRow>
            <NotificationSettingsSwitch
              value={isMentionsEmailSettingEnabled}
              onChange={(checked) => {
                updateMentionsSettings({ email: checked });
              }}
              aria-label="Mentions notification switch"
            />
            <SettingsRecap>
              <SettingsName>Mentions</SettingsName>
              <SettingsDescription>New mentions on specific action or incident records</SettingsDescription>
            </SettingsRecap>
          </SettingsRow>

          {showNewIncidentsSettings ? (
            <SettingsRow>
              <NotificationSettingsSwitch
                value={isNewIncidentsEmailSettingEnabled}
                onChange={(checked) => {
                  updateNewIncidentsSettings({ email: checked });
                }}
                aria-label="New incidents notification switch"
              />
              <SettingsRecap>
                <SettingsName>New incidents</SettingsName>
                <SettingsDescription>New incidents reported by the organisation</SettingsDescription>
              </SettingsRecap>
            </SettingsRow>
          ) : null}

          {showNewAuditsSettings ? (
            <SettingsRow>
              <NotificationSettingsSwitch
                value={isNewAuditsEmailSettingEnabled}
                onChange={(checked) => {
                  updateNewAuditsSettings({ email: checked });
                }}
                aria-label="New audits notification switch"
              />
              <SettingsRecap>
                <SettingsName>New audits</SettingsName>
                <SettingsDescription>New audits completed by the organisation</SettingsDescription>
              </SettingsRecap>
            </SettingsRow>
          ) : null}

          {showNewEventSettings ? (
            <SettingsRow>
              <NotificationSettingsSwitch
                value={isNewEventsEmailSettingEnabled}
                onChange={(checked) => {
                  updateNewEventsSettings({ email: checked });
                }}
                aria-label="New events notification switch"
              />
              <SettingsRecap>
                <SettingsName>New events</SettingsName>
                <SettingsDescription>New events reported by the organisation</SettingsDescription>
              </SettingsRecap>
            </SettingsRow>
          ) : null}
        </Container>
      )}
      navBarHeight={navBarHeight}
    />
  );
};

export default observer(ProfileNotificationsSettings);
