import React from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { ErrorOutline } from '@mui/icons-material';

import { CancelButton } from 'components/Button';

import API from 'utils/api';
import { fabricateFormErrorResponse, formErrors } from 'utils/api/errors';
import { useStore } from 'utils/hooks/store';
import { SecondaryTextField } from 'components/TextField';

import {
  FormFooter,
  FormRow,
  FormStatus,
  FormStatusContainer,
  InnerFormStatusContainer,
  RowLabel,
  StyledField,
  SubmitButton,
} from './styles';
import { ChangePasswordFormProps, ChangePasswordFormValues } from './types';

const FormValidationSchema = Yup.object()
  .shape({
    oldPassword: Yup.string()
      .required('Please provide old password')
      .max(128),
    newPassword1: Yup.string()
      .required('Please provide new password')
      .max(128),
    newPassword2: Yup.string()
      .required('Please confirm your password')
      .max(128),
  })
  .required()
  .strict()
  .noUnknown(true);

const ChangePasswordForm: React.FunctionComponent<ChangePasswordFormProps> = ({ onClose }) => {
  const { notification } = useStore();

  const initialFormValues: ChangePasswordFormValues = {
    oldPassword: undefined,
    newPassword1: undefined,
    newPassword2: undefined,
  };

  const handleFormSubmit = async (
    data: ChangePasswordFormValues,
    helpers: FormikHelpers<ChangePasswordFormValues>
  ): Promise<void> => {
    try {
      helpers.setStatus();
      if (data.oldPassword === data.newPassword1) {
        throw fabricateFormErrorResponse({
          nonFieldErrors: [
            "The new password can't be the same as your current password. Please choose a different password",
          ],
        });
      }

      if (data.newPassword1 !== data.newPassword2) {
        throw fabricateFormErrorResponse({
          nonFieldErrors: [
            // eslint-disable-next-line max-len
            "The new passwords don't match. Please ensure the 'New Password' and 'Confirm New Password' fields are identical.",
          ],
        });
      }
      helpers.setSubmitting(true);
      await API.changePassword(data)();
      helpers.resetForm();
      notification.enqueueSuccessSnackbar('Password changed successfully');
      onClose();
    } catch (e) {
      // @ts-ignore
      const { nonFieldErrors, fieldErrors } = formErrors(e);
      if (nonFieldErrors) {
        helpers.setStatus({ messages: nonFieldErrors });
      }
      if (fieldErrors) {
        helpers.setErrors(fieldErrors);
      }
    }
  };

  return (
    <Formik initialValues={initialFormValues} onSubmit={handleFormSubmit} validationSchema={FormValidationSchema}>
      {({ isSubmitting, status, errors, touched, submitForm }) => (
        <Form>
          <FormRow hasError={Object.keys(errors).includes('oldPassword') && Boolean(touched.oldPassword)}>
            <RowLabel>Current Password</RowLabel>
            <StyledField
              id="oldPassword"
              name="oldPassword"
              placeholder="Current Password"
              type="password"
              component={SecondaryTextField}
              required
              fullWidth
            />
          </FormRow>
          <FormRow hasError={Object.keys(errors).includes('newPassword1') && Boolean(touched.newPassword1)}>
            <RowLabel>New Password</RowLabel>
            <StyledField
              id="newPassword1"
              name="newPassword1"
              placeholder="New Password"
              type="password"
              component={SecondaryTextField}
              required
              fullWidth
            />
          </FormRow>
          <FormRow hasError={Object.keys(errors).includes('newPassword2') && Boolean(touched.newPassword2)}>
            <RowLabel>Confirm New Password</RowLabel>
            <StyledField
              id="newPassword2"
              name="newPassword2"
              placeholder="Confirm New Password"
              type="password"
              component={SecondaryTextField}
              required
              fullWidth
            />
          </FormRow>
          {status && status.messages ? (
            <FormStatusContainer>
              <ErrorOutline htmlColor="rgba(211, 47, 47, 1)" sx={{ alignSelf: 'flex-start', marginTop: '8px' }} />
              <InnerFormStatusContainer>
                {status.messages.map((msg: string) => (
                  <FormStatus>{msg}</FormStatus>
                ))}
              </InnerFormStatusContainer>
            </FormStatusContainer>
          ) : null}
          <FormFooter>
            <SubmitButton
              disabled={isSubmitting || Object.keys(errors).length > 0}
              type="submit"
              size="large"
              color="primary"
              variant="contained"
              onClick={() => {
                submitForm();
              }}
            >
              Update Password
            </SubmitButton>
            <CancelButton
              onClick={() => {
                onClose();
              }}
              size="large"
              color="primary"
              variant="outlined"
            >
              Cancel
            </CancelButton>
          </FormFooter>
        </Form>
      )}
    </Formik>
  );
};

export default ChangePasswordForm;
