import React from 'react';

export interface ErrorProps {
  message: string;
  icon: React.ComponentType;
  children?: React.ReactNode;
}

export enum VatixError {
  SystemError,
  Unauthorized,
  NotFound,
}
