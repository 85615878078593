/* eslint-disable no-nested-ternary */
import React from 'react';

import AttachFileIcon from '@mui/icons-material/AttachFile';

import { Typography, Button } from '@mui/material';

import ReplayIcon from '@mui/icons-material/Replay';
import { ActivityResponse, FileActivityResponse, FileUploadProgressResponse } from 'vatix-ui/lib/utils/api/types';

import { humanFileSize } from 'utils/formatters/file';

import { FileUploadContainer, FileName, FileSize, StyledLinearProgress } from './styles';

function isFile(file: ActivityResponse): file is FileActivityResponse {
  return (file as FileActivityResponse).filename !== undefined;
}

const FileUpload: React.FC<{
  activity: ActivityResponse;
  failed?: boolean;
  retryUpload: (fileUuid: string) => Promise<void>;
}> = ({ activity, failed, retryUpload }) => {
  let size: number;
  let name: string;
  let loaded = 0;
  if (isFile(activity)) {
    size = activity.size;
    name = activity.filename;
  } else {
    const fileUploadProgress = activity as FileUploadProgressResponse;
    size = fileUploadProgress.size;
    name = fileUploadProgress.name;
    loaded = fileUploadProgress.loaded;
  }

  const progressValue = (loaded / size) * 100;

  return (
    <FileUploadContainer>
      <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          <AttachFileIcon sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
          <div>
            <FileName>{name}</FileName>
            {failed ? (
              <Typography variant="body2" color="red">
                Failed to upload
              </Typography>
            ) : (
              <FileSize>{humanFileSize(size)}</FileSize>
            )}
          </div>
        </div>
        {failed ? (
          <Button onClick={() => retryUpload(activity.uuid)} style={{ padding: '0', minWidth: '0' }}>
            <ReplayIcon />
          </Button>
        ) : null}
      </div>
      {failed ? null : (
        <div style={{ width: '100%', paddingTop: '9px' }}>
          <StyledLinearProgress variant="determinate" value={progressValue} $completed={progressValue >= 100} />
          <Typography variant="body2" color="text.secondary">
            {`${Math.round(progressValue)}%`}
          </Typography>
        </div>
      )}
    </FileUploadContainer>
  );
};

export default FileUpload;
