// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const Header = styled.h1`
  color: rgba(0, 0, 0, 0.87);
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  padding: 0;
`;

export const LockedText = styled.p`
  color: rgba(0, 0, 0, 0.38);
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  padding: 0;
  margin-left: 4px;
`;

export const MainContainer = styled.div`
  display: flex;
  padding: 70px 150px;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  background: #fff;
  height: calc(100% - 54px);
`;

export const KeyFeatures = styled.h3`
  color: #101d31;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 55px;
  text-transform: capitalize;
  margin: 0;
`;

export const Info = styled.p`
  color: #101d31;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  margin: 0;
`;
