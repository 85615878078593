import React from 'react';

import { useHistory, useRouteMatch } from 'react-router-dom';

import DetailsLoader from 'vatix-ui/lib/components/DetailsLoader';

import Record from 'vatix-ui/lib/containers/Record/Record';

import { Button } from 'components/Button';
import { CenteredCircularProgress } from 'components/Progress';

import NotFound from 'containers/Error/Pages/NotFound/NotFound';

import DataError from 'containers/Error/DataError';

import { isNotFound } from 'utils/api/errors';

import { useStore } from 'utils/hooks/store';

import { useNavBarHeight } from 'utils/hooks/navbar';

import CustomModal from 'components/CustomModal';

import UserBackgroundInformation from './components/UserBackgroundInformation';
import UserProfileHeader from './components/UserProfileHeader';

import { ModalText } from './styles';

const UserDetails: React.FunctionComponent = (): React.ReactElement => {
  const navBarHeight = useNavBarHeight();
  const { userDetails } = useStore();
  const history = useHistory();
  const match = useRouteMatch();

  const [deletionModalOpen, setDeletionModalOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [deleting, setDeleting] = React.useState(false);

  const handleClose = (): void => {
    setAnchorEl(null);
    setDeletionModalOpen(false);
    setDeleting(false);
  };

  const onDeactivateUser = async (): Promise<void> => {
    setDeleting(true);
    handleClose();
    const response = await userDetails.deleteUser(userDetails.details?.uuid as string);
    if (response) {
      history.goBack();
    }
  };

  return (
    <DetailsLoader
      match={match}
      // @ts-ignore
      routeParam="userId"
      store={userDetails}
    >
      {({ isLoaded, error, reload }): React.ReactElement => {
        if (!isLoaded) {
          return <CenteredCircularProgress />;
        }

        if (error) {
          if (isNotFound(error)) {
            return <NotFound />;
          }
          return <DataError onReload={reload} />;
        }

        return (
          <Record
            navBarHeight={navBarHeight}
            disableMaxContentHeight
            Content={() => (
              <>
                <UserBackgroundInformation />
                <CustomModal
                  openModal={deletionModalOpen}
                  onClose={handleClose}
                  modalTitle="Deactivate User"
                  modalWidth="sm"
                  cancelButton={
                    <Button
                      disabled={deleting}
                      onClick={handleClose}
                      autoFocus
                      id="cancel-button"
                      variant="outlined"
                      size="large"
                    >
                      Cancel
                    </Button>
                  }
                  submitButton={
                    <Button
                      onClick={onDeactivateUser}
                      disabled={deleting}
                      id="confirm-button"
                      variant="contained"
                      size="large"
                      color="error"
                    >
                      Deactivate User
                    </Button>
                  }
                >
                  <ModalText>
                    {userDetails.details?.devices?.length && userDetails.details?.devices?.length > 0
                      ? 'Are you sure you want to deactivate this user? ' +
                        'This user currently has a lone worker device or mobile app enabled, ' +
                        'which will be unassigned upon deactivation.'
                      : 'Are you sure you want to deactivate this user?'}
                  </ModalText>
                </CustomModal>
              </>
            )}
            Header={
              <UserProfileHeader
                setDeletionModalOpen={setDeletionModalOpen}
                setAnchorEl={setAnchorEl}
                anchorEl={anchorEl}
              />
            }
          />
        );
      }}
    </DetailsLoader>
  );
};

export default UserDetails;
