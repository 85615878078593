import { AxiosError } from 'axios';

import { action, observable, runInAction, makeObservable } from 'mobx';
import { eventBus, subscribe } from 'mobx-event-bus2';

import DataMixin from 'utils/stores/DataMixin';

import Logger from 'utils/logger';

import { TaskStatus } from 'core/constants';

import RootStore from 'stores/Root';
import API from 'utils/api';
import { isNotFound } from 'utils/api/errors';

import { TaskListResponse } from 'utils/api/types';
import { postMessage } from 'utils/events/broadcast';
import { EventType } from 'utils/events/constants';
import { ActionEvent } from 'utils/events/types';

export default class AnswerTasks extends DataMixin {
  @observable error?: AxiosError;

  @observable tasks?: TaskListResponse[];

  inspectionId: string;

  answerId: string;

  editingTask = false;

  constructor(rootStore: RootStore, api: typeof API, inspectionId: string, answerId: string) {
    super(rootStore, api);
    makeObservable(this);
    this.inspectionId = inspectionId;
    this.answerId = answerId;

    eventBus.register(this);
  }

  setEditingTask(val: boolean): void {
    this.editingTask = val;
  }

  @action.bound
  async loadTasks(): Promise<void> {
    this.isLoaded = false;
    try {
      const { data } = await this.api.loadTasksAnswer(this.inspectionId, this.answerId)();
      runInAction(() => {
        this.error = undefined;
        this.tasks = data.results;
      });
    } catch (e) {
      const err = e as AxiosError;
      runInAction(() => {
        this.error = err;
      });
      if (!isNotFound(err)) {
        Logger.error(
          `Invalid load tasks API response. Inspection id: ${this.inspectionId}. Answer id: ${this.answerId}`,
          e
        );
      }
    } finally {
      runInAction(() => {
        this.isLoaded = true;
      });
    }
  }

  @action
  createGhostTask(): void {
    this.setEditingTask(true);
    this.tasks = [
      ...(this.tasks || []),
      {
        uuid: '',
        name: '',
        assigned: null,
        status: TaskStatus.ToDo,
        dueDate: null,
        description: '',
        customIncident: {
          uuid: '',
          name: '',
          subject: '',
          isActive: false,
          created: '',
          creator: { uuid: '', name: '' },
          owner: { uuid: '', name: '' },
          definition: {
            mandatory: [],
            order: [],
            properties: {},
          },
        },
        links: [],
        lastUpdate: '',
        location: null,
      },
    ];
  }

  @action
  removeGhostTask(): void {
    if (this.tasks) {
      this.tasks = this.tasks.filter((task) => task.uuid !== '');
    }
  }

  @action
  async createTaskAnswerTask(name: string): Promise<void> {
    try {
      const { data } = await this.api.createTask({
        name,
        // @ts-ignore
        links: [
          {
            entity: 'inspection',
            instance: this.inspectionId,
            extraData: {
              questionId: this.answerId,
            },
          },
        ],
      })();
      this.store.notification.enqueueSuccessSnackbar('Task created successfully');
      await this.loadTasks();
      postMessage(EventType.CreatedTaskAnswerTask, data);
    } catch (ex) {
      Logger.error('Invalid create task API response.', ex);
      this.store.notification.enqueueErrorSnackbar('Task could not be created');
    }
  }

  @action
  async updateTask(
    uuid: string,
    data: Partial<{
      assigned: string | null;
      name: string;
      status: TaskStatus;
    }>
  ): Promise<void> {
    try {
      // @ts-ignore
      const { data: result } = await this.api.updateTask(uuid, data)();
      this.store.notification.enqueueSuccessSnackbar('Task updated successfully');
      postMessage(EventType.UpdatedTask, result);
    } catch (ex) {
      Logger.error('Invalid update task API response.', ex);
      this.store.notification.enqueueErrorSnackbar('Task could not be updated');
    }
  }

  @subscribe(EventType.UpdatedTask)
  updatedTask({ payload }: ActionEvent<TaskListResponse>): void {
    const index = this.tasks?.findIndex((task) => task.uuid === payload.uuid);
    if (this.tasks !== undefined && index !== undefined && index !== -1) {
      this.tasks[index] = {
        ...this.tasks[index],
        ...payload,
      };
    }
  }

  @subscribe(EventType.DeletedTask)
  deletedTask(): void {
    this.reset();
  }
}
