import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

import { CenteredContainer } from './styles';
import { CenteredCircularProgressProps } from './types';

const CenteredCircularProgress: React.FunctionComponent<CenteredCircularProgressProps> = ({
  CircularProgressProps,
  ...props
}): React.ReactElement => (
  <CenteredContainer {...props}>
    <CircularProgress {...CircularProgressProps} />
  </CenteredContainer>
);

export default CenteredCircularProgress;
