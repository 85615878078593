import { action, observable, makeObservable, reaction } from 'mobx';
import { AxiosError } from 'axios';

import { UniqueIdentifier } from '@dnd-kit/core';

import { v4 as uuid } from 'uuid';

import { isEqual } from 'lodash';

import DataMixin from 'utils/stores/DataMixin';

import API from 'utils/api';
import RootStore from 'stores/Root';

import { FormActionsType, FormBuilderType } from 'utils/api/types';

import FormBuilderSettings from 'stores/FormBuilderSettings/FormBuilderSettings';

import FormBuilderDragAndDrop from './helpers/FormBuilderDragAndDrop';
import FormBuilderDataManager from './helpers/FormBuilderDataManager';
import FormBuilderValidation from './helpers/FormBuilderValidation';
import QuestionLogicStore from './helpers/QuestionLogicStore';
import SectionLogicStore from './helpers/SectionLogicStore';
import FormMapping from './helpers/FormMapping';
import FormAction from './helpers/FormAction';

type Items = Record<UniqueIdentifier, UniqueIdentifier[]>;

export const NEW_FORM_ID = 'new';

export default class FormBuilder extends DataMixin {
  @observable error?: AxiosError;

  @observable data?: FormBuilderType;

  // data loaded at first, needed to compare with the current data
  @observable loadedData?: FormBuilderType;

  @observable loadedActions?: {
    [sectionKey: string]: FormActionsType;
  };

  @observable lastPublished?: string;

  @observable containers: string[] = [];

  @observable items: Items = {};

  @observable contentOfLayoutWasEdited = false;

  @observable formName = '';

  @observable initialName = '';

  @observable formId = NEW_FORM_ID;

  @observable currentEditedQuestion: { sectionId: string; fieldId: string } | undefined = undefined;

  // variable needed to block main click away event when editing dropdown options
  @observable isDropdownOptionInEdit: string | undefined = undefined;

  // helper stores
  dragAndDropManager: FormBuilderDragAndDrop;

  validation: FormBuilderValidation;

  dataManager: FormBuilderDataManager;

  sectionLogic: SectionLogicStore;

  questionLogic: QuestionLogicStore;

  formBuilderSettings: FormBuilderSettings;

  formAction: FormAction;

  formMapping: FormMapping;

  // variable to display UI for conditional logic
  @observable dropdownConditionalLogic: { sectionId: string; fieldId: string } | undefined = undefined;

  @observable contentWasEdited = false;

  constructor(rootStore: RootStore, api: typeof API) {
    super(rootStore, api);

    // Initialize helper stores
    this.dragAndDropManager = new FormBuilderDragAndDrop(this);

    this.validation = new FormBuilderValidation(rootStore, this);

    this.dataManager = new FormBuilderDataManager(rootStore, api, this);

    this.sectionLogic = new SectionLogicStore(this);

    this.questionLogic = new QuestionLogicStore(this);

    this.formBuilderSettings = new FormBuilderSettings(rootStore, api);

    this.formAction = new FormAction(this);

    this.formMapping = new FormMapping(rootStore, api, this);

    makeObservable(this);

    // Reaction to observe changes
    reaction(
      () => [
        this.data,
        this.items,
        this.formId,
        this.formName,
        this.containers,
        this.initialName,
        this.loadedActions,
        this.formMapping.mappingModified,
        this.formBuilderSettings.settingsWasEdited,
      ],
      () => {
        this.isModified();
      }
    );
  }

  @action.bound
  isModified(): void {
    if (this.loadedData === undefined) return;

    if (this.formMapping.mappingModified) {
      this.contentWasEdited = true;
      return;
    }
    const nameModified = this.initialName !== this.formName;
    if (this.formId === NEW_FORM_ID && nameModified) {
      this.contentWasEdited = true;
      return;
    }

    if (this.loadedActions === undefined) return;
    const actionsModified = !isEqual(this.loadedActions, this.formAction.formActions);
    const contentModified = !isEqual(this.data, this.loadedData);

    this.contentWasEdited =
      contentModified || nameModified || this.formBuilderSettings.settingsWasEdited || actionsModified;
  }

  @action
  toggleConditionalLogicVisibility(sectionId: string, fieldId: string): void {
    const actionExists = this.formAction.getActionsForQuestion(sectionId, fieldId).length > 0;
    if (actionExists) {
      this.formAction.removeQuestionActions(sectionId, fieldId);
    } else {
      this.formAction.createDefaultActionForQuestion(sectionId, fieldId);
    }
  }

  getNextContainerId = (): string => uuid().slice(0, 8);

  @action.bound
  setDropdownOptionInEdit(value: string | undefined): void {
    this.isDropdownOptionInEdit = value;
  }

  @action.bound
  setCurrentEditedQuestion(data: { sectionId: string; fieldId: string } | undefined): void {
    this.currentEditedQuestion = data;
  }

  @action.bound
  updateFormName(name: string): void {
    this.formName = name;
  }

  getAllSectionTitlesAndKeys = (currentSectionId: string): { key: string; title: string }[] => {
    if (!this.data) return [];

    // return all sections AFTER the current section
    const currentSectionIndex = this.containers.indexOf(currentSectionId);
    const sectionsAfterCurrentSection = this.containers.slice(currentSectionIndex + 1);

    return sectionsAfterCurrentSection.map((container) => ({
      key: container,
      title: this.data!.properties[container].title,
    }));
  };

  getSectionOrderId = (sectionId: string): number => this.containers.indexOf(sectionId) + 1;

  @action.bound
  resetForm(): void {
    // Reset form metadata
    this.formId = NEW_FORM_ID;
    this.formName = '';
    this.initialName = '';

    // Reset data states
    this.data = undefined;
    this.loadedData = undefined;
    this.loadedActions = undefined;
    this.lastPublished = undefined;

    // Reset layout states
    this.containers = [];
    this.items = {};
    this.contentOfLayoutWasEdited = false;
    this.contentWasEdited = false;

    // Reset editing states
    this.currentEditedQuestion = undefined;

    // Reset related stores
    this.formBuilderSettings.resetSettings();
    this.formMapping.resetMapping();
  }
}
