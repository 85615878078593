import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function Plus(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path d="M18,13H13v5a1,1,0,0,1-2,0V13H6a1,1,0,0,1,0-2h5V6a1,1,0,0,1,2,0v5h5a1,1,0,0,1,0,2Z" />
    </SvgIcon>
  );
}

export default Plus;
