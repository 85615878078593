import React from 'react';

import { StyledError } from './styles';

const BlockError: React.FunctionComponent<{
  children: React.ReactNode;
}> = ({ children, ...props }): React.ReactElement => (
  <>
    {React.Children.map(children, (child) => (
      <StyledError {...props}>{child}</StyledError>
    ))}
  </>
);

export default BlockError;
