import React from 'react';

import { Switch } from '@mui/material';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { observer } from 'mobx-react';

import { TextInput } from 'vatix-ui/lib/components/TextInput';

import { Button, CancelButton } from 'components/Button';

import {
  StyledControlLabel,
  OptionHeader,
  EditContainer,
  EditChipContainer,
  AddOptionButton,
  StyledTextInput,
  StyledIconButton,
  Row,
  OptionLeft,
  ErrorMessage,
} from '../../styles';

import { EditMultipleAnswersProps } from './types';
import ScoreField from '../ScoreField';

const EditMultipleAnswers: React.FunctionComponent<EditMultipleAnswersProps> = ({
  options,
  setOptions,
  allowMulti,
  setAllowMulti,
  deleteOptionFromOptions,
  addOptions,
  scoring,
  setScoring,
}): React.ReactElement => {
  const textInput = React.useRef(null);
  const [value, setValue] = React.useState('');
  const [scoreValue, setScoreValue] = React.useState('');
  const [answerOptions, setAnswerOptions] = React.useState(options);

  const handleAllowMulti = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    setAllowMulti(!allowMulti);
  };

  const handleAllowScoring = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setScoring(scoring ? null : []);
    event.stopPropagation();
  };

  const getValidatedScoreValue = (): string => {
    try {
      const numberScoreValue = Number(scoreValue);
      if (numberScoreValue < 0) {
        throw new Error('Negatives not allowed');
      }
      return String(numberScoreValue);
    } catch (ex) {
      return '0';
    }
  };

  const addOption = (): void => {
    setAnswerOptions((option) => [...option, value]);
    setOptions([...answerOptions, value]);
    if (scoring) {
      setScoring([...(scoring || []), getValidatedScoreValue()]);
    } else {
      setScoring(null);
    }
    setValue('');
    setTimeout(() => {
      setScoreValue('');
    });
  };

  const deleteOption = (index: number): void => {
    const answers = [...answerOptions.slice(0, index), ...answerOptions.slice(index + 1, answerOptions.length)];
    if (scoring) {
      const temp = scoring || [];
      temp.splice(index, 1);
      setScoring([...temp]);
    }
    setAnswerOptions(answers);
    setOptions(answers);
  };

  const isValid = (): boolean => answerOptions.includes(value);

  const handleSubmit = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    e.stopPropagation();
    if (!isValid() && e.key === 'Enter' && value !== '') {
      addOption();
      if (scoring && scoreValue !== '') {
        // @ts-ignore
        textInput.current && textInput.current.focus();
      }
    }
  };

  const allowSubmit = value !== '' && (!scoring ? true : scoreValue !== '');

  return (
    <EditContainer>
      <Row>
        <OptionHeader>Option</OptionHeader>
        {scoring ? <OptionHeader style={{ width: '35%' }}>Score</OptionHeader> : null}
      </Row>
      {answerOptions.map((option, index) => (
        <EditChipContainer key={option}>
          <OptionLeft>{option}</OptionLeft>
          {scoring ? <ScoreField index={index} scoring={scoring} setScoring={setScoring} /> : null}
          <StyledIconButton
            onClick={() => {
              deleteOption(index);
            }}
          >
            <DeleteOutlineIcon />
          </StyledIconButton>
        </EditChipContainer>
      ))}

      <Row>
        <TextInput
          placeholder="Type response here..."
          value={value}
          onChange={(event) => {
            event.stopPropagation();
            setValue(event.target.value);
          }}
          onKeyDown={handleSubmit}
          maxLength={25}
          ref={textInput}
        />
        {scoring ? (
          <StyledTextInput
            placeholder="Type score here..."
            value={scoreValue}
            onBlur={() => setScoreValue(getValidatedScoreValue())}
            onChange={(event) => {
              setScoreValue(event.target.value);
              event.stopPropagation();
            }}
            onKeyDown={(event) => {
              if (event.key === 'e' || event.key === '-' || event.key === '+') {
                event.preventDefault();
              } else {
                handleSubmit(event);
              }
            }}
            min="0"
            maxLength={25}
            type="number"
          />
        ) : null}
      </Row>

      {isValid() ? <ErrorMessage>This value already exist in options</ErrorMessage> : null}

      <AddOptionButton disabled={!allowSubmit || isValid()} onClick={addOption}>
        + Add Option
      </AddOptionButton>
      <Row>
        <StyledControlLabel
          control={<Switch checked={allowMulti} color="primary" onClick={handleAllowMulti} />}
          label="Allow multiple answers"
          id="multipe-toggle"
        />
        <StyledControlLabel
          control={<Switch checked={Boolean(scoring)} color="primary" onClick={handleAllowScoring} />}
          label="Enable Scoring"
          id="scoring-toggle"
        />
      </Row>
      <Row>
        <CancelButton
          onClick={() => {
            deleteOptionFromOptions(answerOptions);
          }}
        >
          Delete
        </CancelButton>
        <Button
          onClick={() => {
            addOptions(answerOptions);
          }}
          disabled={answerOptions.length === 0 || isValid()}
        >
          Save Options
        </Button>
      </Row>
    </EditContainer>
  );
};

export default observer(EditMultipleAnswers);
