import React from 'react';

import { Link } from 'react-router-dom';

import Breadcrumbs from '@mui/material/Breadcrumbs';

import Tooltip from '@mui/material/Tooltip';

import { MainHeaderProps } from './types';
import { HeaderWrapper, HeaderTitle, HeaderTitleWrapper, ItemLabel, BreadcrumbLink } from './styles';

export const MainHeader: React.FunctionComponent<MainHeaderProps> = ({
  title,
  breadcrumbs,
  action,
}): React.ReactElement => (
  <HeaderWrapper>
    <HeaderTitleWrapper>
      <Breadcrumbs separator=">" aria-label="breadcrumb">
        {breadcrumbs.map((item, index) => [
          index === breadcrumbs.length - 1 ? (
            <ItemLabel>{item.label}</ItemLabel>
          ) : (
            <BreadcrumbLink to={item.link} as={Link}>
              {item.label}
            </BreadcrumbLink>
          ),
        ])}
      </Breadcrumbs>
      <Tooltip title={title.length > 60 ? title : ''}>
        <HeaderTitle id={`record-title-${title}`}>{title}</HeaderTitle>
      </Tooltip>
    </HeaderTitleWrapper>
    {action}
  </HeaderWrapper>
);

export default MainHeader;
