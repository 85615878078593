import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';

import { Divider } from '@mui/material';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

import { Button } from 'vatix-ui/lib/components/Button';

import DataError from 'containers/Error/DataError';

import EventDetailsStore from 'stores/EventDetails';

import Row from 'containers/IncidentDetails/components/IncidentInformation/components/Row';

import { AnswerTypes, IncidentResponse, ProtectorType } from 'utils/api/types';

import CustomFieldDisplayValue from 'containers/IncidentDetails/components/CustomFieldDisplayValue';
import { EntityModules } from 'core/constants';

import { checkIfAllAnswersInSectionAreEmpty, getContentForKey } from 'stores/EventDetails/helpers';

import OriginalIncident from 'containers/IncidentDetails/components/OriginalIncident/OriginalIncident';

import { EntitiesStores } from '../types';
import OriginalSubmissionHeader from './OriginalSubmissionHeader';

import { SectionContainer, SectionHeader, RowContainer, ButtonContainer } from './styles';
import ReporterComments from './ReporterComments';
import ReporterDetailsSection from './ReporterDetailsSection';

const ToggleEmptyAnswersButton: React.FC<{
  hideEmptyAnswers?: boolean;
  setHideEmptyAnswers: (hide: boolean) => void;
}> = ({ hideEmptyAnswers, setHideEmptyAnswers }) => (
  <Button variant="outlined" onClick={() => setHideEmptyAnswers(!hideEmptyAnswers)} sx={{ gap: '8px' }}>
    {hideEmptyAnswers ? (
      <>
        <VisibilityOffOutlinedIcon />
        Show all answers
      </>
    ) : (
      <>
        <VisibilityOutlinedIcon />
        Hide empty answers
      </>
    )}
  </Button>
);

const EntitySubmissionTab: React.FunctionComponent<{
  store: EntitiesStores;
  hideEmptyAnswers?: boolean;
  setHideEmptyAnswers?: (hideEmptyAnswers: boolean) => void;
}> = ({ store, hideEmptyAnswers, setHideEmptyAnswers }): React.ReactElement => {
  const { details, prefix } = store;
  const detailsType = details as EventDetailsStore['details'];

  if (!details || !detailsType) {
    return <DataError />;
  }

  const eventId = useMemo(() => detailsType.entity[`${prefix}eventID`], [detailsType.entity, prefix]);
  const { stack } = detailsType.submission;

  const renderSection = useCallback(
    (sectionKey: string) => {
      const { title, order, properties } = detailsType.submission.form.form.properties[sectionKey];

      const allAnswersInSectionAreEmpty = checkIfAllAnswersInSectionAreEmpty(
        detailsType.submission.form.form,
        sectionKey,
        detailsType.submission.content
      );

      if (hideEmptyAnswers && allAnswersInSectionAreEmpty) {
        return null;
      }

      return (
        <React.Fragment key={sectionKey}>
          <SectionHeader>
            <p>{title}</p>
          </SectionHeader>
          {order.map((property, index) => {
            const {
              description,
              questionType,
              properties: { lookupType },
            } = properties[property];

            const value = getContentForKey({
              form: detailsType.submission.form.form,
              content: detailsType.submission.content,
              key: property,
              sectionKey,
            });

            if (hideEmptyAnswers && value === undefined) {
              return null;
            }

            return (
              <>
                <RowContainer key={property}>
                  <Row name={description || '-'}>
                    <CustomFieldDisplayValue
                      schema={undefined}
                      type={questionType as ProtectorType}
                      value={value as AnswerTypes}
                      lookupType={(lookupType as unknown) as EntityModules}
                    />
                  </Row>
                </RowContainer>
                {index !== order.length - 1 && <Divider />}
              </>
            );
          })}
        </React.Fragment>
      );
    },
    [detailsType, hideEmptyAnswers]
  );

  // if there is a legacy submission, use it
  if (detailsType.legacySubmission) {
    return (
      <>
        <ButtonContainer>
          {setHideEmptyAnswers && (
            <ToggleEmptyAnswersButton hideEmptyAnswers={hideEmptyAnswers} setHideEmptyAnswers={setHideEmptyAnswers} />
          )}
        </ButtonContainer>
        <OriginalIncident
          customHeader={
            <OriginalSubmissionHeader
              id={eventId.value as number}
              created={detailsType.createdAt}
              createdBy={detailsType.createdBy}
            />
          }
          hideEmptyAnswers={hideEmptyAnswers}
          legacyActivityLog={false}
          details={
            {
              originalIncident: detailsType.legacySubmission,
            } as IncidentResponse
          }
        />

        <ReporterComments />
      </>
    );
  }

  return (
    <>
      <SectionContainer>
        {setHideEmptyAnswers && (
          <ToggleEmptyAnswersButton hideEmptyAnswers={hideEmptyAnswers} setHideEmptyAnswers={setHideEmptyAnswers} />
        )}

        <OriginalSubmissionHeader
          id={eventId.value as number}
          created={detailsType.createdAt}
          createdBy={detailsType.createdBy}
        />
        {/* if it is a public submission and reporter details are not empty display section with reporter data */}
        {detailsType.submission.publicSubmissionDetails &&
          Object.keys(detailsType.submission.publicSubmissionDetails).length > 0 && (
            <ReporterDetailsSection publicSubmissionDetails={detailsType.submission.publicSubmissionDetails} />
          )}

        {stack.map((sectionKey) => renderSection(sectionKey))}
      </SectionContainer>
      <ReporterComments />
    </>
  );
};

export default observer(EntitySubmissionTab);
