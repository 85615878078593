import React from 'react';

import { Button } from 'components/Button';

import CustomModal from 'components/CustomModal';

import { InfoText } from './styles';

const RemoveARPModal: React.FC<{
  onClose: () => void;
  handleRemoveARP: () => void;
}> = ({ onClose, handleRemoveARP }): React.ReactElement => {
  const [openModal, setOpenModal] = React.useState(true);

  const onCloseModal = (): void => {
    setOpenModal(false);
    onClose();
  };

  const onDelete = (): void => {
    handleRemoveARP();
    onCloseModal();
  };

  return (
    <>
      <CustomModal
        openModal={openModal}
        onClose={onCloseModal}
        modalTitle="Delete Alarm Response Profile"
        cancelButton={
          <Button id="cancel-action-button" onClick={onCloseModal} variant="outlined" size="large">
            Cancel
          </Button>
        }
        submitButton={
          <Button id="delete-action-button" onClick={onDelete} variant="contained" size="large" color="error">
            Delete
          </Button>
        }
      >
        <InfoText>Are you sure you want to delete this alarm response profile?</InfoText>
        <InfoText>Once removed, you will not be able to recover this data.</InfoText>
      </CustomModal>
    </>
  );
};

export default RemoveARPModal;
