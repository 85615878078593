import styled from 'styled-components';
import Typography from '@mui/material/Typography';

import { center } from '../../core/styles';

import vatixTheme from '../../theme/vatix';

export const ErrorWrapper = styled.div`
  ${center};
  flex-direction: column;
  height: 100%;
`;

export const ErrorMessage = styled(Typography)`
  margin-top: 27px;
  margin-bottom: 31px;
  text-align: center;
`;

export const ErrorLink = styled.a`
  color: ${vatixTheme.palette.primary.main};
  font-size: 1.7rem;
  line-height: 2rem;
  cursor: pointer;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
`;
