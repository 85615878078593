import React from 'react';

import Record from 'vatix-ui/lib/containers/Record/Record';

import { MainHeader } from 'components/MainHeader/MainHeader';

import routes from 'core/routes';

import { useNavBarHeight } from 'utils/hooks/navbar';

import UserList from './components/UserList';
import AddUsersModal from './components/AddUsersModal';
import AddMultipleUsersModal from './components/AddMultipleUsersModal';

const Users: React.FunctionComponent = (): React.ReactElement => {
  const navBarHeight = useNavBarHeight();
  const refreshRef = React.useRef<Function | null>(null);
  const onRefreshCallback = (callback: Function): void => {
    refreshRef.current = callback;
  };

  const onCloseRefresh = (): void => {
    if (refreshRef.current) {
      refreshRef.current();
    }
  };

  return (
    <Record
      Header={
        <MainHeader
          title="Users"
          breadcrumbs={[
            { label: 'Admin', link: routes.dashboard.admin.users.toString() },
            { label: 'Users', link: routes.dashboard.admin.users.toString() },
          ]}
          action={
            <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
              <AddMultipleUsersModal onCloseRefresh={onCloseRefresh} />
              <AddUsersModal onCloseRefresh={onCloseRefresh} />
            </div>
          }
        />
      }
      Content={(onError) => <UserList onError={onError} onRefreshCallback={onRefreshCallback} />}
      navBarHeight={navBarHeight}
    />
  );
};

export default Users;
