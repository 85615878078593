import React from 'react';

import { useRouteMatch } from 'react-router-dom';

import DetailsLoader from 'vatix-ui/lib/components/DetailsLoader';

import { CenteredCircularProgress } from 'components/Progress';

import NotFound from 'containers/Error/Pages/NotFound/NotFound';

import DataError from 'containers/Error/DataError';

import { isNotFound } from 'utils/api/errors';

import { useStore } from 'utils/hooks/store';

import IncidentDetails from './components/IncidentDetails';

const IncidentDetailsLoader = (): React.ReactElement => {
  const { incidentDetails } = useStore();
  const match = useRouteMatch();

  return (
    <DetailsLoader
      match={match}
      // @ts-ignore
      routeParam="incidentId"
      store={incidentDetails}
    >
      {({ isLoaded, error, reload }): React.ReactElement => {
        if (!isLoaded) {
          return <CenteredCircularProgress />;
        }

        if (error) {
          if (isNotFound(error)) {
            return <NotFound />;
          }
          return <DataError onReload={reload} />;
        }

        return <IncidentDetails />;
      }}
    </DetailsLoader>
  );
};

export default IncidentDetailsLoader;
