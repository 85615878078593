import { VatixError } from 'components/Error/types';

import NotFound from './NotFound';
import SystemError from './SystemError';
import Unauthorized from './Unauthorized';

export { default as NotFound } from './NotFound';
export { default as Unauthorized } from './Unauthorized';
export { default as SystemError } from './SystemError';

const pagesErrorMapping: { [key in VatixError]: React.FunctionComponent } = {
  [VatixError.NotFound]: NotFound,
  [VatixError.Unauthorized]: Unauthorized,
  [VatixError.SystemError]: SystemError,
};

export default pagesErrorMapping;
