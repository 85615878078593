import React from 'react';

import { CenteredCircularProgress } from 'components/Progress';

import { AnswerType, AnswerTypes, CustomIncidentTypeResponse, ProtectorType } from 'utils/api/types';

import IncidentForm from 'containers/Incidents/components/IncidentForm';

import { Container, IncidentOverviewNoSection, IncidentOverviewNoSectionTitle } from '../../styles';

import ReportIncidentSection from '../ReportIncidentSection';

const ReportingIncidentCommon: React.FC<{
  customIncidentType: CustomIncidentTypeResponse | undefined;
  incidentContent: AnswerType | undefined;
  updateIncidentField: (section: string | undefined, field: string, value: AnswerTypes) => void;
}> = ({ customIncidentType, incidentContent, updateIncidentField }): React.ReactElement =>
  !customIncidentType || !customIncidentType.schema ? (
    <CenteredCircularProgress key="report_incident_circular_progress" />
  ) : (
    <Container>
      {customIncidentType.schema.order
        .filter((i) => (customIncidentType.schema.properties || {})[i].protectorType !== ProtectorType.Status)
        .map((i, index, { length }) => {
          const section = (customIncidentType.schema.properties || {})[i];
          const { protectorType } = (customIncidentType?.schema.properties || {})[i];
          const onlyOneSection = customIncidentType.schema.order.filter((s) => s !== 'status').length === 1;

          if (protectorType === ProtectorType.Section && section.order.length > 0)
            return (
              <ReportIncidentSection
                key={`${i}_section_${index}`}
                onlyOneSection={onlyOneSection}
                section={section}
                updateField={(field, value) => updateIncidentField(i, field, value)}
                incidentContent={incidentContent ? (incidentContent[i] as AnswerType) : undefined}
              />
            );
          if (index === 0) {
            return (
              <IncidentOverviewNoSection>
                <IncidentOverviewNoSectionTitle>General Details</IncidentOverviewNoSectionTitle>
                <IncidentForm
                  editable
                  properties={(customIncidentType.schema.properties || {})[i]}
                  required={customIncidentType.schema.required ? customIncidentType.schema.required.includes(i) : false}
                  title={i}
                  updateField={(field, value) => updateIncidentField(undefined, field, value)}
                  incidentContent={(incidentContent || {})[i]}
                />
              </IncidentOverviewNoSection>
            );
          }

          if (index + 1 === length && protectorType !== ProtectorType.Section) {
            return (
              <IncidentOverviewNoSection
                style={{ borderRadius: '0 0 8px 8px', paddingBottom: '20px' }}
                key={`${i}_section_${index}`}
              >
                <IncidentForm
                  editable
                  properties={(customIncidentType.schema.properties || {})[i]}
                  required={customIncidentType.schema.required ? customIncidentType.schema.required.includes(i) : false}
                  title={i}
                  updateField={(field, value) => updateIncidentField(undefined, field, value)}
                  incidentContent={(incidentContent || {})[i]}
                />
              </IncidentOverviewNoSection>
            );
          }

          if (protectorType === ProtectorType.Section) return null;

          return (
            <IncidentOverviewNoSection>
              <IncidentForm
                editable
                properties={(customIncidentType.schema.properties || {})[i]}
                required={customIncidentType.schema.required ? customIncidentType.schema.required.includes(i) : false}
                title={i}
                updateField={(field, value) => updateIncidentField(undefined, field, value)}
                incidentContent={(incidentContent || {})[i]}
              />
            </IncidentOverviewNoSection>
          );
        })}
    </Container>
  );

export default ReportingIncidentCommon;
