import React from 'react';
import { GridFilterItem } from '@mui/x-data-grid/models/gridFilterItem';
import { escapeRegExp } from 'lodash';
import { GridFilterOperator, useGridApiContext } from '@mui/x-data-grid';

import { CenteredCircularProgress } from 'components/Progress';

import { useSearch } from 'utils/hooks/search';

import { APIRequest, UuidableName } from 'utils/api/types';

import { StyledTextField } from 'components/Input/styles';

import { StyledAutocomplete, StyledOption } from './styles';
import { SearchProps } from '../types';

const loadingOption = { uuid: 'loading', name: 'Loading...' } as UuidableName;

const SearchInput: React.FunctionComponent<SearchProps> = ({ apiSearch, applyValue, item }) => {
  const { results, loading, searchQuery } = useSearch(apiSearch, undefined, { limit: 20 });
  const [selected, setSelected] = React.useState<UuidableName | null | undefined>(undefined);

  React.useEffect(() => {
    if (item.value) {
      setSelected((previous) => previous || (results.find((r) => r.uuid === item.value) as UuidableName));
    } else {
      setSelected((previous) => previous || null);
    }
  }, [results]);

  return (
    <StyledAutocomplete
      options={selected === undefined ? [loadingOption] : results}
      value={selected === undefined ? loadingOption : selected}
      isOptionEqualToValue={(option: UuidableName | null, val: UuidableName | null) =>
        val?.uuid !== undefined && val?.uuid === option?.uuid
      }
      renderOption={(props, option: UuidableName) =>
        option.uuid === loadingOption.uuid ? (
          <StyledOption>
            <CenteredCircularProgress />
          </StyledOption>
        ) : (
          <StyledOption {...props}>{option.name}</StyledOption>
        )
      }
      getOptionLabel={(option: UuidableName | null) => option?.name as string}
      getOptionDisabled={(option: UuidableName) => option.uuid === loadingOption.uuid || option.uuid === selected?.uuid}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          placeholder="Field Value"
          value={selected?.name || ''}
          onChange={(e) => {
            searchQuery(e.target.value);
          }}
        />
      )}
      loading={loading}
      noOptionsText="-"
      onChange={(_, rawValue: UuidableName | null) => {
        setSelected(rawValue);
        applyValue({ ...item, value: rawValue?.uuid });
      }}
      fullWidth
    />
  );
};

const cache = new Map<string, string>();

export default function<T>(
  apiSearch: (params: Partial<{ query: string; limit: number; offset: number }>) => APIRequest<T[]>,
  loadDetails: (uuid: string) => unknown,
  valueKey: string
): GridFilterOperator[] {
  return ([
    {
      label: 'is',
      value: 'is',
      getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (!filterItem.value) {
          return null;
        }

        const filterRegex = new RegExp(escapeRegExp(filterItem.value.trim()), 'i');
        return ({ value }: { value: string }): boolean => (value != null ? filterRegex.test(value.toString()) : false);
      },
      InputComponent: SearchInput,
      InputComponentProps: { apiSearch },
      getValueAsString: (value: string) => {
        const context = useGridApiContext();
        (async () => {
          if (cache.has(value)) {
            return;
          }
          // @ts-ignore
          const { data } = await loadDetails(value)();
          cache.set(value, data[valueKey]);
          context.current.setFilterModel({ ...context.current.state.filter.filterModel });
        })();

        return cache.get(value) || 'loading...';
      },
    },
  ] as unknown) as GridFilterOperator[];
}
