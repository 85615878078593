import React from 'react';

import Record from 'vatix-ui/lib/containers/Record/Record';

import MainHeader from 'components/MainHeader';

import { Button } from 'components/Button';

import routes from 'core/routes';
import { useNavBarHeight } from 'utils/hooks/navbar';

import AddEntityModal from 'components/Entities/AddEntityModal/AddEntityModal';
import { useStore } from 'utils/hooks/store';

import SuppliersTable from './components/SuppliersTable';

const Suppliers: React.FunctionComponent = (): React.ReactElement => {
  const navBarHeight = useNavBarHeight();
  const [openModal, setOpenModal] = React.useState(false);
  const refreshRef = React.useRef<Function | null>(null);
  const { session, supplierDetails, notification } = useStore();

  const [suppliersCount, setSuppliersCount] = React.useState(0);

  React.useEffect(() => {
    const fetchLoadCount = async (): Promise<void> => {
      const count = await supplierDetails.loadCount();
      setSuppliersCount(count);
    };

    fetchLoadCount();
  }, []);

  const onClose = (created: boolean): void => {
    setOpenModal(false);
    if (refreshRef.current && created) {
      refreshRef.current();
    }
  };
  const onRefreshCallback = (callback: Function): void => {
    refreshRef.current = callback;
  };

  const handleNewButtonClick = (): void => {
    if (session.user && suppliersCount >= session.user.organisation.maximumSuppliersAllowed) {
      notification.enqueueErrorSnackbar('Maximum suppliers limit reached. Please contact us to increase your limit.');
    } else {
      setOpenModal(true);
    }
  };

  return (
    <>
      <Record
        Header={
          <MainHeader
            title="Suppliers"
            breadcrumbs={[
              { label: 'Suppliers', link: routes.dashboard.suppliers.toString() },
              { label: 'Overview', link: routes.dashboard.suppliers.toString() },
            ]}
            action={
              <Button variant="contained" size="large" onClick={handleNewButtonClick}>
                New
              </Button>
            }
          />
        }
        Content={(onError) => <SuppliersTable onError={onError} onRefreshCallback={onRefreshCallback} />}
        navBarHeight={navBarHeight}
      />
      <AddEntityModal openModal={openModal} store={supplierDetails} onClose={onClose} />
    </>
  );
};

export default Suppliers;
