import styled from 'styled-components';

import { Button } from 'components/Button';

import CustomModal from 'components/CustomModal';

const ModalText = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0.15px;
`;

const ModalForChangingQuestionType: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  onContinue: () => void;
}> = ({ isOpen, onClose, onContinue }): React.ReactElement => {
  const handleCancel = (): void => {
    onClose();
  };

  const handleContinue = (): void => {
    onContinue();
  };

  return (
    <CustomModal
      onClose={handleCancel}
      openModal={isOpen}
      modalTitle="Changing Question Type"
      cancelButton={
        <Button variant="outlined" onClick={handleCancel}>
          Cancel
        </Button>
      }
      submitButton={
        <Button variant="contained" onClick={handleContinue}>
          Continue
        </Button>
      }
    >
      <ModalText>
        You are about to change the type of a question that is currently mapped to an event record field. Changing the
        type of this question will remove its current mapping.
        <br />
        <br />
        This action cannot be undone, and you will need to re-map the question after saving your changes. Would you like
        to proceed?
      </ModalText>
    </CustomModal>
  );
};

export default ModalForChangingQuestionType;
