// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { styled } from '@mui/system';
import MaterialUIButton from '@mui/material/Button';

import vatixTheme from '../../theme/vatix';

export const Button = styled(MaterialUIButton)`
  border-radius: 5px;
  font-style: normal;
  letter-spacing: 0.46px;
  text-transform: initial;
  white-space: nowrap;

  &.MuiButton-sizeLarge {
    font-size: 15px;
    height: 42px;
  }

  &.MuiButton-sizeMedium {
    font-size: 14px;
    height: 36px;
  }

  &.MuiButton-sizeSmall {
    font-size: 13px;
    height: 30px;
  }

  //contained variant

  &.MuiButton-contained {
    box-shadow: none;
  }

  &:not(:disabled).MuiButton-containedInfo {
    background: ${vatixTheme.ui.button.primaryInfo.main};
  }

  &:not(:disabled).MuiButton-containedPrimary {
    background: ${vatixTheme.ui.button.primaryInfo.main};
  }

  &:hover.MuiButton-containedInfo {
    background: ${vatixTheme.ui.button.primaryInfo.dark};
  }

  &:hover.MuiButton-containedPrimary {
    background: ${vatixTheme.ui.button.primaryInfo.dark};
  }

  //outlined variant

  &:not(:disabled).MuiButton-outlinedInfo {
    color: ${vatixTheme.ui.button.primaryInfo.main};
    border-color: ${vatixTheme.ui.button.primaryInfo.outlinedBorder};
  }

  &:not(:disabled).MuiButton-outlinedPrimary {
    color: ${vatixTheme.ui.button.primaryInfo.main};
    border-color: ${vatixTheme.ui.button.primaryInfo.outlinedBorder};
  }

  &:hover.MuiButton-outlinedInfo {
    background: ${vatixTheme.ui.button.primaryInfo.light};
  }

  &:hover.MuiButton-outlinedPrimary {
    background: ${vatixTheme.ui.button.primaryInfo.light};
  }

  //text variant
  &:not(:disabled).MuiButton-textInfo {
    color: ${vatixTheme.ui.button.primaryInfo.main};
  }

  &:not(:disabled).MuiButton-textPrimary {
    color: ${vatixTheme.ui.button.primaryInfo.main};
  }

  &:hover.MuiButton-textInfo {
    background: ${vatixTheme.ui.button.primaryInfo.light};
  }

  &:hover.MuiButton-textPrimary {
    background: ${vatixTheme.ui.button.primaryInfo.light};
  }
`;

export const CancelButton = Button;
