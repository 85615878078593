import React from 'react';
import { observer } from 'mobx-react';
import EventItem from 'vatix-ui/lib/components/Feed/EventItem';

import { EventStateActivityResponse, UuidableName } from 'utils/api/types';
import { EntityState } from 'core/constants';

const EventState: React.FunctionComponent<{
  activity: EventStateActivityResponse;
  sessionUser: UuidableName;
}> = ({ activity, sessionUser }) => {
  if (activity.state === EntityState.Created) {
    return (
      <EventItem activity={activity} sessionUser={sessionUser} id={`activity-comment-log-${activity.state}`}>
        created the event
      </EventItem>
    );
  }

  if (activity.state === EntityState.Deleted) {
    return (
      <EventItem activity={activity} sessionUser={sessionUser} id={`activity-comment-log-${activity.state}`}>
        deleted the event
      </EventItem>
    );
  }

  if (activity.state === null) {
    return null;
  }

  return (
    <EventItem activity={activity} sessionUser={sessionUser} id={`activity-comment-log-${activity.state}`}>
      {` has changed the state to ${activity.state}`}
    </EventItem>
  );
};

export default observer(EventState);
