import React from 'react';

import { reverse } from 'named-urls';

import { Button } from 'components/Button';

import { HomeSectionHeader, HomeSectionHeaderContainer } from 'containers/Home/styles';
import routes from 'core/routes';
import { useStore } from 'utils/hooks/store';

import { Solution } from './types';
import { SolutionCell, SolutionDescription, SolutionImage, SolutionTitle, SolutionsContainer } from './styles';

const SolutionsPane: React.FunctionComponent<{ displayHeader?: boolean }> = ({ displayHeader = true }) => {
  const {
    session: { user },
    routing,
  } = useStore();

  const incidentsEnabled = user?.licenses.protectorIncidents || false;
  const alarmsEnabled = user?.licenses.protectorAlarms || false;
  const auditsEnabled = user?.licenses.workflows || false;

  const solutions: Solution[] = [
    {
      title: 'Incidents',
      description:
        // eslint-disable-next-line max-len
        'Centralised platform for seamless incident reporting, investigation, and follow-up action tracking across your organisation.',
      imgName: 'incidentsSolution.png',
      enabled: incidentsEnabled,
      route: incidentsEnabled
        ? routes.dashboard.incidents.toString()
        : reverse(routes.dashboard.disableFeature.details, { featureId: 'incidents' }),
    },
    {
      title: 'Lone Working',
      description:
        // eslint-disable-next-line max-len
        'Safeguard your staff and boost H&S compliance through our 24/7 monitored lone working app or lone working devices.',
      imgName: 'loneWorkingSolution.png',
      enabled: alarmsEnabled,
      route: alarmsEnabled
        ? routes.dashboard.alarms.red.toString()
        : reverse(routes.dashboard.disableFeature.details, { featureId: 'protector' }),
    },
    {
      title: 'Audits',
      description:
        // eslint-disable-next-line max-len
        'Create audits & inspections templates, complete inspections through the mobile app, and coordinate corrective tasks.',
      imgName: 'auditsSolution.png',
      enabled: auditsEnabled,
      route: auditsEnabled ? '§' : reverse(routes.dashboard.disableFeature.details, { featureId: 'audits' }),
    },
  ];
  const goToModule = (route: string): void => {
    routing.push(route);
  };

  return (
    <>
      {displayHeader ? (
        <HomeSectionHeaderContainer>
          <HomeSectionHeader>Our Solutions</HomeSectionHeader>
        </HomeSectionHeaderContainer>
      ) : null}
      <SolutionsContainer>
        {solutions.map(({ title, description, imgName, enabled, route }, index) => (
          <SolutionCell key={`solution_${index}`}>
            <SolutionTitle>{title}</SolutionTitle>
            <SolutionImage src={`/solutions/${imgName}`} alt={`${title} solution image`} />
            <SolutionDescription>{description}</SolutionDescription>
            <div>
              <Button
                onClick={() => {
                  goToModule(route);
                }}
                sx={{
                  padding: '6px 0px 6px 0px',
                }}
              >
                {enabled ? 'View Module' : 'Unlock Module'}
              </Button>
            </div>
          </SolutionCell>
        ))}
      </SolutionsContainer>
    </>
  );
};

export default SolutionsPane;
