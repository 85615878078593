import React from 'react';

import Record from 'vatix-ui/lib/containers/Record/Record';

import { reverse } from 'named-urls';

import { useRouteMatch } from 'react-router-dom';

import { GridEventListener, GridRowParams } from '@mui/x-data-grid';

import { formatDateTime } from 'utils/formatters/time';

import { MainHeader } from 'components/MainHeader/MainHeader';

import { Button } from 'components/Button';

import routes from 'core/routes';

import { useNavBarHeight } from 'utils/hooks/navbar';

import { getEntityTranslation } from 'stores/EntityDetails/utils';
import { EntityModules } from 'core/constants';
import Grid, { defaultFormatters, defaultOperators } from 'components/Grid';
import { ColumnDefinition } from 'components/Grid/types';
import { useStore } from 'utils/hooks/store';

import ActionCell from './ActionCell';
import StatusCell from './StatusCell';

import StatusFilteringOperator from './StatusFilteringOperator';
import AudienceFilteringOperator from './AudienceFilteringOperator';
import { StatusCellProps } from './StatusCell/StatusCell';

export const basicColumns = ([
  {
    field: 'name',
    headerName: 'Form name',
  },
  {
    field: 'audience',
    headerName: 'Audience',
    nativeFilter: 'audience',
    valueGetter: (params: GridRowParams): string => params.row.settings.audience,
    valueFormatter: ({ value }: { value: string[] }): string => {
      if (value.length === 1) {
        return value[0].charAt(0).toUpperCase() + value[0].slice(1);
      }
      return 'Public & User';
    },
    filterOperators: AudienceFilteringOperator,
  },
  {
    field: 'updatedAt',
    nativeFilter: 'updatedAt',
    headerName: 'Last published',
    filterOperators: defaultOperators.date_time(),
    valueFormatter: defaultFormatters.date,
    renderCell: ({ value }: { value: string }) => formatDateTime(value),
  },
  {
    field: 'status',
    nativeFilter: 'active',
    renderCell: ({ row, colDef }: { row: StatusCellProps['row']; colDef: ColumnDefinition }) => (
      <StatusCell row={row} actions={colDef.actions} />
    ),
    headerName: 'Status',
    filterOperators: StatusFilteringOperator,
  },
  {
    maxWidth: 50,
    headerName: '',
    type: 'actions',
    field: 'actions',
    ariaLabel: 'actions',
    renderCell: ({ row }: { row: { uuid: string } }) => <ActionCell rowData={row} />,
    sortable: false,
  },
] as unknown) as ColumnDefinition[];

const EventForms = (): React.ReactElement => {
  const { routing } = useStore();
  const navBarHeight = useNavBarHeight();
  const match = useRouteMatch(routes.dashboard.objectManager.details.formBuilder.toString());

  const { moduleName } = match?.params as { moduleName: string };

  const onRowClick: GridEventListener<'rowClick'> = (form: GridRowParams): void => {
    const path = reverse(routes.dashboard.objectManager.details.formBuilder.toString(), {
      moduleName: EntityModules.Events,
    });
    routing.push(`${path + form.row.uuid}/`);
  };

  return (
    <Record
      navBarHeight={navBarHeight}
      Header={
        <MainHeader
          title="Forms"
          breadcrumbs={[
            {
              label: 'Object Manager',
              link: reverse(routes.dashboard.objectManager.details.fieldManager, { moduleName }),
            },
            {
              label: getEntityTranslation[moduleName as EntityModules].plural,
              link: reverse(routes.dashboard.objectManager.details.fieldManager, { moduleName }),
            },
            {
              label: 'Forms',
              link: reverse(routes.dashboard.objectManager.details.formBuilder.toString(), { moduleName }),
            },
          ]}
          action={
            <Button
              variant="contained"
              onClick={() => {
                const path = reverse(routes.dashboard.objectManager.details.formBuilder.toString(), {
                  moduleName: EntityModules.Events,
                });
                routing.push(`${path}new/`);
              }}
            >
              New
            </Button>
          }
        />
      }
      Content={(onError) => (
        <Grid
          disableExport
          onError={onError}
          disableColumnSelector
          onRowClick={onRowClick}
          basicColumns={basicColumns}
          entity={`${moduleName} forms`}
          key={`form_builder_${moduleName}`}
          dataURL={`/api/entities/${moduleName}/forms`}
          customNoRowsText="No forms have been created yet"
        />
      )}
    />
  );
};

export default EventForms;
