import React from 'react';

import { Form, Formik, FormikHelpers } from 'formik';
import { Box } from '@mui/material';

import { observer } from 'mobx-react';

import { Button } from 'components/Button';

import { formErrors, isBadRequest } from 'utils/api/errors';
import UserRow from 'components/UserRow';
import { DeviceDetailsResponse } from 'utils/api/types';
import { useStore } from 'utils/hooks/store';

import CustomTextInputField from 'containers/IncidentDetails/components/CustomTextInputField';
import Logger from 'utils/logger';

import TeamsAutocomplete from './components/TeamsAutocomple';
import TagsAutocomplete from './components/TagsAutocomplete';

import {
  CancelButton,
  DeviceDetailsBlock,
  DeviceDetailsBlockItem,
  DeviceDetailsBlockItemTitle,
  EditDeviceRowName,
  FormControlContainer,
  StyledId,
  StyledName,
} from './styles';
import { EditDevicePropertiesProps } from './types';

const Row: React.FC<{ name: string; children: React.ReactNode; id?: string }> = ({
  name,
  children,
  id,
}): React.ReactElement => (
  <div key={id}>
    <EditDeviceRowName>{name}</EditDeviceRowName>
    <div>{children}</div>
  </div>
);

const EditDeviceProperties: React.FunctionComponent<EditDevicePropertiesProps> = ({ setEditing }) => {
  const {
    deviceDetails: { details: detailsRaw, updateDevice },
  } = useStore();
  const details = detailsRaw as DeviceDetailsResponse;
  const [deviceDetailsData] = React.useState(details);

  const onUpdate = async (
    data: DeviceDetailsResponse,
    helpers: FormikHelpers<DeviceDetailsResponse>
  ): Promise<void> => {
    try {
      helpers.setSubmitting(true);
      const res = await updateDevice({
        tags: data.tags.map((tag) => ({ name: tag.name })),
        groups: data.groups.map((group) => group.uuid),
        costCentre: data.costCentre,
      });
      setEditing(!res);
    } catch (e) {
      // @ts-ignore
      const { nonFieldErrors, fieldErrors } = formErrors(e);
      fieldErrors && helpers.setErrors(fieldErrors);
      nonFieldErrors && helpers.setStatus(nonFieldErrors);
      // @ts-ignore
      if (!isBadRequest(e)) {
        Logger.error('Invalid update user details API response', e);
      }
    } finally {
      helpers.setSubmitting(false);
    }
  };

  return (
    <Box aria-label="edit-device-properties">
      <DeviceDetailsBlock>
        <DeviceDetailsBlockItem>
          <DeviceDetailsBlockItemTitle>Device ID</DeviceDetailsBlockItemTitle>
          <StyledName>{deviceDetailsData.customId}</StyledName>
        </DeviceDetailsBlockItem>
        <DeviceDetailsBlockItem>
          <DeviceDetailsBlockItemTitle>Device</DeviceDetailsBlockItemTitle>
          <Box display="flex" flexDirection="row">
            <StyledName>
              {deviceDetailsData?.type.brand} {deviceDetailsData?.type.model}
            </StyledName>
            <StyledId>{` (${deviceDetailsData?.customId})`}</StyledId>
          </Box>
        </DeviceDetailsBlockItem>
        <DeviceDetailsBlockItem>
          <DeviceDetailsBlockItemTitle>Current User</DeviceDetailsBlockItemTitle>
          {deviceDetailsData?.user ? <UserRow user={deviceDetailsData.user} /> : <StyledName>-</StyledName>}
        </DeviceDetailsBlockItem>
      </DeviceDetailsBlock>
      <Formik initialValues={deviceDetailsData} onSubmit={(values, helpers) => onUpdate(values, helpers)}>
        {({ values, isSubmitting, setFieldValue }) => (
          <Form>
            <Row name="Teams">
              <TeamsAutocomplete groups={values.groups} setFieldValue={setFieldValue} />
            </Row>
            <Row name="Cost centre">
              <CustomTextInputField
                description="Cost centre"
                value={values.costCentre || ''}
                onChange={(value) => setFieldValue('costCentre', value)}
              />
            </Row>
            <Row name="Tags">
              <TagsAutocomplete tags={values.tags} setFieldValue={setFieldValue} />
            </Row>
            <FormControlContainer>
              <CancelButton disabled={isSubmitting} onClick={() => setEditing(false)} variant="outlined" size="large">
                Cancel
              </CancelButton>
              <Button type="submit" disabled={isSubmitting} variant="contained" size="large">
                Save
              </Button>
            </FormControlContainer>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default observer(EditDeviceProperties);
