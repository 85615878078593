import React from 'react';

import { APIRequest } from '../api/types';

import Logger from '../logger';

export const useSearch = <T>(
  request: (params?: Partial<{ query: string; limit: number; offset: number }>) => APIRequest<T[]>,
  load = true,
  options: {
    limit?: number;
    offset?: number;
  } = {}
): {
  results: T[];
  loading: boolean;
  loadMore: (newLimit: number) => void;
  searchQuery: (phrase: string) => Promise<{ label: string; value: string }[]>;
} => {
  const [results, setResults] = React.useState<T[]>([]);
  const [requesting, setRequesting] = React.useState(false);
  const [requested, setRequested] = React.useState(false);
  const [limit, setLimit] = React.useState(options.limit || 5);
  const [offset, setOffset] = React.useState(options.offset || 0);
  const [query, setQuery] = React.useState('');
  const currentRequestID = React.useRef(0);

  const loadMore = (newLimit: number): void => {
    setLimit(newLimit);
    setOffset(results.length);
    setRequested(false);
    setRequesting(false);
  };

  const searchQuery = async (phrase: string): Promise<{ label: string; value: string }[]> => {
    setQuery(phrase);
    setRequested(false);
    setRequesting(false);

    // @ts-ignore
    return results.map((type) => ({ label: type.name, value: type.uuid }));
  };

  React.useEffect(() => {
    setRequested(false);
  }, [request]);

  React.useEffect(() => {
    (async () => {
      if (!load || requested) {
        return;
      }
      try {
        setRequesting(true);
        setRequested(true);
        currentRequestID.current += 1;
        const requestID = currentRequestID.current;
        const { data } = await request({ limit, offset, query: query.trim() })();
        if (requestID === currentRequestID.current) {
          setResults(data);
        }
      } catch (error) {
        Logger.warn('Invalid response from the search API', error);
      } finally {
        setRequesting(false);
      }
    })();
  }, [request, requested, load, limit, offset, query]);

  return { results, loading: requesting, loadMore, searchQuery };
};
