import React from 'react';

import EventItem from 'vatix-ui/lib/components/Feed/EventItem';

import { TestDeviceActivityProps } from './types';

const TestDeviceActivity: React.FunctionComponent<TestDeviceActivityProps> = ({ activity, sessionUser }) => (
  <EventItem activity={activity} sessionUser={sessionUser} id="activity-test-device">
    successfully completed a device test
  </EventItem>
);

export default TestDeviceActivity;
