import React from 'react';
import { observer } from 'mobx-react';

import File from 'vatix-ui/lib/components/Feed/File';

import { ActivityType } from 'utils/stores/Activities/constants';

import API from 'utils/api';
import Logger from 'utils/logger';
import { useStore } from 'utils/hooks/store';

import { VoiceMemoActivityProps } from './types';

const VoiceMemoActivity: React.FunctionComponent<VoiceMemoActivityProps> = ({ activity, ...props }) => {
  const { notification } = useStore();

  const handleDownload = async (): Promise<void> => {
    try {
      const {
        data: { recording },
      } = await API.loadVoiceMemoDetails(activity.uuid)();
      window.open(recording, '_blank');
    } catch (e) {
      Logger.error('Invalid load voice memo details API response', e);
      notification.enqueueErrorSnackbar('Voice Memo cannot be downloaded right now.');
    }
  };

  return (
    <File
      activity={{
        filename: activity.extra.fileName,
        file: activity.uuid,
        size: activity.extra.fileSize,
        uuid: activity.uuid,
        // adding ts-ignore for now, but this error will be fixed after full migration from vatix-ui
        // @ts-ignore
        type: ActivityType.File,
        created: (activity.created as unknown) as string,
      }}
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      downloadFile={(_) => handleDownload}
      {...props}
    />
  );
};

export default observer(VoiceMemoActivity);
