import React from 'react';
import { IconButton } from '@mui/material';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { observer } from 'mobx-react';

import PhoneInput from 'components/PhoneInput';

import Delete from 'components/Icons/Delete';

import { StyledTextField } from 'components/Input/styles';

import {
  CellHeader,
  EscalationCell,
  EscalationContainer,
  EscalationOrder,
} from 'containers/EscalationProfileDetails/styles';

import DragHandle from '../SortableList/components/DragHandle';

import { EscalationInstructionRowProps } from './types';

const EscalationInstructionRow: React.FunctionComponent<EscalationInstructionRowProps> = ({
  instruction,
  SortableItemContext,
  index,
  removeInstruction,
  updateInstruction,
  duplicateInstruction,
  errors,
}) => {
  const [contactNameValue, setContactNameValue] = React.useState(instruction.contactName);
  const [phoneNumberValue, setPhoneNumberValue] = React.useState(instruction.phoneNumber);
  const [instructionValue, setInstructionValue] = React.useState(instruction.description);

  const updateActionProperty = (propertyName: string, propertyValue: string | undefined): void => {
    updateInstruction(index - 1, { [propertyName]: propertyValue });
  };

  return (
    <EscalationContainer>
      <EscalationOrder>{index}</EscalationOrder>
      <DragHandle SortableItemContext={SortableItemContext} />

      <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <EscalationCell width="25%">
          <CellHeader>Contact name</CellHeader>
          <StyledTextField
            style={{ backgroundColor: '#fff' }}
            value={contactNameValue}
            onChange={(event) => {
              setContactNameValue(event.target.value);
            }}
            onBlur={() => updateActionProperty('contactName', contactNameValue)}
            aria-label="Contact name field"
            placeholder="Enter name"
          />
          <p style={{ color: 'red' }}>{errors?.find((err) => err.key === 'contactName')?.message}</p>
        </EscalationCell>

        <EscalationCell width="25%">
          <CellHeader>Phone number</CellHeader>

          <PhoneInput
            required
            style={{ width: '100%', backgroundColor: '#fff' }}
            defaultCountry="gb"
            preferredCountries={['gb', 'ie', 'us']}
            regions={(['north-america', 'europe', 'middle-east'] as unknown) as [string]}
            value={phoneNumberValue}
            onChange={(e) => setPhoneNumberValue(e as string)}
            onBlur={() => updateActionProperty('phoneNumber', phoneNumberValue)}
            variant="outlined"
            disableAreaCodes
            placeholder="e.g. +44 0123456789"
          />
          <p style={{ color: 'red' }}>{errors?.find((err) => err.key === 'phoneNumber')?.message}</p>
        </EscalationCell>

        <EscalationCell width="50%">
          <CellHeader>Instructions (Optional)</CellHeader>
          <StyledTextField
            style={{ backgroundColor: '#fff' }}
            value={instructionValue}
            onChange={(event) => {
              setInstructionValue(event.target.value);
            }}
            onBlur={() => updateActionProperty('description', instructionValue)}
            aria-label="Instruction field"
            placeholder="Enter your text here..."
            inputProps={{ maxLength: 85 }}
            helperText={
              instructionValue && instructionValue.length >= 85
                ? 'Maximum character limit reached (85 characters).'
                : null
            }
            FormHelperTextProps={{ sx: { background: '#fafafa', margin: 0, paddingLeft: '4px' } }}
          />
        </EscalationCell>
      </div>

      <IconButton
        onClick={() => {
          duplicateInstruction(instruction.uuid || '');
        }}
        aria-label="Duplicate instruction"
        size="large"
      >
        <ContentCopyIcon />
      </IconButton>
      <IconButton
        onClick={() => {
          removeInstruction(instruction.uuid || '');
        }}
        aria-label="Remove instruction"
        size="large"
      >
        <Delete />
      </IconButton>
    </EscalationContainer>
  );
};

export default observer(EscalationInstructionRow);
