import React from 'react';
import { observer } from 'mobx-react';

import { MenuItem, Switch, Autocomplete, Checkbox, FormControl, CircularProgress } from '@mui/material';

import { Person } from '@mui/icons-material';

import { Formik, Form } from 'formik';

import CheckBoxIcon from '@mui/icons-material/CheckBox';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import { AxiosError } from 'axios';

import { trackEvent } from '@intercom/messenger-js-sdk';

import { CenteredCircularProgress } from 'components/Progress';
import { Button } from 'components/Button';

import { useSearch } from 'utils/hooks/search';

import Logger from 'utils/logger';

import { StyledSelect, StyledTextField } from 'components/Input/styles';

import API from 'utils/api';

import { useStore } from 'utils/hooks/store';

import CustomModal from 'components/CustomModal';

import Chip from 'components/Chip';

import { UserRole } from 'core/constants';

import { SearchResponse } from 'utils/api/types';

import {
  StyledModalActions,
  InputLabel,
  ProductLicencesGroup,
  StyledOption,
  RedFormHelperText,
  OptionalText,
} from './styles';
import RoleInfo from './components/RoleInfo';
import { FormValues, getUserValues, getValidationSchema, initialValues } from './types';

const loadingOption = { uuid: 'loading', name: 'Loading...' } as SearchResponse;

const AddUsersModal: React.FC<{ onCloseRefresh: () => void }> = ({ onCloseRefresh }): React.ReactElement => {
  const [openModal, setOpenModal] = React.useState(false);
  const { notification, session } = useStore();
  const { results, searchQuery, loading } = useSearch(API.searchGroups, undefined, { limit: 5 });
  const {
    results: organisationsResults,
    searchQuery: organisationsSearchQuery,
    loading: organisationLoading,
  } = useSearch(API.searchOrganisations, undefined, { limit: 5 });
  const [isResetting, setIsResetting] = React.useState(false);

  const onClose = (): void => {
    setOpenModal(false);
    onCloseRefresh();
  };

  const handleSave = async (
    values: FormValues,
    setFieldError: (field: string, message: string | undefined) => void,
    resetForm?: () => void
  ): Promise<void> => {
    if (resetForm) setIsResetting(true);
    const user = getUserValues(values);
    try {
      await API.createUser(user)();
      notification.enqueueSuccessSnackbar('User created succesfully.');
      if (session.user?.appCues) {
        window.Appcues.track('User created');
      }
      if (session.hasIntercom) {
        trackEvent('User created');
      }

      if (resetForm) {
        resetForm();
      } else {
        onClose();
      }
    } catch (e) {
      const err = e as AxiosError<Error[]>;
      const response = err.response?.data;

      if (Array.isArray(response)) {
        response.forEach((message) => {
          notification.enqueueErrorSnackbar((message as unknown) as string);
        });
      } else if (typeof response === 'object') {
        Logger.error('Cannot create user right now.', e);
        notification.enqueueErrorSnackbar('Cannot create user right now.');
        const keys = Object.keys(response);
        if (keys.length === 1 && Array.isArray(response[keys[0]])) {
          const key = keys[0];
          // @ts-ignore
          response[key].forEach((message: string) => {
            setFieldError(key, message);
          });
        }
      }
    }
    setIsResetting(false);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpenModal(true)}
        size="large"
        style={{ marginLeft: '8px' }}
      >
        <Person />
        <p style={{ margin: 0, marginLeft: '8px' }}>Add User</p>
      </Button>
      <CustomModal openModal={openModal} modalTitle="Create User" onClose={onClose}>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setFieldError }) => handleSave(values, setFieldError)}
          validationSchema={getValidationSchema(session.user?.role as UserRole)}
        >
          {({ values, handleChange, isSubmitting, touched, errors, setFieldValue, resetForm, setFieldError }) => (
            <Form>
              <InputLabel>Name</InputLabel>
              <StyledTextField
                fullWidth
                id="name"
                name="name"
                placeholder="Name"
                value={values.name}
                disabled={isSubmitting}
                onChange={handleChange}
                helperText={touched.name ? errors.name : ''}
                error={(touched.name || false) && errors.name !== undefined}
              />

              <InputLabel>Email</InputLabel>
              <StyledTextField
                fullWidth
                id="email"
                name="email"
                type="email"
                placeholder="example@gmail.com"
                value={values.email}
                disabled={isSubmitting}
                onChange={handleChange}
                helperText={touched.email ? errors.email : ''}
                error={(touched.email || false) && errors.email !== undefined}
              />

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <InputLabel>Role</InputLabel>
                <RoleInfo />
              </div>

              <FormControl style={{ width: '100%' }}>
                <StyledSelect
                  fullWidth
                  id="role"
                  name="role"
                  variant="outlined"
                  labelId="role-select"
                  placeholder="Choose an option"
                  value={values.role}
                  disabled={isSubmitting}
                  onChange={handleChange}
                  error={(touched.role || false) && errors.role !== undefined}
                >
                  <MenuItem value="user">User</MenuItem>
                  <MenuItem value="manager">Manager</MenuItem>
                  <MenuItem value="account_owner">Account Owner</MenuItem>
                </StyledSelect>
                {touched.role && errors.role && <RedFormHelperText>{errors.role}</RedFormHelperText>}
              </FormControl>

              <InputLabel>Product licences</InputLabel>
              <ProductLicencesGroup aria-labelledby="checkbox-group">
                <div>
                  <p>Lone Working</p>
                  <Switch
                    size="small"
                    id="productLicenses.alarms"
                    name="productLicenses.alarms"
                    disabled={isSubmitting}
                    onChange={handleChange}
                    value={values.productLicenses.alarms}
                    checked={values.productLicenses.alarms}
                  />
                </div>
                <div>
                  <p>Incidents</p>
                  <Switch
                    size="small"
                    id="productLicenses.incidents"
                    name="productLicenses.incidents"
                    disabled={isSubmitting}
                    onChange={handleChange}
                    value={values.productLicenses.incidents}
                    checked={values.productLicenses.incidents}
                  />
                </div>
                <div>
                  <p>Audits</p>
                  <Switch
                    size="small"
                    id="productLicenses.audits"
                    name="productLicenses.audits"
                    disabled={isSubmitting}
                    onChange={handleChange}
                    checked={values.productLicenses.audits}
                    value={values.productLicenses.audits}
                  />
                </div>
              </ProductLicencesGroup>
              {touched.productLicenses && errors.productLicenses && <div>{errors.productLicenses.alarms}</div>}

              <InputLabel>
                Team <span style={{ fontWeight: 'normal' }}>(Optional)</span>
              </InputLabel>

              <Autocomplete
                multiple
                id="team"
                options={results}
                value={values.teams}
                disableCloseOnSelect
                renderOption={(props, option: SearchResponse, { selected }) =>
                  option.uuid === loadingOption.uuid ? (
                    <StyledOption>
                      <CenteredCircularProgress />
                    </StyledOption>
                  ) : (
                    <StyledOption {...props}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="medium" />}
                        checkedIcon={<CheckBoxIcon fontSize="medium" />}
                        checked={selected}
                      />
                      {option.name}
                    </StyledOption>
                  )
                }
                getOptionLabel={(option: SearchResponse | null) => option?.name as string}
                renderInput={(params) => (
                  <StyledTextField
                    {...params}
                    placeholder={values.teams.length === 0 ? 'Choose option(s)' : ''}
                    onChange={(e) => {
                      searchQuery(e.target.value);
                    }}
                  />
                )}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip {...getTagProps({ index })} label={`${option.name}`} size="medium" fontSize={14} />
                  ))
                }
                loading={loading}
                noOptionsText="-"
                onChange={(_, value) => {
                  setFieldValue('teams', value);
                  searchQuery('');
                }}
                isOptionEqualToValue={(option: SearchResponse | null, val: SearchResponse | null) =>
                  val?.uuid !== undefined && val?.uuid === option?.uuid
                }
                fullWidth
              />

              {session.user?.role === UserRole.Admin && (
                <>
                  <OptionalText>
                    As an Admin, you can select to which organisation all the previously specified users will be
                    invited.
                  </OptionalText>

                  <Autocomplete
                    id="organisation"
                    options={organisationsResults}
                    value={values.organisation}
                    renderOption={(props, option: SearchResponse) =>
                      option.uuid === loadingOption.uuid ? (
                        <StyledOption>
                          <CenteredCircularProgress />
                        </StyledOption>
                      ) : (
                        <StyledOption {...props}>{option.name}</StyledOption>
                      )
                    }
                    getOptionLabel={(option: SearchResponse | null) => option?.name as string}
                    renderInput={(params) => (
                      <StyledTextField
                        {...params}
                        placeholder=""
                        onChange={(e) => {
                          organisationsSearchQuery(e.target.value);
                        }}
                        error={(touched.organisation || false) && errors.organisation !== undefined}
                      />
                    )}
                    loading={organisationLoading}
                    noOptionsText="-"
                    onChange={(_, value) => {
                      setFieldValue('organisation', value);
                      organisationsSearchQuery('');
                    }}
                    isOptionEqualToValue={(option: SearchResponse | null, val: SearchResponse | null) =>
                      val?.uuid !== undefined && val?.uuid === option?.uuid
                    }
                    fullWidth
                  />

                  {touched.role && errors.organisation && (
                    <RedFormHelperText>{errors.organisation.name}</RedFormHelperText>
                  )}
                </>
              )}
              <StyledModalActions>
                <Button
                  type="button"
                  id="cancel-button"
                  onClick={onClose}
                  size="large"
                  disabled={isSubmitting || isResetting}
                >
                  Cancel
                </Button>
                <div>
                  <Button
                    size="large"
                    id="reset-button"
                    variant="outlined"
                    onClick={() => {
                      handleSave(values, setFieldError, resetForm);
                    }}
                    disabled={isSubmitting || isResetting}
                  >
                    {isResetting && <CircularProgress color="inherit" size="16px" style={{ marginRight: '8px' }} />}
                    Create and Add Another
                  </Button>
                  <Button
                    type="submit"
                    id="add-user-button"
                    variant="contained"
                    size="large"
                    style={{ marginLeft: '8px' }}
                    disabled={isSubmitting || isResetting}
                  >
                    {isSubmitting && <CircularProgress color="inherit" size="16px" style={{ marginRight: '8px' }} />}
                    Create
                  </Button>
                </div>
              </StyledModalActions>
            </Form>
          )}
        </Formik>
      </CustomModal>
    </>
  );
};

export default observer(AddUsersModal);
