import React from 'react';
import Record from 'vatix-ui/lib/containers/Record/Record';

import { useRouteMatch } from 'react-router-dom';

import DetailsLoader from 'vatix-ui/lib/components/DetailsLoader';

import { observer } from 'mobx-react';

import { CenteredCircularProgress } from 'components/Progress';

import MainHeader from 'components/MainHeader';

import NotFound from 'containers/Error/Pages/NotFound/NotFound';

import DataError from 'containers/Error/DataError';

import { isNotFound } from 'utils/api/errors';

import { useStore } from 'utils/hooks/store';

import { useNavBarHeight } from 'utils/hooks/navbar';

import routes from 'core/routes';

import DeviceInformation from './components/DeviceInformation';
import DeviceHistory from './components/DeviceHistory';
import DeviceActivitiesContent from './components/DeviceActivities/DeviceActivitiesContent';
import DeviceActivitiesFooter from './components/DeviceActivities/DeviceActivitiesFooter';
import { Wrapper } from './styles';

const DeviceDetails: React.FunctionComponent = (): React.ReactElement => {
  const { deviceDetails } = useStore();
  const match = useRouteMatch();
  const navBarHeight = useNavBarHeight();

  return (
    <DetailsLoader
      match={match}
      // @ts-ignore
      routeParam="deviceId"
      store={deviceDetails}
    >
      {({ isLoaded, error, reload }): React.ReactElement => {
        if (!isLoaded) {
          return <CenteredCircularProgress aria-label="device-details-loader" />;
        }

        if (error) {
          if (isNotFound(error)) {
            return <NotFound />;
          }
          return <DataError onReload={reload} />;
        }

        // TODO: Add skeletons

        return (
          <Record
            Header={
              <MainHeader
                // TODO: Device name could be passed with router
                // eslint-disable-next-line max-len
                title={`${deviceDetails.details?.type.brand} ${deviceDetails.details?.type.model} (${deviceDetails.details?.customId})`}
                breadcrumbs={[
                  {
                    label: 'Lone Working',
                    link: routes.dashboard.alarms.red.toString(),
                  },
                  {
                    label: 'Devices',
                    link: routes.dashboard.alarms.devices.toString(),
                  },
                  {
                    label: deviceDetails.details?.customId || 'Device details',
                    link: routes.dashboard.alarms.devices.details.toString(),
                  },
                ]}
              />
            }
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            Content={(_) => (
              <Wrapper>
                <DeviceInformation />
                <DeviceHistory />
              </Wrapper>
            )}
            Activities={{
              Content: <DeviceActivitiesContent />,
              Footer: <DeviceActivitiesFooter />,
            }}
            navBarHeight={navBarHeight}
            disableMaxContentHeight
          />
        );
      }}
    </DetailsLoader>
  );
};

export default observer(DeviceDetails);
