import React from 'react';

import { useRouteMatch } from 'react-router-dom';

import { reverse } from 'named-urls';

import { Divider, IconButton } from '@mui/material';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import { observer } from 'mobx-react';

import MainHeader from 'components/MainHeader';

import { useStore } from 'utils/hooks/store';

import routes from 'core/routes';

import { UserRole } from 'core/constants';

import {
  MainInformation,
  MainInformationColumn,
  UserName,
  MainInformationTitle,
  ButtonWrapper,
  StyledPopover,
  MenuItem,
} from './styles';

const UserDetails: React.FunctionComponent<{
  setDeletionModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  anchorEl: null | HTMLElement;
  setAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>;
}> = ({ setDeletionModalOpen, anchorEl, setAnchorEl }): React.ReactElement => {
  const { userDetails, session } = useStore();
  const match = useRouteMatch();

  const { userId } = match.params as { userId: string };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  return (
    <>
      {session.user?.role === UserRole.User ? null : (
        <MainHeader
          title={userDetails.details ? userDetails.details.name : 'N/A'}
          breadcrumbs={[
            { label: 'Admin', link: routes.dashboard.admin.users.toString() },
            { label: 'Users', link: routes.dashboard.admin.users.toString() },
            {
              label: 'Profile',
              link: reverse(routes.dashboard.admin.users.details, { userId }),
            },
          ]}
          action={
            <>
              <ButtonWrapper>
                <IconButton onClick={handleClick}>
                  <MoreVertIcon htmlColor="rgba(0, 0, 0, 0.54)" />
                </IconButton>
              </ButtonWrapper>
              <StyledPopover
                id="popover-deactivate-user"
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <MenuItem id="deactivate-button" onClick={() => setDeletionModalOpen(true)}>
                  Deactivate user
                </MenuItem>
              </StyledPopover>
            </>
          }
        />
      )}

      <MainInformation $withHeader={session.user?.role !== UserRole.User}>
        {userDetails.details?.name ? <UserName>{userDetails.details.name}</UserName> : <h1>-</h1>}
        <Divider orientation="vertical" flexItem style={{ margin: '0 30px' }} />
        <MainInformationColumn>
          <MainInformationTitle>Email</MainInformationTitle>
          {userDetails.details?.email ? <p>{userDetails.details?.email}</p> : <p>N/A</p>}
        </MainInformationColumn>
        <MainInformationColumn>
          <MainInformationTitle>Account</MainInformationTitle>
          {userDetails.details?.organisation ? <p>{userDetails.details?.organisation.name}</p> : <p>N/A</p>}
        </MainInformationColumn>
      </MainInformation>
    </>
  );
};

export default observer(UserDetails);
