import styled from 'styled-components';

import MenuItem from '@mui/material/MenuItem';
import { ListItem } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';

import { Dropdown } from 'components/Dropdown';

import VatixTheme from 'theme/vatix';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  justify-content: space-between;
`;

export const MultiAllowed = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    font-size: 14px;
    line-height: 16px;
  }
`;

export const StyledListItemButton = styled(ListItemButton)`
  color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Container = styled.div`
  display: flex;
`;

export const QuestionRow = styled(Container)`
  flex-direction: row;
  margin-bottom: 4px;
  align-items: flex-start;
  margin-left: 24px;
  width: calc(100% - 48px);
`;

export const QuestionsLeftSide = styled(Container)`
  align-items: center;
  min-width: 60%;
`;

export const QuestionsRightSide = styled(Container)`
  margin-left: 4px;
  width: 40%;
  flex-direction: column;
  align-items: flex-end;
`;

export const StyledDropdown = styled(Dropdown)<{ $type: boolean }>`
  width: 100%;
  margin: 0;
  padding: 0;

  .MuiSelect-select {
    padding-left: ${(props) => (props.$type ? '0' : '')};
    margin-left: ${(props) => (props.$type ? '0' : '')};
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  font-size: 1.4rem;
`;

export const StyledText = styled.p`
  padding: 0;
  margin: 0;
  color: black;
`;

export const Chip = styled.div`
  border: 1px solid #bdbdbd;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 99px;
  margin: 0 2px;
  padding: 0 4px;
  font-size: 12px;
  display: flex;
  justify-content: space-around;
  min-height: 28px;
  max-height: 28px;
  align-items: center;
`;

export const TextChip = styled(Chip)`
  min-width: 80px;
  max-width: 100px;
`;

export const NumberChip = styled(Chip)`
  width: 52px;
`;

export const EnumItemText = styled.p`
  max-width: 65%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const ChipContainer = styled(ListItem)`
  display: flex;
  text-align: center;
  margin: 0;
  padding: 0;
`;

export const CircleScore = styled.p`
  width: 22px;
  height: 22px;
  background-color: #bdbdbd;
  color: ${VatixTheme.palette.common.white};
  border-radius: 48px;
  text-align: center;
  vertical-align: middle;
  line-height: 22px;
  margin: 2px 0px 2px 4px;
`;
