import React from 'react';
import { observer } from 'mobx-react';
import { reverse } from 'named-urls';
import { GridEventListener, GridRowParams } from '@mui/x-data-grid';

import { formatDateTime } from 'utils/formatters/time';

import { VatixError } from 'components/Error/types';

import routes from 'core/routes';
import { useStore } from 'utils/hooks/store';

import Grid, { defaultFormatters, defaultOperators, defaultRenderers } from 'components/Grid';
import { IncidentResponse, JSONSchemaValue, LinkedTaskType } from 'utils/api/types';

import { ColumnDefinition } from 'components/Grid/types';

import { UserRole } from 'core/constants';

import { ExtendedUserOperators } from 'components/Grid/operators/User/User';

import { getEntityGridColumns } from 'components/Grid/components/EntitiesGridColumn/EntitiesGridColumn';

import LinkedTasksCell from '../LinkedTasksCell';
import { StyledCreated } from './styles';

import ActionCell from '../ActionCell/ActionCell';

export const incidentGridBasicColumns = ([
  {
    field: 'subject',
    headerName: 'Name',
    nativeFilter: true,
  },
  {
    field: 'Type',
    customColumn: true,
  },
  {
    field: 'creator',
    headerName: 'Reporter',
    filterOperators: defaultOperators.user(),
    renderCell: defaultRenderers.user,
    valueFormatter: defaultFormatters.user,
    nativeFilter: true,
  },
  {
    field: 'owner',
    headerName: 'Owner',
    filterOperators: ExtendedUserOperators(),
    renderCell: defaultRenderers.user,
    valueFormatter: defaultFormatters.user,
    nativeFilter: true,
  },
  {
    field: 'linkedTasks',
    headerName: 'Tasks',
    renderCell: ({ value }: { value: JSONSchemaValue<LinkedTaskType> }) => (
      <LinkedTasksCell linkedTasks={value?.value || { completed: 0, pending: 0, total: 0 }} />
    ),
    valueFormatter: ({ value }: { value: JSONSchemaValue<LinkedTaskType> }) =>
      `pending:${value?.value?.pending};completed:${value?.value?.completed};total:${value?.value?.total}`,
    nativeFilter: true,
    sortable: false,
  },
  {
    field: 'Status',
    customColumn: true,
  },
  {
    field: 'created',
    filterOperators: defaultOperators.date_time(),
    headerName: 'Created',
    renderCell: ({ value }: { value: JSONSchemaValue<string> }) => (
      <StyledCreated>{formatDateTime(value?.value)}</StyledCreated>
    ),
    nativeFilter: true,
    valueFormatter: defaultFormatters.date,
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: '',
    maxWidth: 50,
    ariaLabel: 'actions_cell',
    renderCell: ({ row, colDef }: { row: IncidentResponse; colDef: ColumnDefinition }) => (
      <ActionCell rowData={row} actions={colDef.actions} />
    ),
    filterOperators: null,
    sortable: false,
  },
] as unknown) as ColumnDefinition[];

const IncidentList: React.FunctionComponent<{
  onError: (error: VatixError) => void;
}> = ({ onError }) => {
  const { routing, session } = useStore();
  const onRowClick: GridEventListener<'rowClick'> = (incident: GridRowParams): void => {
    routing.push(reverse(routes.dashboard.incidents.details, { incidentId: incident.row.uuid }));
  };

  return (
    <Grid
      basicColumns={[...incidentGridBasicColumns, ...getEntityGridColumns(session.user?.activeEntityModules || [])]}
      onError={onError}
      onRowClick={onRowClick}
      dataURL="/api/incidents/?type=custom"
      fieldsURL="/api/incidents/fields/"
      customFieldsIncidentImplementation
      sortBy={{ field: 'created', sort: 'desc' }}
      disableSelectAllColumns
      disableColumnSelector={session.user?.role === UserRole.User}
      disableExtraFields={session.user?.role === UserRole.User}
      onlyBasicExport={session.user?.role === UserRole.User}
    />
  );
};

export default observer(IncidentList);
