import React from 'react';
import { observer } from 'mobx-react';
import { reverse } from 'named-urls';
import { GridEventListener, GridRowParams } from '@mui/x-data-grid';

import { useRouteMatch } from 'react-router-dom';

import Record from 'vatix-ui/lib/containers/Record/Record';

import MainHeader from 'components/MainHeader';

import routes from 'core/routes';
import { useStore } from 'utils/hooks/store';

import Grid from 'components/Grid';

import { useNavBarHeight } from 'utils/hooks/navbar';

import { tasksTableBasicColumns } from 'containers/Tasks/components/TasksTable/TasksTable';

import { getRelatedEntityDetails } from '../helper';

const RelatedActions: React.FunctionComponent = () => {
  const { routing } = useStore();
  const navBarHeight = useNavBarHeight();

  const onRowClick: GridEventListener<'rowClick'> = (task: GridRowParams): void => {
    routing.push(reverse(routes.dashboard.tasks.details, { taskId: task.row.uuid }));
  };

  const match = useRouteMatch();
  const { entityId } = match.params as { entityId: string };

  const {
    store: { loadDetails, details, path, detailsPath },
    translations,
    nameKey,
    entityType,
  } = getRelatedEntityDetails(match.url);

  React.useEffect(() => {
    const fetchDetails = async (): Promise<void> => {
      loadDetails(entityId);
    };

    fetchDetails();
  }, [entityId]);

  return (
    <Record
      Header={
        <MainHeader
          title={`Related Actions to ${translations.singular} ${details?.entity[nameKey].value || ''}`}
          breadcrumbs={[
            { label: translations.plural, link: path },
            { label: 'Details', link: detailsPath(entityId) },
            {
              label: 'Related Actions',
              link: reverse(routes.dashboard[entityType].relatedActions, { entityId }),
            },
          ]}
        />
      }
      Content={(onError) => (
        <Grid
          basicColumns={tasksTableBasicColumns}
          entity="action"
          onError={onError}
          onRowClick={onRowClick}
          dataURL={`/api/tasks/?entity=${entityId}`}
          sortBy={{ field: 'dueDate', sort: 'asc' }}
          customNoRowsText="No tasks have been created yet"
        />
      )}
      navBarHeight={navBarHeight}
    />
  );
};

export default observer(RelatedActions);
