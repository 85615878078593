import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useRouteMatch } from 'react-router-dom';

import Record from 'vatix-ui/lib/containers/Record/Record';

import { CenteredCircularProgress } from 'components/Progress';

import { useNavBarHeight } from 'utils/hooks/navbar';

import { MonitoringMethod } from 'core/constants';
import { ActionFlow, EscalationProfileDetailsResponse } from 'utils/api/types';
import { useStore } from 'utils/hooks/store';

import { StyledTextField } from 'components/Input/styles';

import EscalationProfileDetailsNavbar from './components/EscalationProfileDetailsNavbar/EscalationProfileDetailsNavbar';
import { AlarmRespondersHeader, BaseLayout, SectionHeader } from './styles';
import EscalationProfileDetailsSelf from './components/EscalationProfileDetailsSelf';
import EscalationProfileDetailsArc from './components/EscalationProfileDetailsArc';

const useEscalationProfile = (): { escalationProfileId: string; isNew: boolean } => {
  const match = useRouteMatch();
  const escalationProfileId = (match.params as { escalationProfileId: string }).escalationProfileId as string;
  const isNew = escalationProfileId === 'new-arc' || escalationProfileId === 'new-self';

  return { escalationProfileId, isNew };
};

const EscalationProfileDetails = (): React.ReactElement => {
  const navBarHeight = useNavBarHeight();
  const {
    escalationProfileDetails,
    escalationProfileDetails: { arcMonitoredDetails, selfMonitoredDetails },
  } = useStore();

  const { escalationProfileId, isNew } = useEscalationProfile();

  const [escalationNameValue, setEscalationNameValue] = useState<string | null>('');

  React.useEffect(() => {
    if (escalationProfileId && !isNew) {
      (async () => {
        await escalationProfileDetails.loadDetails(escalationProfileId);
      })();
    }
    return () => escalationProfileDetails.reset();
  }, [escalationProfileId, isNew]);

  const getInitialData = (): Partial<EscalationProfileDetailsResponse> => {
    const commonData = { name: '', escalationInstructions: [] };
    return escalationProfileId === 'new-arc'
      ? { ...commonData, escalationType: MonitoringMethod.ArcMonitored }
      : {
          ...commonData,
          escalationType: MonitoringMethod.SelfMonitored,
          actions: [],
          actionFlow: ActionFlow.SEQUENTIAL,
        };
  };

  const details = isNew ? getInitialData() : (escalationProfileDetails.details as EscalationProfileDetailsResponse);

  if (!isNew && !details && !escalationProfileDetails.isLoaded) {
    return <CenteredCircularProgress />;
  }

  return (
    <Record
      disableMaxContentHeight
      navBarHeight={navBarHeight}
      Content={() => (
        <BaseLayout>
          <SectionHeader>Alarm response profile name</SectionHeader>
          <StyledTextField
            fullWidth
            placeholder="Enter alarm response profile name"
            id="arp-name-input"
            value={details.name || escalationNameValue}
            onChange={(event) => {
              setEscalationNameValue(event.target.value);
              escalationProfileDetails.updateName(event.target.value);
            }}
          />
          <AlarmRespondersHeader>Alarm responders</AlarmRespondersHeader>
          {details.escalationType === MonitoringMethod.ArcMonitored ? (
            <EscalationProfileDetailsArc details={arcMonitoredDetails?.escalationInstructions || []} />
          ) : (
            <EscalationProfileDetailsSelf details={selfMonitoredDetails?.actions || []} />
          )}
        </BaseLayout>
      )}
      Header={<EscalationProfileDetailsNavbar monitoringMethod={details.escalationType} isNew={isNew} />}
    />
  );
};

export default observer(EscalationProfileDetails);
