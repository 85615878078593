import React from 'react';

import formatDistance from 'date-fns/formatDistance';
import { reverse } from 'named-urls';

import Avatar from 'components/Avatar/Avatar';

import routes from 'core/routes';
import { NotificationType } from 'utils/api/types';
import Notification from 'containers/Dashboard/components/Notification';
import { NotificationTarget } from 'core/constants';

import { NotificationItemContainer, NotificationStatusDot, TimeText, ContainerLink } from './styles';

const NotificationItem: React.FunctionComponent<{ item: NotificationType }> = ({ item }): React.ReactElement => {
  let linkTo = '';
  switch (item.target) {
    case NotificationTarget.NEW_INCIDENT_MESSAGE:
    case NotificationTarget.NEW_INCIDENT_SUBMISSION_MESSAGE:
    case NotificationTarget.NEW_INCIDENT_REPORTED:
    case NotificationTarget.NEW_INCIDENT_ASSIGNED_TO_ME:
      linkTo = reverse(routes.dashboard.incidents.details, { incidentId: item.instance.uuid });
      break;
    case NotificationTarget.NEW_TASK_MESSAGE:
    case NotificationTarget.NEW_TASK_ASSIGNED_TO_ME:
      linkTo = reverse(routes.dashboard.tasks.details, { taskId: item.instance.uuid });
      break;
    case NotificationTarget.NEW_FORM_SUBMITTED:
      linkTo = reverse(routes.dashboard.audits.forms.details, { formId: item.instance.uuid });
      break;
    case NotificationTarget.NEW_EVENT_REPORTED:
    case NotificationTarget.NEW_EVENT_ASSIGNED_TO_ME:
      linkTo = reverse(routes.dashboard.events.details, { entityId: item.instance.uuid });
      break;
    case NotificationTarget.NEW_EVENT_MESSAGE:
    case NotificationTarget.NEW_ASSET_MESSAGE:
    case NotificationTarget.NEW_SITE_MESSAGE:
    case NotificationTarget.NEW_SITE_ASSIGNED_TO_ME:
      linkTo = reverse(routes.dashboard.sites.details, { entityId: item.instance.uuid });
      break;
    default:
      linkTo = '';
  }

  return (
    <ContainerLink to={linkTo} data-testid="notification-item">
      <NotificationItemContainer key={`${item.creator.name}_${item.created}`}>
        <Avatar name={item.creator.name} />
        <div>
          <Notification notification={item} />
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <TimeText>{formatDistance(new Date(item.created), new Date(), { addSuffix: true })}</TimeText>
            <NotificationStatusDot
              dismissed={item.dismissed}
              data-testid={`notification-status-dot-${item.dismissed}`}
            />
          </div>
        </div>
      </NotificationItemContainer>
    </ContainerLink>
  );
};

export default NotificationItem;
