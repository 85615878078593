import React from 'react';

import { Button } from 'components/Button';

import { Container, Controls, DescriptionContainer, IllustrationContainer, Title, Subtitle } from './styles';

export default function PageFactory(
  Illustration: React.FunctionComponent,
  title: string,
  subtitles: string[]
): React.ReactNode {
  return (
    <Container>
      <IllustrationContainer>
        <Illustration />
      </IllustrationContainer>
      <DescriptionContainer>
        <Title>{title}</Title>
        {subtitles.map((subtitle) => (
          <Subtitle>{subtitle}</Subtitle>
        ))}
        <Controls>
          <Button variant="contained" href="/">
            Back to Home
          </Button>
        </Controls>
      </DescriptionContainer>
    </Container>
  );
}
