import React from 'react';

import Record from 'vatix-ui/lib/containers/Record/Record';

import MainHeader from 'components/MainHeader';

import { useNavBarHeight } from 'utils/hooks/navbar';

import { Container, Header, Text } from './styles';

const MainAnalytics: React.FunctionComponent = (): React.ReactElement => {
  const navBarHeight = useNavBarHeight();

  return (
    <Record
      navBarHeight={navBarHeight}
      Content={() => (
        <Container>
          <Header>Analytics</Header>
          <Text>Select a dashboard from the navigation bar to view data</Text>
        </Container>
      )}
      Header={<MainHeader title="Analytics" breadcrumbs={[]} />}
    />
  );
};

export default MainAnalytics;
