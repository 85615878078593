import React from 'react';

import PersonIcon from '@mui/icons-material/Person';

import InitialAvatar from '../InitialAvatar';
import { ImageAvatar } from '../styles';
import { AvatarProps } from './types';
import { InitialAvatarContainer, InitialAvatarName } from '../InitialAvatar/styles';

const Avatar: React.FunctionComponent<AvatarProps> = ({ name, image, empty, ...props }): React.ReactElement => {
  if (image) {
    return <ImageAvatar src={image} alt={name} {...props} />;
  }
  if (empty) {
    return (
      <InitialAvatarContainer {...props} $empty>
        <InitialAvatarName size={props.size}>
          <PersonIcon />
        </InitialAvatarName>
      </InitialAvatarContainer>
    );
  }
  return <InitialAvatar name={name} {...props} />;
};

Avatar.defaultProps = {
  size: 'medium',
};

export default Avatar;
