import React from 'react';

import CenteredCircularProgress from 'vatix-ui/lib/components/Progress/CenteredCircularProgress';

import { observer } from 'mobx-react';

import NotFound from 'containers/Error/Pages/NotFound/NotFound';

import { useStore } from 'utils/hooks/store';

import EmptyLayout from 'containers/LayoutEditor/components/helpers/EmptyLayout';

import RuleSection from './components/RuleSection';

const FormToFieldMappingDetails = (): React.ReactElement => {
  const {
    formBuilder: { formMapping },
  } = useStore();

  if (!formMapping.isLoaded) {
    return <CenteredCircularProgress aria-label="form-to-field-loading" />;
  }

  if (formMapping.error) {
    return <NotFound />;
  }

  const form = formMapping.getFormData();

  if (form === undefined || form?.order.length === 0) {
    return <EmptyLayout />;
  }

  return (
    <>
      {form.order.map((section) => {
        const { order, properties, title, description } = form.properties[section];
        return (
          <RuleSection key={section} order={order} properties={properties} title={title} description={description} />
        );
      })}
    </>
  );
};

export default observer(FormToFieldMappingDetails);
