import React from 'react';
import { observer } from 'mobx-react';

import { useRouteMatch } from 'react-router-dom';

import { Button } from 'components/Button';

import CustomTextInputField from 'containers/IncidentDetails/components/CustomTextInputField';
import { formatFullDate } from 'utils/formatters/time';
import { useStore } from 'utils/hooks/store';
import CustomModal from 'components/CustomModal';

import CustomDatePicker from '../CustomDatePicker';
import { DetailsRowContainer, StyledContainer, TitleText } from './styles';
import CustomUserDropdownField from '../CustomUserDropdownField';

const AddActionModal: React.FunctionComponent<{
  onClose: (created: boolean) => void;
}> = ({ onClose }): React.ReactElement => {
  const { incidentDetails } = useStore();

  const [name, setName] = React.useState('');
  const [assigned, setAssigned] = React.useState('');
  const [dueDate, setDueDate] = React.useState('');
  const [disableCreate, setDisableCreate] = React.useState(false);

  const match = useRouteMatch();

  const { incidentId } = match.params as { incidentId: string };

  const handleCreate = async (): Promise<void> => {
    setDisableCreate(true);
    const convert = dueDate ? formatFullDate((dueDate as unknown) as string) : '';
    const result = incidentDetails.createTaskRelatedToIncident(name, incidentId || null, assigned, convert || null);
    if (await result) {
      onClose(true);
      setDisableCreate(false);
    }
  };

  const date = dueDate ? new Date(dueDate) : null;

  return (
    <CustomModal
      openModal
      onClose={() => onClose(false)}
      modalTitle="Create New Task"
      cancelButton={
        <Button
          id="cancel-button"
          onClick={() => onClose(false)}
          variant="outlined"
          size="large"
          data-testid="cancelButton"
        >
          Cancel
        </Button>
      }
      submitButton={
        <Button
          id="create-action-button"
          onClick={handleCreate}
          disabled={name === '' || disableCreate}
          variant="contained"
          size="large"
          data-testid="addButton"
        >
          Create Task
        </Button>
      }
    >
      <DetailsRowContainer>
        <TitleText>Task Name</TitleText>
        <CustomTextInputField
          value={name}
          onChange={(e) => setName((e as unknown) as string)}
          description="Enter task name"
        />
      </DetailsRowContainer>

      <StyledContainer>
        <DetailsRowContainer>
          <TitleText>Assignee</TitleText>
          <CustomUserDropdownField
            value={assigned}
            onChange={(value) => {
              setAssigned(value ? (value as string).split(':')[1] : '');
            }}
            description="Assigned To"
          />
        </DetailsRowContainer>

        <DetailsRowContainer>
          <TitleText>Due Date</TitleText>
          <CustomDatePicker
            // @ts-ignore
            value={date || undefined}
            onChange={(val) => {
              const convert = val ? formatFullDate(val as string) : '';
              setDueDate(convert);
            }}
          />
        </DetailsRowContainer>
      </StyledContainer>
    </CustomModal>
  );
};

export default observer(AddActionModal);
