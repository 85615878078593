import { observer } from 'mobx-react';
import React from 'react';

import SignaturePad from 'react-signature-pad-wrapper';

import { Button } from 'components/Button';

import { useStore } from 'utils/hooks/store';

import { QuestionRowProps } from 'containers/FormsDetails/types';

import { EraserIcon } from 'components/Icons/EraserIcon';

import { AnswerImage, SignatureContainer } from './styles';

const SignatureAnswer: React.FunctionComponent<QuestionRowProps> = ({
  isSubmitted,
  answer,
  updateAnswer,
}): React.ReactElement => {
  const { notification } = useStore();
  const signaturePadRef = React.useRef<SignaturePad>(null);
  const [saveTimer, setSaveTimer] = React.useState<NodeJS.Timeout | null>(null);

  const save = (): void => {
    const signaturePad = signaturePadRef.current;
    if (signaturePad) {
      const dataURL = signaturePad.toDataURL();
      if (dataURL !== answer?.answer) {
        updateAnswer(answer?.questionId as string, dataURL);
      }
    }
  };

  const handleClear = (): void => {
    const signaturePad = signaturePadRef.current;
    if (signaturePad) {
      signaturePad.clear();
      if (saveTimer) {
        clearTimeout(saveTimer);
      }
      save();
    }
  };

  React.useEffect(() => {
    const signaturePadInstance = signaturePadRef.current?.instance;

    const loadExistingSignature = (): void => {
      if (signaturePadInstance && answer?.answer) {
        try {
          // Load the existing signature data from the data URL
          signaturePadInstance.fromDataURL(answer.answer as string);
        } catch (error) {
          notification.enqueueErrorSnackbar('Failed to load existing signature');
        }
      }
    };

    const handleEnd = (): void => {
      if (saveTimer) {
        clearTimeout(saveTimer);
      }
      const timer = setTimeout(save, 2000);
      setSaveTimer(timer);
    };

    if (signaturePadInstance) {
      signaturePadInstance.addEventListener('endStroke', handleEnd);
      loadExistingSignature();
    }

    return () => {
      if (saveTimer) {
        clearTimeout(saveTimer);
      }
      if (signaturePadInstance) {
        signaturePadInstance.removeEventListener('endStroke', handleEnd);
      }
    };
  }, [saveTimer]);

  if (!isSubmitted) {
    return (
      <>
        <SignatureContainer role="button" aria-label="signature pad" tabIndex={0} onClick={(e) => e.stopPropagation()}>
          <SignaturePad
            redrawOnResize
            ref={signaturePadRef}
            options={{ penColor: 'rgb(0, 0, 0)' }}
            height={120}
            canvasProps={{
              style: { width: '100%', height: '100%' },
            }}
          />
        </SignatureContainer>
        <Button
          startIcon={<EraserIcon />}
          sx={{ background: 'rgba(7, 114, 229, 0.1)' }}
          onClick={(e) => {
            e.stopPropagation();
            handleClear();
          }}
        >
          Clear
        </Button>
      </>
    );
  }

  return <AnswerImage alt="media answer" src={answer?.answer as string} />;
};

export default observer(SignatureAnswer);
