import styled from 'styled-components';

import vatixTheme from '../../theme/vatix';

export const StyledError = styled.p`
  background-color: ${vatixTheme.palette.error.main};
  color: ${vatixTheme.palette.common.white};
  font-size: 1.2rem;
  line-height: 1.4rem;
  font-weight: bold;
`;
