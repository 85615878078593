import styled from 'styled-components';

import { center } from '../../core/styles';

export const CenteredContainer = styled.div`
  ${center};
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
`;
