import React from 'react';
import { observer } from 'mobx-react';

import GroupsIcon from '@mui/icons-material/Groups';

import { Button } from 'components/Button';

import CustomModal from 'components/CustomModal';

import Logger from 'utils/logger';

import { useStore } from 'utils/hooks/store';

import UploadCSVStep from './components/UploadCSVStep';
import SumUpStep from './components/SumUpStep';

import SuccessModal from './components/SuccessModal/SuccessModal';

const AddMultipleUsers: React.FC<{ onCloseRefresh: () => void }> = ({ onCloseRefresh }): React.ReactElement => {
  const { userList } = useStore();
  const [openModal, setOpenModal] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [sumUpStep, setSumUpStep] = React.useState(false);

  const onClose = (): void => {
    setOpenModal(false);
    setSumUpStep(false);
    onCloseRefresh();
    userList.clearUsersToUpload();
  };

  const onSubmit = async (): Promise<void> => {
    setIsSubmitting(true);
    try {
      await userList.createUsersInBatches();
    } catch (e) {
      Logger.error(`Invalid create users in bulk API result.`, e);
    }

    // if there are any errors move to first step and display button for downloading the errors
    if (userList.uploadErrors.length > 0) {
      setSumUpStep(false);
    }
    setIsSubmitting(false);
  };

  const onOpenModal = (): void => setOpenModal(true);

  const onSumUp = (): void => setSumUpStep(true);

  const tryAgain = (): void => {
    userList.clearUsersToUpload();
    setSumUpStep(false);
  };

  const renderModalContent = (): JSX.Element => {
    if (userList.uploadedAllUsers) {
      return <SuccessModal onClose={onClose} />;
    }

    return sumUpStep ? (
      <SumUpStep isSubmitting={isSubmitting} onSubmit={onSubmit} setSumUpStep={setSumUpStep} />
    ) : (
      <UploadCSVStep isSubmitting={isSubmitting} onClose={onClose} onSumUp={onSumUp} tryAgain={tryAgain} />
    );
  };

  return (
    <>
      <Button variant="outlined" color="primary" onClick={onOpenModal} size="large">
        <GroupsIcon />
        <p style={{ margin: 0, marginLeft: '8px' }}>Add Multiple Users</p>
      </Button>
      <CustomModal openModal={openModal} modalTitle="Add Multiple Users" onClose={onClose}>
        {renderModalContent()}
      </CustomModal>
    </>
  );
};

export default observer(AddMultipleUsers);
