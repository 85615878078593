import React from 'react';

import Record from 'vatix-ui/lib/containers/Record/Record';

import DetailsLoader from 'vatix-ui/lib/components/DetailsLoader';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { DefaultFeedMapping } from 'vatix-ui/lib/components/Feed/Feed';

import { observer } from 'mobx-react';

import { CenteredCircularProgress } from 'components/Progress';

import { formatDateTime } from 'utils/formatters/time';

import NotFound from 'containers/Error/Pages/NotFound/NotFound';

import DataError from 'containers/Error/DataError';

import { isNotFound } from 'utils/api/errors';

import { useNavBarHeight } from 'utils/hooks/navbar';
import { useStore } from 'utils/hooks/store';

import EntityDetailsContent from 'components/Entities/EntityDetailsContent';

import EntityDetailsHeader from 'components/Entities/EntityDetailsHeader';
import EntityDetailsActivities from 'components/Entities/EntityDetailsActivities';
import EntityDetailsActivitiesFooter from 'components/Entities/EntityDetailsActivities/EntityDetailsActivitiesFooter';

import { ActivityMapping, UuidableName } from 'utils/api/types';
import UserField from 'containers/IncidentDetails/components/UserField';
import { ActivityType } from 'utils/stores/Activities/constants';

import { EntityDropdownFieldProps } from 'containers/IncidentDetails/components/types';

import EntityRelatedActionsCell from 'containers/IncidentDetails/components/EntityRelatedActionsCell';

import { UserRole } from 'core/constants';

import EntitySubmissionTab from 'components/Entities/EntitySubmissionTab/EntitySubmissionTab';

import { UserOverview } from 'components/Entities/EntityDetailsContent/styles';

import routes from 'core/routes';

import EventState from './EventActivities/EventState';
import EventName from './EventActivities/EventName';
import EventOwner from './EventActivities/EventOwner';

const activityTypeToComponent = ({
  ...DefaultFeedMapping,
  [ActivityType.EventState]: EventState,
  [ActivityType.EventOwnerChange]: EventOwner,
  [ActivityType.EventNameChange]: EventName,
} as unknown) as ActivityMapping;

const EventDetails: React.FunctionComponent = (): React.ReactElement => {
  const navBarHeight = useNavBarHeight();
  const match = useRouteMatch<{ entityId: string }>();

  const { eventDetails, session } = useStore();

  const userRole = session.user?.role as UserRole;

  const history = useHistory();

  React.useEffect(() => {
    // redirect to events list when going back
    window.addEventListener('popstate', () => {
      history.push(routes.dashboard.events.toString());
    });
  }, []);

  return (
    <DetailsLoader
      match={match}
      routeParam="entityId"
      // @ts-ignore
      store={eventDetails}
    >
      {({ isLoaded, error, reload }): React.ReactElement => {
        if (!isLoaded) {
          return <CenteredCircularProgress />;
        }
        if (eventDetails.details === undefined) {
          return <NotFound />;
        }
        if (error) {
          if (isNotFound(error)) {
            return <NotFound />;
          }
          return <DataError onReload={reload} />;
        }

        const {
          prefix,
          details: { entity },
        } = eventDetails;

        const eventReporter = entity[`${prefix}eventReporter`];
        const eventOwner = entity[`${prefix}eventOwner`];
        const eventName = entity[`${prefix}eventName`];
        const eventType = entity[`${prefix}eventType`] as EntityDropdownFieldProps;

        return (
          <>
            {userRole !== UserRole.User ? (
              <Record
                navBarHeight={navBarHeight}
                Header={
                  <EntityDetailsHeader
                    store={eventDetails}
                    title={eventName.value as string}
                    columns={[
                      {
                        name: 'Reporter',
                        value: eventReporter.value ? (
                          <UserField userId={(eventReporter.value as UuidableName).uuid} />
                        ) : (
                          <p>N/A</p>
                        ),
                      },
                      {
                        name: 'Created',
                        value: <p>{formatDateTime(eventDetails.details.createdAt)}</p>,
                      },
                      {
                        name: 'Type',
                        value: eventType.value ? <p>{eventType.value[0].value}</p> : <p>N/A</p>,
                      },
                      {
                        name: 'Owner',
                        value: eventOwner.value ? (
                          <UserField userId={(eventOwner.value as UuidableName).uuid} />
                        ) : (
                          <p>N/A</p>
                        ),
                      },
                      {
                        name: 'Related Actions',
                        value: (
                          <EntityRelatedActionsCell
                            relatedActions={eventDetails.details.relatedActions || { total: 0, completed: 0 }}
                          />
                        ),
                      },
                    ]}
                  />
                }
                Content={(onError) => <EntityDetailsContent onError={onError} store={eventDetails} />}
                Activities={{
                  Content: (
                    <EntityDetailsActivities
                      activities={eventDetails.activities}
                      activityTypeToComponent={activityTypeToComponent}
                    />
                  ),
                  Footer: <EntityDetailsActivitiesFooter activities={eventDetails.activities} />,
                }}
              />
            ) : (
              <Record
                Header={<EntityDetailsHeader store={eventDetails} title={eventName.value as string} />}
                Content={() => (
                  <UserOverview>
                    <EntitySubmissionTab store={eventDetails} />
                  </UserOverview>
                )}
                navBarHeight={navBarHeight}
              />
            )}
          </>
        );
      }}
    </DetailsLoader>
  );
};

export default observer(EventDetails);
