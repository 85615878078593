import React from 'react';

import AttachFileIcon from '@mui/icons-material/AttachFile';

import { Typography } from '@mui/material';

import {
  ActivityResponse,
  FileActivityResponse,
  FileUploadProgressResponse,
  ActivityType,
} from 'vatix-ui/lib/utils/api/types';

import { observer } from 'mobx-react';

import { humanFileSize } from 'utils/formatters/file';

import ConfirmCheck from 'components/Icons/ConfirmCheck';

import { useStore } from 'utils/hooks/store';

import {
  Modal,
  SimpleModal,
  StyledModalTitle,
  GreenHeader,
  InfoText,
  Title,
  FileUploadContainer,
  FileName,
  FileSize,
  StyledLinearProgress,
} from './styles';

const SimpleSubmissionModal: React.FunctionComponent<{ open: boolean }> = ({ open }): React.ReactElement => (
  <SimpleModal
    aria-labelledby="create-action-dialog"
    open={open}
    maxWidth="md"
    fullWidth
    PaperProps={{
      square: true,
    }}
  >
    <ConfirmCheck />
    <StyledModalTitle>Incident has been successfully submitted!</StyledModalTitle>
  </SimpleModal>
);

function isFile(file: ActivityResponse): file is FileActivityResponse {
  return (file as FileActivityResponse).filename !== undefined;
}

const FileUpload: React.FC<{ activity: ActivityResponse }> = ({ activity }) => {
  let size: number;
  let name: string;
  let loaded = 0;
  if (isFile(activity)) {
    size = activity.size;
    name = activity.filename;
  } else {
    const fileUploadProgress = activity as FileUploadProgressResponse;
    size = fileUploadProgress.size;
    name = fileUploadProgress.name;
    loaded = fileUploadProgress.loaded;
  }

  const progressValue = (loaded / size) * 100;

  return (
    <FileUploadContainer>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <AttachFileIcon sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
        <div>
          <FileName>{name}</FileName>
          <FileSize>{humanFileSize(size)}</FileSize>
        </div>
      </div>
      <div style={{ width: '100%', paddingTop: '9px' }}>
        <StyledLinearProgress variant="determinate" value={progressValue} $completed={progressValue >= 100} />
        <Typography variant="body2" color="text.secondary">
          {`${Math.round(progressValue)}%`}
        </Typography>
      </div>
    </FileUploadContainer>
  );
};

const SubmittedIncidentModal: React.FunctionComponent<{ open: boolean; flowWithFile: boolean }> = ({
  open,
  flowWithFile,
}): React.ReactElement => {
  if (flowWithFile) {
    const {
      incidentDetails: { submissionActivities },
    } = useStore();

    return (
      <Modal
        aria-labelledby="create-action-dialog"
        open={open}
        maxWidth="md"
        fullWidth
        PaperProps={{
          square: true,
        }}
      >
        <GreenHeader>Incident has been successfully submitted!</GreenHeader>

        {submissionActivities?.finishedUploading ? null : (
          <InfoText>Please don’t close this window until all files are uploaded</InfoText>
        )}

        <Title>Submitted files</Title>
        {submissionActivities?.activities
          ?.filter((i) => i.type !== ActivityType.Message)
          .map((i) => (
            <FileUpload activity={i} />
          ))}
      </Modal>
    );
  }
  return <SimpleSubmissionModal open={open} />;
};

export default observer(SubmittedIncidentModal);
