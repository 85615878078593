import React from 'react';

import Record from 'vatix-ui/lib/containers/Record/Record';

import { MainHeader } from 'components/MainHeader/MainHeader';

import { useNavBarHeight } from 'utils/hooks/navbar';

import routes from 'core/routes';

import ActivityTabs from './components/ActivityTabs';
import UserActivityMap from './components/UserActivityMap';

const ActivityMap: React.FunctionComponent = (): React.ReactElement => {
  const navBarHeight = useNavBarHeight();

  return (
    <Record
      disableMaxContentHeight
      navBarHeight={navBarHeight}
      Content={() => (
        <>
          <ActivityTabs />
          <UserActivityMap />
        </>
      )}
      Header={
        <MainHeader
          title="Live Map"
          breadcrumbs={[
            { label: 'Lone Working', link: routes.dashboard.alarms.red.toString() },
            { label: 'Live Map', link: routes.dashboard.alarms.activityMap.toString() },
          ]}
        />
      }
    />
  );
};

export default ActivityMap;
