import { action, observable, runInAction, makeObservable } from 'mobx';
import { eventBus, subscribe } from 'mobx-event-bus2';
import { AxiosError } from 'axios';

import { reverse } from 'named-urls';

import Logger from 'utils/logger';

import API from 'utils/api';
import { EventType } from 'utils/events/constants';
import { EntityValueType, EntityDetailsType, AnswerTypes, EntityFormType, SchemaContent } from 'utils/api/types';

import { isNotFound } from 'utils/api/errors';

import { EntityModules } from 'core/constants';
import { postMessage } from 'utils/events/broadcast';

import routes from 'core/routes';

import EntitiesActivities from 'stores/EntitiesActivities';

import { PublicSubmissionUserDetails } from 'stores/EntitiesFormSubmission/EntitiesFormSubmission';

import RootStore from '../Root';
import EntityDetails from '../EntityDetails/EntityDetails';
import ReporterActivities from './ReporterActivities';

type EventDetailsType = EntityDetailsType & {
  submission: {
    form: EntityFormType;
    content: Record<string, AnswerTypes>;
    stack: string[];
    publicSubmissionDetails?: PublicSubmissionUserDetails;
  };
  legacySubmission: SchemaContent | null;
};

export default class EventDetailsStore extends EntityDetails {
  @observable error?: AxiosError;

  @observable details?: EventDetailsType;

  @observable reporterActivities?: ReporterActivities;

  constructor(rootStore: RootStore, api: typeof API) {
    super(rootStore, api, EntityModules.Events, routes.dashboard.events.toString(), (uuid: string): string =>
      reverse(routes.dashboard.events.details, { entityId: uuid })
    );

    makeObservable(this);

    eventBus.register(this);
  }

  @action.bound
  async loadDetails(eventId: string): Promise<void> {
    this.isLoaded = false;
    try {
      const { data } = await this.api.loadEntitiesDetails<EventDetailsType>(this.entityType, eventId)();
      runInAction(() => {
        this.activities = new EntitiesActivities(this.store, this.api, eventId, this.entityType);
        this.reporterActivities = new ReporterActivities(this.store, this.api, eventId);
        this.error = undefined;
        this.details = data;
      });
    } catch (e) {
      runInAction(() => {
        // @ts-ignore
        this.error = e;
      });
      // @ts-ignore
      if (!isNotFound(e)) {
        Logger.error(`Invalid load event details API response. Task id: ${eventId}`, e);
      }
    } finally {
      runInAction(() => {
        this.isLoaded = true;
      });
    }
  }

  create = async (eventName: string): Promise<boolean> => {
    try {
      await this.api.createEntity(this.entityType, { [`${this.prefix}eventName`]: eventName })();
      return true;
    } catch (e) {
      Logger.error('Failed to create event', e);

      return false;
    }
  };

  delete = async (eventId: string): Promise<boolean> => {
    try {
      await this.api.deleteEntity(this.entityType, eventId)();
    } catch (e) {
      Logger.error('Failed to delete event', e);
      return false;
    }
    return true;
  };

  edit = async (data: Record<string, EntityValueType>): Promise<boolean> => {
    if (this.details?.uuid === undefined) {
      return false;
    }
    try {
      const edited = await this.api.editEntity<EventDetailsType>(this.entityType, this.details?.uuid, data)();
      this.details = edited.data;
      postMessage(EventType.UpdatedEvent, { uuid: edited.data.uuid });
      this.store.notification.enqueueSuccessSnackbar('Event edited successfully');
      return true;
    } catch (e) {
      const error = e as AxiosError;
      this.store.notification.enqueueErrorSnackbar(
        error.response?.data[0] || `The event could not be updated. Something went wrong.`
      );
      Logger.error('Failed to edit event', e);
      return false;
    }
  };

  @subscribe(EventType.LoggedOut)
  @action
  reset(): void {
    this.isLoaded = false;
    this.error = undefined;
    this.details = undefined;
  }

  @action.bound
  async loadCount(): Promise<number> {
    this.isLoaded = false;
    try {
      const { data } = await this.api.loadEntitiesCount(EntityModules.Events)();
      runInAction(() => {
        this.error = undefined;
      });
      return data.count;
    } catch (e) {
      runInAction(() => {
        // @ts-ignore
        this.error = e;
      });
      return 0;
    } finally {
      runInAction(() => {
        this.isLoaded = true;
      });
    }
  }
}
