import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import { reverse } from 'named-urls';

import NotFound from 'containers/Error/Pages/NotFound/NotFound';

import { EntityModules, UserRole } from 'core/constants';
import routes from 'core/routes';
import { SessionStoreWithUser } from 'stores/Session/types';

import UserRoute from 'components/Route/UserRoute';
import Incidents from 'containers/Incidents';
import ActivityMap from 'containers/ActivityMap';
import Devices from 'containers/Devices';
import UserDetails from 'containers/UserDetails';
import DeviceDetails from 'containers/DeviceDetails';
import Users from 'containers/Users';
import AlarmsConfigure from 'containers/AlarmsConfigure';
import IncidentDetails from 'containers/IncidentDetails';
import AlarmDetails from 'containers/AlarmDetails';
import Manage from 'containers/Manage';
import Analytics from 'containers/Analytics';

import UsageReport from 'containers/Reports/components/UsageReport';

import TaskDetails from 'containers/TaskDetails';

import Tasks from 'containers/Tasks';
import MyTasks from 'containers/Tasks/components/MyTasks';
import ReportIncident from 'containers/Incidents/components/ReportIncident';

import Notifications from 'containers/Notifications';

import DisableFeatures from 'containers/DisableFeatures/DisableFeatures';

import { AmberAlarms, RedAlarms } from 'containers/Alarms/Alarms';

import Teams from 'containers/Admin/Teams';

import MainAnalytics from 'containers/Analytics/components/MainAnalytics';
import Home from 'containers/Home';

import Licenses from 'containers/Licenses';

import TeamDetails from 'containers/Admin/TeamDetails';

import ProfileSecuritySettings from 'containers/ProfileSecuritySettings';
import ProfileNotificationsSettings from 'containers/ProfileNotificationsSettings';
import NotificationSettingsStore from 'stores/NotificationSettings/NotificationSettings';

import Forms from 'containers/Forms';

import FormsDetails from 'containers/FormsDetails';

import FormTemplates from 'containers/FormTemplates';

import CompanySettings from 'containers/CompanySettings';

import MoreApps from 'containers/MoreApps';

import TemplateDetails from 'containers/TemplateDetails';

import Sites from 'containers/Sites';
import LayoutEditor from 'containers/LayoutEditor';

import SiteDetails from 'containers/SiteDetails';
import FieldManager from 'containers/FieldManager';

import Assets from 'containers/Assets';

import AssetsDetails from 'containers/AssetsDetails/AssetsDetails';

import RelatedIncidents from 'components/Entities/EntityRelatedPage/RelatedIncidents';

import RelatedActions from 'components/Entities/EntityRelatedPage/RelatedActions';

import RelatedAudits from 'components/Entities/EntityRelatedPage/RelatedAudits';

import Suppliers from 'containers/Suppliers';

import SuppliersDetails from 'containers/SuppliersDetails';

import RelatedEntities from 'components/Entities/EntityRelatedPage/RelatedEntities';
import EntitiesFormBuilder from 'containers/EntitiesFormBuilder';

import EventForms from 'containers/EventForms';

import Events from 'containers/Events';

import EventDetails from 'containers/EventDetails';

import EntitiesFormSubmission from 'containers/EntitiesFormSubmission';

import { ADMIN_AND_MANAGERS, InjectedRoutingProps, NON_ARC_OPERATORS, NON_USER } from './types';
import { RoutingContainer } from './styles';

const Routing = ({ session, isDetailsView }: InjectedRoutingProps): React.ReactElement => (
  <RoutingContainer $isDetailsView={isDetailsView} id="routing-container">
    <Switch>
      {/* Home */}
      <Route path={String(routes.dashboard.home)} component={Home} exact data-test-id="dashboard-redirect" />

      {/* Inbox */}
      <UserRoute
        path={String(routes.dashboard.notifications)}
        component={Notifications}
        isAuthorizedUser={() => session.user.hasFunctionalModuleLicense()}
        exact
      />

      {/* Actions */}
      <UserRoute
        path={String(routes.dashboard.tasks.all)}
        component={Tasks}
        isAuthorizedUser={(user) => NON_ARC_OPERATORS.includes(user.role)}
        exact
      />
      <UserRoute
        path={String(routes.dashboard.tasks.my)}
        component={MyTasks}
        isAuthorizedUser={(user) => NON_ARC_OPERATORS.includes(user.role)}
        exact
      />
      <UserRoute
        path={routes.dashboard.tasks.details}
        component={TaskDetails}
        isAuthorizedUser={(user) =>
          NON_ARC_OPERATORS.includes(user.role) && user.hasFunctionalModuleLicense()
        }
        exact
      />

      {/* Incidents */}
      <UserRoute
        path={routes.dashboard.incidents.report}
        component={ReportIncident}
        isAuthorizedUser={(user) => NON_ARC_OPERATORS.includes(user.role) && session.user.licenses.protectorIncidents}
        exact
      />
      <UserRoute
        path={String(routes.dashboard.incidents)}
        component={Incidents}
        isAuthorizedUser={(user) => NON_ARC_OPERATORS.includes(user.role) && session.user.licenses.protectorIncidents}
        onUnauthorizedRedirectProducts
        exact
      />
      <UserRoute
        path={routes.dashboard.incidents.details}
        component={IncidentDetails}
        isAuthorizedUser={(user) => NON_ARC_OPERATORS.includes(user.role) && session.user.licenses.protectorIncidents}
        exact
      />

      {/* Lone working */}
      <UserRoute
        path={String(routes.dashboard.alarms.escalationProfiles)}
        component={AlarmsConfigure}
        isAuthorizedUser={(user) => [UserRole.Admin, UserRole.AccountManager, UserRole.Manager].includes(user.role)}
      />
      <UserRoute
        path={routes.dashboard.alarms.activityMap}
        component={ActivityMap}
        exact
        isAuthorizedUser={(user) =>
          user.licenses.protectorAlarms &&
          (user.organisation.activityMap || [UserRole.ArcOperator, UserRole.Admin].includes(user.role))
        }
        onUnauthorizedRedirectProducts
      />
      <UserRoute path={String(routes.dashboard.alarms)} isAuthorizedUser={(user) => NON_USER.includes(user.role)} exact>
        <Redirect to={routes.dashboard.alarms.red} exact />
      </UserRoute>
      <UserRoute
        path={String(routes.dashboard.alarms.red)}
        component={RedAlarms}
        isAuthorizedUser={(user) => NON_USER.includes(user.role)}
        exact
      />
      <UserRoute
        path={String(routes.dashboard.alarms.amber)}
        component={AmberAlarms}
        isAuthorizedUser={(user) => NON_USER.includes(user.role)}
        exact
      />
      <UserRoute
        path={String(routes.dashboard.alarms.manage)}
        component={Manage}
        isAuthorizedUser={(user) => ADMIN_AND_MANAGERS.includes(user.role)}
        exact
      />
      <UserRoute
        path={String(routes.dashboard.alarms.devices)}
        component={Devices}
        isAuthorizedUser={(user) => ADMIN_AND_MANAGERS.includes(user.role)}
        exact
      />
      <UserRoute
        path={routes.dashboard.alarms.details}
        component={AlarmDetails}
        isAuthorizedUser={(user) => NON_USER.includes(user.role)}
        exact
      />
      <UserRoute
        path={routes.dashboard.alarms.devices.details}
        component={DeviceDetails}
        isAuthorizedUser={(user) => ADMIN_AND_MANAGERS.includes(user.role)}
        exact
      />
      <Route
        path={String(routes.dashboard.alarms.reports)}
        component={UsageReport}
        data-test-id="reports-device-route"
        exact
      />

      {/* Audits */}

      <UserRoute path={String(routes.dashboard.audits)} isAuthorizedUser={(user) => NON_USER.includes(user.role)} exact>
        <Redirect to={routes.dashboard.audits.forms.toString()} exact />
      </UserRoute>
      <UserRoute
        path={String(routes.dashboard.audits.forms)}
        component={Forms}
        data-test-id="forms-route"
        exact
        isAuthorizedUser={(user) => user.licenses.workflows}
      />
      <UserRoute
        path={String(routes.dashboard.audits.forms.details)}
        component={FormsDetails}
        data-test-id="forms-details-route"
        exact
        isAuthorizedUser={(user) => user.licenses.workflows}
      />
      <UserRoute
        path={String(routes.dashboard.audits.templates)}
        component={FormTemplates}
        data-test-id="forms-template-route"
        exact
        isAuthorizedUser={(user) => user.licenses.workflows && user.role !== UserRole.User}
      />
      <UserRoute
        path={String(routes.dashboard.audits.templates.details)}
        component={TemplateDetails}
        isAuthorizedUser={(user) => user.licenses.workflows && user.role !== UserRole.User}
        exact
      />

      {/* Admin */}

      <UserRoute
        path={String(routes.dashboard.admin.companySettings)}
        component={CompanySettings}
        isAuthorizedUser={(user) => [UserRole.Admin, UserRole.AccountManager].includes(user.role)}
        exact
      />

      <UserRoute
        path={String(routes.dashboard.admin.users)}
        component={Users}
        isAuthorizedUser={(user) => [UserRole.Admin, UserRole.AccountManager].includes(user.role)}
        exact
      />

      <UserRoute
        path={routes.dashboard.admin.users.details}
        isAuthorizedUser={(user) =>
          [UserRole.Admin, UserRole.Manager, UserRole.User, UserRole.ArcOperator, UserRole.AccountManager].includes(
            user.role
          )
        }
        component={UserDetails}
        exact
      />
      <UserRoute
        path={routes.dashboard.admin.licenses}
        component={Licenses}
        isAuthorizedUser={(user) => [UserRole.Admin, UserRole.AccountManager].includes(user.role)}
        exact
      />

      <UserRoute
        path={String(routes.dashboard.admin.teams)}
        component={Teams}
        data-test-id="teams-route"
        exact
        isAuthorizedUser={(user) => [UserRole.Admin, UserRole.AccountManager].includes(user.role)}
      />

      <Route path={String(routes.dashboard.admin.teams.new)} component={TeamDetails} data-test-id="teams-route" exact />

      <Route
        path={String(routes.dashboard.admin.teams.details)}
        component={TeamDetails}
        data-test-id="teams-route"
        exact
      />

      {/* ============ */}
      <UserRoute
        path={routes.dashboard.disableFeature.details.toString()}
        component={DisableFeatures}
        isAuthorizedUser={(user) => NON_ARC_OPERATORS.includes(user.role)}
        exact
      />

      <UserRoute
        path={String(routes.dashboard.analytics)}
        component={MainAnalytics}
        isAuthorizedUser={(user) => ADMIN_AND_MANAGERS.includes(user.role)}
        exact
      />

      <UserRoute
        path={routes.dashboard.analytics.details}
        component={Analytics}
        isAuthorizedUser={(user) => ADMIN_AND_MANAGERS.includes(user.role)}
        exact
      />

      <UserRoute
        path={routes.dashboard.profileSettings.notifications.toString()}
        component={ProfileNotificationsSettings}
        isAuthorizedUser={(user) => user.hasFunctionalModuleLicense()}
        exact
      />

      <UserRoute
        path={routes.dashboard.profileSettings.security.toString()}
        component={ProfileSecuritySettings}
        isAuthorizedUser={(user) => user !== undefined && user !== null}
        exact
      />

      <UserRoute
        path={String(routes.dashboard.moreApps)}
        component={MoreApps}
        data-test-id="more-apps"
        exact
        isAuthorizedUser={(user) => user !== undefined && user !== null}
      />

      {/* Sites */}
      <UserRoute
        path={String(routes.dashboard.sites)}
        component={Sites}
        data-test-id="sites"
        exact
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Sites)}
      />

      <UserRoute
        path={routes.dashboard.sites.details}
        component={SiteDetails}
        data-test-id="sites-details"
        exact
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Sites)}
      />

      <UserRoute
        path={reverse(routes.dashboard.objectManager.details.layoutEditor, { moduleName: EntityModules.Sites })}
        component={LayoutEditor}
        data-test-id={`layout-editor-${EntityModules.Sites}`}
        exact
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Sites) && user.showObjectManager}
      />

      <UserRoute
        path={reverse(routes.dashboard.objectManager.details.fieldManager, { moduleName: EntityModules.Sites })}
        component={FieldManager}
        data-test-id={`field-manager-${EntityModules.Sites}`}
        exact
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Sites) && user.showObjectManager}
      />

      {/* related incidents to entities */}
      <UserRoute
        path={routes.dashboard.sites.relatedIncidents}
        component={RelatedIncidents}
        data-test-id="sites-related-incidents"
        exact
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Sites)}
      />

      <UserRoute
        path={routes.dashboard.assets.relatedIncidents}
        component={RelatedIncidents}
        data-test-id="assets-related-incidents"
        exact
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Assets)}
      />

      <UserRoute
        path={routes.dashboard.suppliers.relatedIncidents}
        component={RelatedIncidents}
        data-test-id="suppliers-related-incidents"
        exact
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Suppliers)}
      />

      <UserRoute
        path={routes.dashboard.events.relatedIncidents}
        component={RelatedIncidents}
        data-test-id="events-related-incidents"
        exact
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Events)}
      />

      {/* related actions to entities */}
      <UserRoute
        path={routes.dashboard.sites.relatedActions}
        component={RelatedActions}
        data-test-id="sites-related-actions"
        exact
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Sites)}
      />

      <UserRoute
        path={routes.dashboard.assets.relatedActions}
        component={RelatedActions}
        data-test-id="assets-related-actions"
        exact
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Assets)}
      />

      <UserRoute
        path={routes.dashboard.suppliers.relatedActions}
        component={RelatedActions}
        data-test-id="suppliers-related-actions"
        exact
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Suppliers)}
      />

      <UserRoute
        path={routes.dashboard.events.relatedActions}
        component={RelatedActions}
        data-test-id="events-related-actions"
        exact
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Events)}
      />

      {/* related audits to entities */}
      <UserRoute
        path={routes.dashboard.sites.relatedAudits}
        component={RelatedAudits}
        data-test-id="sites-related-audits"
        exact
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Sites) && user.licenses.workflows}
      />

      <UserRoute
        path={routes.dashboard.assets.relatedAudits}
        component={RelatedAudits}
        data-test-id="assets-related-audits"
        exact
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Assets) && user.licenses.workflows}
      />

      <UserRoute
        path={routes.dashboard.suppliers.relatedAudits}
        component={RelatedAudits}
        data-test-id="suppliers-related-audits"
        exact
        isAuthorizedUser={(user) =>
          user.activeEntityModules.includes(EntityModules.Suppliers) && user.licenses.workflows
        }
      />

      <UserRoute
        path={routes.dashboard.events.relatedAudits}
        component={RelatedAudits}
        data-test-id="events-related-audits"
        exact
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Events) && user.licenses.workflows}
      />

      {/* related sites to entities */}
      <UserRoute
        path={routes.dashboard.sites.relatedSites}
        component={RelatedEntities}
        data-test-id="sites-related-sites"
        exact
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Sites)}
      />

      <UserRoute
        path={routes.dashboard.assets.relatedSites}
        component={RelatedEntities}
        data-test-id="assets-related-sites"
        exact
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Assets)}
      />

      <UserRoute
        path={routes.dashboard.suppliers.relatedSites}
        component={RelatedEntities}
        data-test-id="suppliers-related-sites"
        exact
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Suppliers)}
      />

      <UserRoute
        path={routes.dashboard.events.relatedSites}
        component={RelatedEntities}
        data-test-id="events-related-sites"
        exact
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Events)}
      />

      {/* related assets to entities */}
      <UserRoute
        path={routes.dashboard.sites.relatedAssets}
        component={RelatedEntities}
        data-test-id="sites-related-assets"
        exact
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Sites)}
      />

      <UserRoute
        path={routes.dashboard.assets.relatedAssets}
        component={RelatedEntities}
        data-test-id="assets-related-assets"
        exact
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Assets)}
      />

      <UserRoute
        path={routes.dashboard.suppliers.relatedAssets}
        component={RelatedEntities}
        data-test-id="suppliers-related-assets"
        exact
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Suppliers)}
      />

      <UserRoute
        path={routes.dashboard.events.relatedAssets}
        component={RelatedEntities}
        data-test-id="events-related-assets"
        exact
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Events)}
      />

      {/* related events to entities */}
      <UserRoute
        path={routes.dashboard.sites.relatedEvents}
        component={RelatedEntities}
        data-test-id="sites-related-events"
        exact
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Sites)}
      />

      <UserRoute
        path={routes.dashboard.assets.relatedEvents}
        component={RelatedEntities}
        data-test-id="assets-related-events"
        exact
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Assets)}
      />

      <UserRoute
        path={routes.dashboard.suppliers.relatedEvents}
        component={RelatedEntities}
        data-test-id="suppliers-related-events"
        exact
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Suppliers)}
      />

      <UserRoute
        path={routes.dashboard.events.relatedEvents}
        component={RelatedEntities}
        data-test-id="events-related-events"
        exact
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Events)}
      />

      {/* related suppliers to entities */}
      <UserRoute
        path={routes.dashboard.sites.relatedSuppliers}
        component={RelatedEntities}
        data-test-id="sites-related-suppliers"
        exact
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Sites)}
      />

      <UserRoute
        path={routes.dashboard.assets.relatedSuppliers}
        component={RelatedEntities}
        data-test-id="assets-related-suppliers"
        exact
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Assets)}
      />

      <UserRoute
        path={routes.dashboard.suppliers.relatedSuppliers}
        component={RelatedEntities}
        data-test-id="suppliers-related-suppliers"
        exact
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Suppliers)}
      />

      <UserRoute
        path={routes.dashboard.events.relatedSuppliers}
        component={RelatedEntities}
        data-test-id="events-related-suppliers"
        exact
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Events)}
      />

      {/* Assets */}
      <UserRoute
        path={String(routes.dashboard.assets)}
        component={Assets}
        data-test-id="assets"
        exact
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Assets)}
      />

      <UserRoute
        path={routes.dashboard.assets.details}
        component={AssetsDetails}
        data-test-id="assets-details"
        exact
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Assets)}
      />

      <UserRoute
        path={reverse(routes.dashboard.objectManager.details.layoutEditor, { moduleName: EntityModules.Assets })}
        component={LayoutEditor}
        data-test-id={`layout-editor-${EntityModules.Assets}`}
        exact
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Assets) && user.showObjectManager}
      />

      <UserRoute
        path={reverse(routes.dashboard.objectManager.details.fieldManager, { moduleName: EntityModules.Assets })}
        component={FieldManager}
        data-test-id={`field-manager-${EntityModules.Assets}`}
        exact
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Assets) && user.showObjectManager}
      />

      {/* suppliers */}

      <UserRoute
        path={String(routes.dashboard.suppliers)}
        component={Suppliers}
        data-test-id="suppliers"
        exact
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Suppliers)}
      />

      <UserRoute
        path={routes.dashboard.suppliers.details}
        component={SuppliersDetails}
        data-test-id="suppliers-details"
        exact
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Suppliers)}
      />

      <UserRoute
        path={reverse(routes.dashboard.objectManager.details.layoutEditor, { moduleName: EntityModules.Suppliers })}
        component={LayoutEditor}
        data-test-id={`layout-editor-${EntityModules.Suppliers}`}
        exact
        isAuthorizedUser={(user) =>
          user.activeEntityModules.includes(EntityModules.Suppliers) && user.showObjectManager
        }
      />

      <UserRoute
        path={reverse(routes.dashboard.objectManager.details.fieldManager, { moduleName: EntityModules.Suppliers })}
        component={FieldManager}
        data-test-id={`field-manager-${EntityModules.Suppliers}`}
        exact
        isAuthorizedUser={(user) =>
          user.activeEntityModules.includes(EntityModules.Suppliers) && user.showObjectManager
        }
      />

      {/* risks */}
      <UserRoute
        path={reverse(routes.dashboard.objectManager.details.layoutEditor, { moduleName: EntityModules.Risks })}
        component={LayoutEditor}
        data-test-id={`layout-editor-${EntityModules.Risks}`}
        exact
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Risks) && user.showObjectManager}
      />

      <UserRoute
        path={reverse(routes.dashboard.objectManager.details.fieldManager, { moduleName: EntityModules.Risks })}
        component={FieldManager}
        data-test-id={`field-manager-${EntityModules.Risks}`}
        exact
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Risks) && user.showObjectManager}
      />
      {/* events */}
      <UserRoute
        path={String(routes.dashboard.events)}
        component={Events}
        data-test-id="events"
        exact
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Events)}
      />

      <UserRoute
        path={routes.dashboard.events.details}
        component={EventDetails}
        data-test-id="event-details"
        exact
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Events)}
      />

      <UserRoute
        path={reverse(routes.dashboard.objectManager.details.layoutEditor, { moduleName: EntityModules.Events })}
        component={LayoutEditor}
        data-test-id={`layout-editor-${EntityModules.Events}`}
        exact
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Events) && user.showObjectManager}
      />

      <UserRoute
        path={reverse(routes.dashboard.objectManager.details.fieldManager, { moduleName: EntityModules.Events })}
        component={FieldManager}
        data-test-id={`field-manager-${EntityModules.Events}`}
        exact
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Events) && user.showObjectManager}
      />

      <UserRoute
        path={routes.dashboard.objectManager.details.formBuilder.details}
        component={EntitiesFormBuilder}
        data-test-id={`field-manager-${EntityModules.Events}`}
        exact
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Events) && user.showObjectManager}
      />
      <UserRoute
        exact
        component={EventForms}
        data-test-id={`field-manager-${EntityModules.Events}`}
        path={reverse(routes.dashboard.objectManager.details.formBuilder.toString(), {
          moduleName: EntityModules.Events,
        })}
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Events) && user.showObjectManager}
      />

      <UserRoute
        exact
        component={EntitiesFormSubmission}
        data-test-id="event-draft-submission"
        path={routes.dashboard.events.draft.details}
        isAuthorizedUser={(user) => user.activeEntityModules.includes(EntityModules.Events)}
      />

      <Route component={NotFound} data-test-id="dashboard-not-found" />
    </Switch>
  </RoutingContainer>
);

Routing.defaultProps = {
  session: (null as unknown) as SessionStoreWithUser,
  notificationSettings: (null as unknown) as NotificationSettingsStore,
};

export default inject('session')(observer(Routing));
