import React from 'react';

import Record from 'vatix-ui/lib/containers/Record/Record';

import { MainHeader } from 'components/MainHeader/MainHeader';

import routes from 'core/routes';
import { useNavBarHeight } from 'utils/hooks/navbar';

import DeviceList from './components/DeviceList/DeviceList';

const Devices = (): React.ReactElement => {
  const navBarHeight = useNavBarHeight();

  return (
    <Record
      navBarHeight={navBarHeight}
      Content={(onError) => <DeviceList onError={onError} />}
      Header={
        <MainHeader
          title="Devices"
          breadcrumbs={[
            { label: 'Lone Working', link: routes.dashboard.alarms.red.toString() },
            { label: 'Devices', link: routes.dashboard.alarms.devices.toString() },
          ]}
        />
      }
    />
  );
};

export default Devices;
