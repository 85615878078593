import styled from 'styled-components';

import { Button } from 'components/Button';

export const CardsContainer = styled.div<{
  $addTopMargin?: boolean;
}>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: ${({ $addTopMargin }) => ($addTopMargin ? '16px' : '0')};
`;

export const CardCell = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
  flex: 1;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  background: #fff;
  flex-wrap: wrap;
  @media (max-width: 950px) {
    flex-basis: 100%;
  }
  justify-content: center;
  text-align: flex-start;
`;

export const CardTitle = styled.h3`
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.15px;
  margin: 0;
`;

export const CardDescription = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
  margin-top: 10px;
  margin-bottom: 24px;
`;

export const CardButtonLink = styled.a`
  text-decoration: none;
`;

export const CardButton = styled(Button)`
  display: flex;
`;

export const MobileBadgesContainer = styled.div`
  display: flex;
  gap: 18px;
`;
