import React from 'react';

import LinearProgress from '@mui/material/LinearProgress';

import { CircularProgress } from '@mui/material';

import { observer } from 'mobx-react';

import { Button } from 'components/Button';

import { useStore } from 'utils/hooks/store';

import { StyledModalActions, BaseInfoText } from './styles';

type SumUpStepProps = {
  isSubmitting: boolean;
  onSubmit: () => Promise<void>;
  setSumUpStep: React.Dispatch<React.SetStateAction<boolean>>;
};

const SumUpStep: React.FC<SumUpStepProps> = ({ isSubmitting, onSubmit, setSumUpStep }): React.ReactElement => {
  const { userList } = useStore();

  const onBack = (): void => setSumUpStep(false);

  return (
    <>
      {isSubmitting ? (
        <>
          <LinearProgress
            variant="determinate"
            value={userList.uploadCount === 0 ? 0 : (userList.uploadCount / userList.usersToUpload.length) * 100}
          />
          <BaseInfoText>{`Importing ${userList.uploadCount} of ${userList.usersToUpload.length} users`}</BaseInfoText>
        </>
      ) : (
        <BaseInfoText>{`This import will create ${userList.usersToUpload.length} new users.`}</BaseInfoText>
      )}

      <StyledModalActions $justify="flex-end">
        <Button type="button" variant="outlined" id="cancel-button" onClick={onBack} size="large">
          Back
        </Button>
        <Button
          type="submit"
          id="add-user-button"
          variant="contained"
          size="large"
          style={{ marginLeft: '8px' }}
          onClick={onSubmit}
          disabled={isSubmitting}
        >
          {isSubmitting && <CircularProgress color="inherit" size="16px" style={{ marginRight: '8px' }} />}
          Import
        </Button>
      </StyledModalActions>
    </>
  );
};

export default observer(SumUpStep);
