import React from 'react';
import { observer } from 'mobx-react';

import { GridEventListener } from '@mui/x-data-grid';

import { reverse } from 'named-urls';

import { Button } from 'components/Button';

import { useStore } from 'utils/hooks/store';

import Grid from 'components/Grid';

import { getEntityTranslation } from 'stores/EntityDetails/utils';

import routes from 'core/routes';

import { EntityModules } from 'core/constants';

import { EntityRelatedTabProps } from 'components/Entities/types';

import DataError from 'containers/Error/DataError';

import AbstractWrapper from '../AbstractWrapper';
import { defaultProps } from '../types';
import { basicColumnsForEntity } from './utils';

const EntitiesRelationWrapper: React.FunctionComponent<EntityRelatedTabProps & { module: EntityModules }> = ({
  onError,
  store,
  module,
}) => {
  const { routing } = useStore();
  // This module is not implemented yet
  if (module === EntityModules.Risks) return null;

  const { details, entityType } = store;
  const { plural } = getEntityTranslation[module];

  // check if module is a valid entity module
  if (!Object.values(EntityModules).includes(module)) return <DataError />;

  const redirectOnClick: GridEventListener<'rowClick'> = (entity): void =>
    routing.push(reverse(routes.dashboard[module].details, { entityId: entity.row.uuid }));

  const redirectToAll = (): void => {
    // @ts-ignore
    routing.push(reverse(routes.dashboard[entityType][`related${plural}`], { entityId: details?.uuid || '' }));
  };

  return (
    <AbstractWrapper
      key={`module-${module}`}
      headerTitle={plural}
      gridElement={
        <Grid
          onError={onError}
          onRowClick={redirectOnClick}
          basicColumns={basicColumnsForEntity(module)}
          dataURL={`/api/entities/${entityType}/${details?.uuid}/${module}/`}
          {...defaultProps}
        />
      }
      footerAction={
        <Button variant="text" size="medium" onClick={redirectToAll}>
          View all...
        </Button>
      }
    />
  );
};

const EntitiesRelatedToEntities: React.FunctionComponent<EntityRelatedTabProps> = ({ onError, store }) => {
  const {
    session: { user },
  } = useStore();
  if (!user) return <DataError />;

  const relatedEntities = store.details?.related || [];
  const commonElements = relatedEntities.filter((element) => user.activeEntityModules.includes(element));

  return commonElements.map((module) => <EntitiesRelationWrapper onError={onError} store={store} module={module} />);
};

export default observer(EntitiesRelatedToEntities);
