import React from 'react';

import { observer } from 'mobx-react';

import { reverse } from 'named-urls';

import { Link } from 'react-router-dom';

import EventItem from 'vatix-ui/lib/components/Feed/EventItem';

import routes from 'core/routes';

import { StateActivityResponse, UuidableName } from 'utils/api/types';

import { TaskLink } from './styles';

interface StateProps {
  activity: StateActivityResponse;
  model: 'task';
  sessionUser: UuidableName;
}

const State: React.FunctionComponent<StateProps> = ({ activity, model, sessionUser }) => (
  <EventItem activity={activity} sessionUser={sessionUser}>
    created task{' '}
    {model !== 'task' && (
      <TaskLink to={reverse(routes.dashboard.tasks.details, { taskId: activity.task.uuid })} as={Link}>
        {activity.task.name}
      </TaskLink>
    )}
  </EventItem>
);

export default observer(State);
