import React from 'react';
import { observer } from 'mobx-react';
import AddCommentActivities from 'vatix-ui/lib/components/AddCommentActivities';
import FeedComponent from 'vatix-ui/lib/components/Feed';
import { DefaultFeedMapping } from 'vatix-ui/lib/components/Feed/Feed';

import { CenteredCircularProgress } from 'components/Progress';

import { SessionStoreWithUser } from 'stores/Session/types';
import ReportActivitiesStore from 'stores/IncidentDetails/ReporterActivities';
import API from 'utils/api';
import { useStore } from 'utils/hooks/store';

import { ScrollableContainer, CommentLayout } from 'containers/UserDetails/components/UserActivities/styles';

import { USER_TAG_BASE } from 'core/constants';

import { ActivityMapping } from 'utils/api/types';

import { Container, Title } from './styles';

const PADDING_BOTTOM_ACTIVITIES = 120;

const activityTypeToComponent = ({
  ...DefaultFeedMapping,
} as unknown) as ActivityMapping;

const AdditionalComments: React.FunctionComponent = (): React.ReactElement => {
  const scrollableRef = React.useRef<HTMLDivElement | null>(null);
  const [keepBottom, setKeepBottom] = React.useState(true);
  const { incidentDetails, session: sessionRaw } = useStore();
  const session = sessionRaw as SessionStoreWithUser;
  const activities = incidentDetails.reportActivities as ReportActivitiesStore;

  const onScroll = (e: React.UIEvent<HTMLDivElement>): void => {
    // @ts-ignore
    const bottom = e.target.clientHeight;
    // @ts-ignore
    const scrolledAt = e.target.scrollHeight - e.target.scrollTop;
    const shouldKeepBottom = scrolledAt - bottom <= PADDING_BOTTOM_ACTIVITIES;

    if (shouldKeepBottom !== keepBottom) {
      setKeepBottom(shouldKeepBottom);
    }
  };

  const onSizeChanged = (newHeight: number): void => {
    if (keepBottom && scrollableRef.current) {
      scrollableRef.current.scrollTop = newHeight;
    }
  };

  React.useEffect(() => {
    if (activities.activities === undefined && !activities.isLoading) {
      activities.loadActivities();
    }
  }, [activities.activities, activities.isLoading]);

  if (activities.activities === undefined) {
    return (
      <Container>
        <CenteredCircularProgress />
      </Container>
    );
  }

  return (
    <Container>
      <Title>Additional Comments</Title>
      <ScrollableContainer
        onScroll={onScroll}
        ref={scrollableRef}
        style={{
          height: activities.activities.length > 0 ? 'fit-content' : '20px',
          maxHeight: activities.activities.length > 0 ? '500px' : '',
        }}
      >
        <CommentLayout>
          <FeedComponent
            // @ts-ignore
            originalActivities={activities}
            mapping={activityTypeToComponent}
            onDeleteComment={activities.deleteMessage}
            onDeleteFile={activities.deleteFile}
            onSizeChanged={onSizeChanged}
            usersSearch={API.searchUsers}
            sessionUser={session.user}
            downloadFile={API.downloadFile}
            userTagBaseUrl={USER_TAG_BASE}
            disableAutoScroll
          />
        </CommentLayout>
      </ScrollableContainer>
      <AddCommentActivities
        borderless
        placeholder={
          session.user.uuid === incidentDetails.details?.creator.uuid
            ? 'Add any additional notes, pictures or files'
            : 'Write a message to the reporter'
        }
        uploadFile={activities.uploadFile}
        usersSearch={API.searchUsers}
        sendMessage={activities.addMessage}
      />
    </Container>
  );
};

export default observer(AdditionalComments);
