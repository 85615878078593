import { observer } from 'mobx-react';
import { reverse } from 'named-urls';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

import { IconButton } from '@mui/material';

import formatDuration from 'format-duration';

import routes from 'core/routes';

import DeviceWhiteIcon from '../../../../components/Icons/DeviceWhite';

import TimePassed from '../../../../components/TimePassed';

import {
  PopUpRedAlertContainer,
  PopUpRedAlertPhoneNumber,
  PopUpRedAlertUserLink,
  PopUpRedAlertInfoRow,
  PopUpRedAlertAcceptButton,
  PopUpRedAlertTimePassed,
} from './styles';
import { PopUpRedAlertProps } from './types';
import { PopUpRedAlertAnimated } from './animations';

export const msToTime = (time: number): string => formatDuration(time);

const PopUpRedAlert: React.FunctionComponent<PopUpRedAlertProps> = ({
  alert,
  isMostRecent,
  ...props
}): React.ReactElement => (
  <PopUpRedAlertAnimated {...props}>
    <PopUpRedAlertContainer isMostRecent={isMostRecent}>
      <PopUpRedAlertInfoRow>
        <PopUpRedAlertUserLink
          to={reverse(routes.dashboard.admin.users.details, {
            userId: alert.user.uuid,
          })}
        >
          {`${alert.user.name} has activated alarm`}
        </PopUpRedAlertUserLink>

        {alert.device && (
          <PopUpRedAlertPhoneNumber>
            <DeviceWhiteIcon />
            {alert.device.phoneNumber}
          </PopUpRedAlertPhoneNumber>
        )}

        <PopUpRedAlertTimePassed>
          <TimePassed since={alert.activated.toDate()} formatter={msToTime} />
        </PopUpRedAlertTimePassed>

        <PopUpRedAlertAcceptButton onClick={alert.accept} disabled={alert.isUpdating} variant="contained">
          Accept
        </PopUpRedAlertAcceptButton>
        <IconButton onClick={alert.ignore} disabled={alert.isUpdating}>
          <CloseIcon htmlColor="#fff" />
        </IconButton>
      </PopUpRedAlertInfoRow>
    </PopUpRedAlertContainer>
  </PopUpRedAlertAnimated>
);

export default observer(PopUpRedAlert);
