import * as React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';

const SearchIcon: React.FunctionComponent = (props: SvgIconProps) => (
  <svg width={16.002} height={16.001} viewBox="0 0 16.002 16.001" {...props}>
    <path
      // eslint-disable-next-line max-len
      d="M14.002 15.416l-2.807-2.811a6.962 6.962 0 01-4.191 1.4 7.008 7.008 0 01-7-7 7.012 7.012 0 017-7 7.009 7.009 0 017 7 6.955 6.955 0 01-1.395 4.189l2.811 2.809a1 1 0 010 1.415 1 1 0 01-.707.292 1.007 1.007 0 01-.711-.294zm-12-8.413a5.007 5.007 0 005 5 5 5 0 005-5 5 5 0 00-5-5 5.008 5.008 0 00-5 4.998z"
      fill="#6d7883"
    />
  </svg>
);

export default SearchIcon;
