import React from 'react';

import { observer } from 'mobx-react';

import AddIcon from '@mui/icons-material/Add';

import { CenteredCircularProgress } from 'components/Progress';

import { Button } from 'components/Button';

import DataError from 'containers/Error/DataError';

import { EmptyTaskIcon } from 'components/Icons/EmptyTaskIcon';

import { TaskListProps } from './types';
import { TaskContainer, DataContainer, EmptyContainer, TasksContainer } from './styles';
import TaskListItem from '../TaskListItem';

const EmptyTaskList: React.FunctionComponent = (): React.ReactElement => (
  <EmptyContainer>
    <EmptyTaskIcon />
    <p>No tasks yet.</p>
    <p>Start by creating a task!</p>
  </EmptyContainer>
);

const TaskList: React.FunctionComponent<TaskListProps> = ({ tasks, onSelected }): React.ReactElement => {
  React.useEffect(() => {
    if (tasks.tasks === undefined) {
      tasks.loadTasks();
    }
  }, [tasks]);

  const renderTasks = (): React.ReactElement | React.ReactElement[] => {
    if (tasks.error) {
      return (
        <TaskContainer>
          <DataError onReload={tasks.loadTasks} message="We are unable to load tasks" />
        </TaskContainer>
      );
    }

    if (tasks.tasks === undefined) {
      return <CenteredCircularProgress />;
    }

    return (
      <DataContainer>
        {tasks.tasks.length === 0 ? (
          <EmptyTaskList />
        ) : (
          <TasksContainer>
            {tasks.tasks.map((task) => (
              <TaskContainer onClick={() => task.uuid && onSelected(task)} key={task.uuid}>
                <TaskListItem task={task} store={tasks} />
              </TaskContainer>
            ))}
          </TasksContainer>
        )}

        {!tasks.editingTask ? (
          <Button
            startIcon={<AddIcon />}
            onClick={() => tasks.createGhostTask()}
            variant="contained"
            sx={{ width: '50%', margin: '0 auto' }}
          >
            Add new task
          </Button>
        ) : null}
      </DataContainer>
    );
  };

  return <>{renderTasks()}</>;
};

export default observer(TaskList);
