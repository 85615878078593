import React from 'react';

import { useGridApiContext, GridFilterItem } from '@mui/x-data-grid';

import AddCircleIcon from '@mui/icons-material/AddCircle';

import { Button } from 'components/Button';

import FilterItem from './components/FilterItem';

import { ContainerAddFilters, FilterMenuContainer, NoFilters } from './styles';
import { Filter } from './types';

const FilterMenu: React.FunctionComponent = () => {
  const context = useGridApiContext();

  const firstFilterableColumn = Object.keys(context.current.state.columns.lookup).find(
    (key) => context.current.state.columns.lookup[key].filterable
  );

  const initialFilters = [
    {
      field: firstFilterableColumn || context.current.state.columns.orderedFields[0],
      operator: 'contains',
      value: null,
    },
  ];

  const [filters, setFilters] = React.useState<Filter[]>(
    context.current.state.filter.filterModel.items.length === 0
      ? initialFilters
      : context.current.state.filter.filterModel.items.map(({ field, operator, value }) => ({ field, operator, value }))
  );

  React.useEffect(() => {
    context.current.setFilterModel({
      items: filters as GridFilterItem[],
    });
  }, [filters]);

  const addFilter = (): void => {
    setFilters([...filters, { field: null, operator: null, value: null }]);
  };

  const updateFilter = (index: number, data: Filter): void => {
    setFilters([...filters.slice(0, index), data, ...filters.slice(index + 1)]);
  };

  const deleteFilter = (index: number): void => {
    setFilters(filters.filter((value, i) => i !== index));
  };

  return (
    <FilterMenuContainer>
      {filters.map((filter, index) => (
        <FilterItem
          columns={context.current.state.columns}
          filter={filter}
          key={`${filter.field}-${index}`}
          onChange={(data: Filter) => updateFilter(index, data)}
          onDelete={() => deleteFilter(index)}
        />
      ))}
      {!filters.length && <NoFilters>No filters applied</NoFilters>}
      <ContainerAddFilters>
        <Button onClick={addFilter} variant="text">
          <AddCircleIcon style={{ marginRight: '8px' }} />
          Add Filter
        </Button>
      </ContainerAddFilters>
    </FilterMenuContainer>
  );
};

export default FilterMenu;
