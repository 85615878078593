import React, { useEffect, useState } from 'react';

import { Collapse, MenuItem, Select, Tooltip } from '@mui/material';

import { ExpandLess, ExpandMore } from '@mui/icons-material';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { observer } from 'mobx-react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { FormBuilderPropertiesType, ProtectorType } from 'utils/api/types';
import { useStore } from 'utils/hooks/store';

import {
  Header,
  QuestionContainer,
  RuleHeader,
  RuleRow,
  SectionContent,
  SectionContentHeader,
  SelectedValue,
  selectStyles,
} from './styles';

type RuleSectionProps = {
  order: string[];
  properties: FormBuilderPropertiesType;
  title: string;
  description?: string;
};

const NONE_SELECT_VALUE = 'none';

const RuleSection: React.FC<RuleSectionProps> = ({ order, properties, title, description }): React.ReactElement => {
  const {
    formBuilder: { formMapping },
  } = useStore();
  const [expanded, setExpanded] = useState(true);
  const selectRef = React.useRef<HTMLDivElement>(null);
  // to avoid layout shift when tabs has changed
  const [rowWidth, setRowWidth] = useState(200);

  // Update width when the ref is available
  useEffect(() => {
    const observator = new ResizeObserver(() => {
      if (selectRef.current) {
        setRowWidth(selectRef.current.clientWidth);
      }
    });

    if (selectRef.current) {
      observator.observe(selectRef.current);
    }

    return () => observator.disconnect();
  }, []);

  const handleExpandClick = (): void => {
    setExpanded((prevState) => !prevState);
  };

  return (
    <>
      <Header $expanded={expanded} onClick={handleExpandClick} key={title}>
        {expanded ? (
          <ExpandLess aria-labelledby="show less" fillOpacity="0.54" style={{ width: '16px', height: '16px' }} />
        ) : (
          <ExpandMore aria-labelledby="show more" fillOpacity="0.54" style={{ width: '16px', height: '16px' }} />
        )}
        <div>
          <h1>{title}</h1>
          {description && <p>{description}</p>}
        </div>
      </Header>

      <Collapse in={expanded} key={`${title}_collapse`}>
        <SectionContent>
          <RuleHeader>
            <SectionContentHeader>questions</SectionContentHeader>
            <div style={{ width: '40px' }} />
            <SectionContentHeader>fields available</SectionContentHeader>
          </RuleHeader>

          {order.map((item) => {
            const field = formMapping.getFieldForQuestion(item);
            const questionType = properties[item].questionType as ProtectorType;
            const { compatibleFields, availableFields } = formMapping.getFieldsByType(questionType);

            return (
              <RuleRow key={item} ref={selectRef}>
                <QuestionContainer key={item}>{properties[item].description}</QuestionContainer>
                <ArrowForwardIcon
                  style={{ width: '24px', height: '24px', color: 'rgba(0, 0, 0, 0.38)', margin: '0 8px' }}
                />
                <Tooltip
                  title={
                    compatibleFields.length === 0
                      ? 'No compatible fields are available for this question in the Field Manager'
                      : ''
                  }
                  placement="bottom"
                >
                  <Select
                    // rowWidth - 48 is the width of the arrow
                    sx={{ ...selectStyles, maxWidth: (rowWidth - 48) / 2 || 'auto' }}
                    value={field?.key || NONE_SELECT_VALUE}
                    IconComponent={KeyboardArrowDownIcon}
                    disabled={compatibleFields.length === 0}
                    onChange={(e) =>
                      formMapping.updateFieldMapping(item, e.target.value === NONE_SELECT_VALUE ? null : e.target.value)
                    }
                    renderValue={(value) => (
                      <SelectedValue $isNone={value === 'none'}>
                        {value === NONE_SELECT_VALUE ? 'None' : compatibleFields.find((f) => f.key === value)?.name}
                      </SelectedValue>
                    )}
                  >
                    <MenuItem value={NONE_SELECT_VALUE}>None</MenuItem>
                    {compatibleFields.map(({ key, name }) => (
                      <MenuItem
                        key={key}
                        value={key}
                        disabled={!availableFields.some((f) => f.key === key) && field?.key !== key}
                        sx={{
                          maxWidth: (rowWidth - 48) / 2 || 'auto',
                          whiteSpace: 'break-spaces',
                        }}
                      >
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </Tooltip>
              </RuleRow>
            );
          })}
        </SectionContent>
      </Collapse>
    </>
  );
};

export default observer(RuleSection);
