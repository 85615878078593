import React from 'react';
import { Observer } from 'mobx-react';
import { Formik } from 'formik';

import { CircularProgress } from '@mui/material';

import { Button } from 'components/Button';

import { useStore } from 'utils/hooks/store';
import { AnswerType, AnswerTypes, IncidentResponse, ProtectorType, UuidableName } from 'utils/api/types';

import IncidentFormField from 'containers/IncidentDetails/components/IncidentFormField/IncidentFormField';

import CustomUserDropdownField from 'containers/IncidentDetails/components/CustomUserDropdownField';

import CustomTextInputField from 'containers/IncidentDetails/components/CustomTextInputField';

import EditEntityRow from 'components/EditEntityRow';

import EntitySearchField from 'components/EntitySearchField';

import { EntityModules } from 'core/constants';

import { useEntityDisplayValue } from 'components/Entities/EntityDisplayValue/utils';

import { SectionGrid } from './styles';

const EditIncidentForm: React.FunctionComponent<{ setEditing: React.Dispatch<React.SetStateAction<boolean>> }> = ({
  setEditing,
}): React.ReactElement => {
  const {
    incidentDetails: { details: detailsRaw },
    incidentDetails,
    session,
  } = useStore();
  const details = detailsRaw as IncidentResponse;
  const { schema, content } = details.details;

  const [data, setData] = React.useState(content as AnswerType);
  const [subject, setSubject] = React.useState(details.subject);
  const [owner, setOwner] = React.useState<string | null>(details.owner ? details.owner.uuid : null);
  const [relatedEntities, setRelatedEntities] = React.useState<{
    site: UuidableName | null;
    asset: UuidableName | null;
    supplier: UuidableName | null;
    risk: UuidableName | null;
  }>({
    site: details.links.find((link) => link.entity === 'sites')?.instance as UuidableName,
    asset: details.links.find((link) => link.entity === 'assets')?.instance as UuidableName,
    supplier: details.links.find((link) => link.entity === 'suppliers')?.instance as UuidableName,
    risk: details.links.find((link) => link.entity === 'risks')?.instance as UuidableName,
  });

  const restoreSchema = (): void => {
    incidentDetails.setEditedContent(null);
    setEditing(false);
  };

  const updateField = (section: string | undefined, field: string, value: AnswerTypes): void => {
    if (section) {
      setData(
        (prevState) =>
          ({
            ...prevState,
            [section]: { ...((prevState[section] as AnswerType) || {}), [field]: value },
          } as AnswerType)
      );
      incidentDetails.setEditedContent({
        ...data,
        [section]: { ...((data[section] as AnswerType) || {}), [field]: value },
      } as AnswerType);
    } else {
      setData(
        (prevState) =>
          ({
            ...prevState,
            [field]: value,
          } as AnswerType)
      );
      incidentDetails.setEditedContent({
        ...data,
        [field]: value,
      } as AnswerType);
    }
  };

  const onUpdate = async (newData: AnswerType): Promise<void> => {
    if (incidentDetails.checkContent(newData, schema, true)) {
      const res = await incidentDetails.updateIncidentData({
        details: {
          schema,
          content: newData,
        },
        subject,
        owner,
        site: relatedEntities.site ? relatedEntities.site.uuid : null,
        asset: relatedEntities.asset ? relatedEntities.asset.uuid : null,
        supplier: relatedEntities.supplier ? relatedEntities.supplier.uuid : null,
        risk: relatedEntities.risk ? relatedEntities.risk.uuid : null,
      });

      setEditing(!res);
    }
  };

  return (
    <Formik initialValues={data} onSubmit={onUpdate}>
      {({ isSubmitting }) => (
        <Observer>
          {() => (
            <>
              <EditEntityRow name="Name">
                <CustomTextInputField
                  value={subject}
                  onChange={(value) => {
                    setSubject((value as unknown) as string);
                    if (value === details.subject) {
                      incidentDetails.setContentOfIncidentWasEdited(false);
                    } else {
                      incidentDetails.setContentOfIncidentWasEdited(true);
                    }
                  }}
                  description="Subject"
                />
              </EditEntityRow>

              <EditEntityRow name="Owner">
                <CustomUserDropdownField
                  value={owner ? `user:${owner}` : ''}
                  description="Owner"
                  onChange={(value) => {
                    const newValue = value ? (value as string).split(':')[1] : null;
                    setOwner(newValue);
                    if (newValue === details.owner?.uuid) {
                      incidentDetails.setContentOfIncidentWasEdited(false);
                    } else {
                      incidentDetails.setContentOfIncidentWasEdited(true);
                    }
                  }}
                />
              </EditEntityRow>

              {session.user?.activeEntityModules
                // filter out event module
                .filter((val) => val !== EntityModules.Events)
                .map((module) => {
                  const { entityLink, singular } = useEntityDisplayValue(module, details.links);

                  return (
                    <EditEntityRow name={singular} id={`related-${singular}-edit-label`} key={singular}>
                      <EntitySearchField
                        field={singular.toLowerCase()}
                        data={entityLink?.instance as UuidableName}
                        updateField={(field: string, value: UuidableName | null) => {
                          const formattedData = value || null;
                          setRelatedEntities((prevState) => ({
                            ...prevState,
                            [singular.toLowerCase()]: formattedData,
                          }));
                        }}
                        module={module}
                      />
                    </EditEntityRow>
                  );
                })}

              {schema.order
                .filter((orderItem) => schema.properties[orderItem].protectorType !== ProtectorType.Section)
                .map((outerOrderKey) => {
                  const { description, items, maximum, minimum, lookupType } = (schema.properties || {})[outerOrderKey];
                  return (
                    <EditEntityRow key={`edit-${outerOrderKey}`} id={`edit-${outerOrderKey}`} name={description}>
                      <IncidentFormField
                        editable
                        type={schema.properties[outerOrderKey].protectorType as ProtectorType}
                        schema={schema.properties[outerOrderKey]}
                        originalValue={content[outerOrderKey] || ''}
                        value={data[outerOrderKey] || ''}
                        onChange={(value) => updateField(undefined, outerOrderKey, value)}
                        items={items}
                        description={description}
                        maximum={maximum}
                        minimum={minimum}
                        requiredNotFilled={false}
                        lookupType={lookupType}
                      />
                    </EditEntityRow>
                  );
                })}

              <SectionGrid item container xs={12} marginTop={2} justifyContent="end">
                <Button
                  disabled={isSubmitting}
                  onClick={restoreSchema}
                  style={{
                    marginRight: '16px',
                  }}
                  variant="outlined"
                  size="large"
                >
                  Cancel
                </Button>
                {isSubmitting ? (
                  <Button
                    disabled
                    startIcon={<CircularProgress size={24} color="inherit" />}
                    variant="contained"
                    size="large"
                  >
                    Saving...
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    onClick={() => {
                      onUpdate(data);
                    }}
                    variant="contained"
                    size="large"
                  >
                    Save
                  </Button>
                )}
              </SectionGrid>
            </>
          )}
        </Observer>
      )}
    </Formik>
  );
};

export default EditIncidentForm;
