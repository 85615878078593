import React from 'react';
import { observer } from 'mobx-react';

import { formatDuration } from 'utils/formatters/time';

import { useStore } from 'utils/hooks/store';

import { RedAlertStatus } from 'core/constants';

import {
  AlarmActionsContainer,
  AcceptedChip,
  Duration,
  DurationContainer,
  PendingChip,
  StyledAlarmsIcon,
  StyledButton,
} from './styles';

const DurationItem: React.FC<{ duration: string }> = ({ duration }): React.ReactElement => (
  <DurationContainer>
    <StyledAlarmsIcon />
    <Duration>{duration}</Duration>
  </DurationContainer>
);

const AlarmActions: React.FunctionComponent = (): React.ReactElement | null => {
  const {
    alarmDetails: { details, accept },
    ui: { openResolutionDialog },
  } = useStore();
  const [accepting, setAccepting] = React.useState(false);
  const [duration, setDuration] = React.useState('');
  React.useEffect(() => {
    const interval = setInterval(() => {
      const seconds = (Date.now() - new Date(details?.activated as string).getTime()) / 1000;
      setDuration(formatDuration(seconds));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [details?.activated]);

  const acceptAlarm = async (): Promise<void> => {
    try {
      setAccepting(true);
      await accept(details?.uuid as string);
    } finally {
      setAccepting(false);
    }
  };

  if ([RedAlertStatus.Pending, RedAlertStatus.Accepted].includes(details?.alertStatus as RedAlertStatus)) {
    return (
      <AlarmActionsContainer>
        {details?.alertStatus === RedAlertStatus.Pending && (
          <PendingChip>
            <DurationItem duration={duration} />
            <StyledButton disabled={accepting} onClick={acceptAlarm} color="error" variant="contained">
              Accept
            </StyledButton>
          </PendingChip>
        )}
        {details?.alertStatus === RedAlertStatus.Accepted && (
          <AcceptedChip>
            <DurationItem duration={duration} />
            <StyledButton onClick={() => openResolutionDialog(details)} color="error" variant="contained">
              Complete
            </StyledButton>
          </AcceptedChip>
        )}
      </AlarmActionsContainer>
    );
  }
  return null;
};

export default observer(AlarmActions);
