import React from 'react';

import Record from 'vatix-ui/lib/containers/Record/Record';

import { MainHeader } from 'components/MainHeader/MainHeader';

import routes from 'core/routes';

import { useNavBarHeight } from 'utils/hooks/navbar';

import LicensesList from './components/LicensesList';

const Licenses: React.FunctionComponent = (): React.ReactElement => {
  const navBarHeight = useNavBarHeight();

  return (
    <Record
      Header={
        <MainHeader
          title="Licences"
          breadcrumbs={[
            { label: 'Lone Working', link: routes.dashboard.admin.licenses.toString() },
            { label: 'Licences', link: routes.dashboard.admin.licenses.toString() },
          ]}
        />
      }
      Content={(onError) => <LicensesList onError={onError} />}
      navBarHeight={navBarHeight}
    />
  );
};

export default Licenses;
