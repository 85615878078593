import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { reverse } from 'named-urls';

import DetailsLoader from 'vatix-ui/lib/components/DetailsLoader';

import Record from 'vatix-ui/lib/containers/Record/Record';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import { IconButton } from '@mui/material';

import MainHeader from 'components/MainHeader';
import { CenteredCircularProgress } from 'components/Progress';
import { Button } from 'components/Button';

import NotFound from 'containers/Error/Pages/NotFound/NotFound';

import DataError from 'containers/Error/DataError';

import GreenCheck from 'components/Icons/GreenCheck';
import WhiteCheck from 'components/Icons/WhiteCheck';
import routes from 'core/routes';
import { isNotFound } from 'utils/api/errors';
import { TaskListResponse } from 'utils/api/types';
import { useNavBarHeight } from 'utils/hooks/navbar';
import { useStore } from 'utils/hooks/store';

import CustomModal from 'components/CustomModal';

import { ButtonWrapper, StyledPopover, MenuItem, MarkCompletedButton, CompletedButton, ModalText } from './styles';
import TaskProperties from './components/TaskProperties';
import { TaskActivitiesContent, TaskActivitiesFooter } from './components/TaskActivities';

const TaskDetails: React.FunctionComponent = (): React.ReactElement => {
  const { taskDetails } = useStore();
  const match = useRouteMatch();
  const history = useHistory();
  const [deletionModalOpen, setDeletionModalOpen] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const navBarHeight = useNavBarHeight();

  const handleClose = (): void => {
    if (!deleting) {
      setDeletionModalOpen(false);
    }
  };

  const onDelete = async (): Promise<void> => {
    setDeleting(true);
    const res = await taskDetails.deleteTask(taskDetails.details?.uuid as string);
    if (res) {
      history.push(reverse(routes.dashboard.tasks.all.toString()));
    }
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const completeAction = async (): Promise<void> => {
    await taskDetails.updateTask(({
      status: 'completed',
    } as unknown) as Partial<TaskListResponse>);
  };

  return (
    <DetailsLoader
      match={match}
      // @ts-ignore
      routeParam="taskId"
      store={taskDetails}
    >
      {({ isLoaded, error, reload }): React.ReactElement => {
        if (!isLoaded) {
          return <CenteredCircularProgress />;
        }

        if (error) {
          if (isNotFound(error)) {
            return <NotFound />;
          }
          return <DataError onReload={reload} />;
        }

        return (
          <Record
            Header={
              <MainHeader
                action={
                  <div style={{ display: 'flex' }}>
                    {taskDetails.details?.status !== 'completed' ? (
                      <MarkCompletedButton onClick={completeAction} variant="outlined" size="large" color="success">
                        <GreenCheck />
                        Mark as Complete
                      </MarkCompletedButton>
                    ) : (
                      <CompletedButton size="large" color="success" variant="contained">
                        <WhiteCheck />
                        Completed
                      </CompletedButton>
                    )}
                    <ButtonWrapper>
                      <IconButton onClick={handleClick}>
                        <MoreVertIcon htmlColor="rgba(0, 0, 0, 0.54)" />
                      </IconButton>
                    </ButtonWrapper>
                    <StyledPopover
                      id="popover-remove_action"
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      onClose={() => setAnchorEl(null)}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                    >
                      <MenuItem id="remove-button" onClick={() => setDeletionModalOpen(true)}>
                        Delete
                      </MenuItem>
                    </StyledPopover>
                  </div>
                }
                title={taskDetails.details?.name as string}
                breadcrumbs={[
                  {
                    label: 'Actions',
                    link: routes.dashboard.tasks.all.toString(),
                  },
                  {
                    label: 'Tasks',
                    link: routes.dashboard.tasks.all.toString(),
                  },
                  {
                    label: 'Details',
                    link: reverse(routes.dashboard.tasks.details, { taskId: taskDetails.details?.uuid as string }),
                  },
                ]}
              />
            }
            disableMaxContentHeight
            Content={() => (
              <>
                <TaskProperties />

                <CustomModal
                  openModal={deletionModalOpen}
                  onClose={handleClose}
                  modalTitle="Delete Task"
                  modalWidth="xs"
                  cancelButton={
                    <Button
                      disabled={deleting}
                      onClick={handleClose}
                      autoFocus
                      id="cancel-button"
                      variant="outlined"
                      size="large"
                    >
                      Cancel
                    </Button>
                  }
                  submitButton={
                    <Button
                      onClick={onDelete}
                      disabled={deleting}
                      id="confirm-delete-button"
                      variant="contained"
                      size="large"
                      color="error"
                    >
                      Delete
                    </Button>
                  }
                >
                  <ModalText>Are you sure you want to delete this record?</ModalText>
                  <ModalText>Once removed, you will not be able to recover this data.</ModalText>
                </CustomModal>
              </>
            )}
            navBarHeight={navBarHeight}
            Activities={{
              Content: <TaskActivitiesContent />,
              Footer: <TaskActivitiesFooter />,
            }}
          />
        );
      }}
    </DetailsLoader>
  );
};

export default TaskDetails;
