/* eslint-disable max-len */

import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function Protector(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon viewBox="0 0 28 28" width="28" height="28" {...props}>
      <defs>
        <style>{'.Protector-b{fill:url(#a);}'}</style>
        <linearGradient id="a" x1="-7.294" y1="-5.096" x2="-7.277" y2="-5.096" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#4ba0fd" />
          <stop offset="1" stopColor="#097eff" />
        </linearGradient>
      </defs>
      <g transform="translate(-803 -191)">
        <path
          className="Protector-b"
          d="M12.521,27.96.04,15.48a.139.139,0,0,1,.1-.236H6.776a.139.139,0,0,1,.1.04L13.4,21.809a.139.139,0,0,0,.195,0l1.561-1.561a.135.135,0,0,0,0-.195l-.68-.684a.143.143,0,0,1,0-.2l3.888-3.887a.138.138,0,0,1,.1-.04H25.1a.14.14,0,0,1,.1.238L12.717,27.96a.141.141,0,0,1-.2,0Zm0-10.543L9.2,14.1a.14.14,0,0,1,0-.2l3.319-3.32a.14.14,0,0,1,.2,0l3.32,3.32a.14.14,0,0,1,0,.2l-3.32,3.319a.137.137,0,0,1-.2,0Zm5.941-4.659a.137.137,0,0,1-.1-.041L11.839,6.191a.14.14,0,0,0-.2,0l-1.56,1.561a.138.138,0,0,0,0,.2l.68.68a.142.142,0,0,1,0,.2L6.872,12.718a.139.139,0,0,1-.1.041H.139a.141.141,0,0,1-.1-.239L12.521.042a.135.135,0,0,1,.2,0L25.2,12.52a.14.14,0,0,1-.1.239Z"
          transform="translate(804.4 191)"
        />
      </g>
    </SvgIcon>
  );
}

export default Protector;
