import React from 'react';

import { reverse } from 'named-urls';

import { IconButton, MenuItem } from '@mui/material';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import { useHistory } from 'react-router-dom';

import MainHeader from 'components/MainHeader';

import { useStore } from 'utils/hooks/store';

import RemoveEntityModal from 'components/RemoveEntityModal';

import { getEntityTranslation } from 'stores/EntityDetails/utils';

import { DetailsHeaderWrapper, StyledPopover, ButtonWrapper } from './styles';
import { EntityDetailsHeaderProps } from './types';

const EntityDetailsHeader: React.FC<EntityDetailsHeaderProps> = ({ store, title, columns }) => {
  if (!store.details) {
    return null;
  }
  const type = store.entityType;

  const entityTranslation = getEntityTranslation[type];

  const { uuid, entity } = store.details;

  const history = useHistory();
  const { notification } = useStore();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const [openDeleteModal, setOpenDeleteModal] = React.useState<boolean>(false);

  const onDelete = async (): Promise<void> => {
    const deleted = await store.delete(uuid);
    if (deleted) {
      history.push(reverse(store.path));
      notification.enqueueSuccessSnackbar(`${entityTranslation.singular} deleted successfully`);
      setOpenDeleteModal(false);
    } else {
      notification.enqueueErrorSnackbar(
        `The ${entityTranslation.singular} could not be deleted. Something went wrong.`
      );
    }
  };

  const openModal = (): void => {
    setAnchorEl(null);
    setOpenDeleteModal(true);
  };

  const closeModal = (): void => {
    setOpenDeleteModal(false);
  };

  if (!entity) {
    return <></>;
  }

  return (
    <>
      <MainHeader
        title={title}
        breadcrumbs={[
          {
            label: entityTranslation.plural,
            link: store.path,
          },
          {
            label: 'Details',
            link: store.detailsPath(uuid),
          },
        ]}
        action={
          <>
            <ButtonWrapper>
              <IconButton onClick={handleClick}>
                <MoreVertIcon htmlColor="rgba(0, 0, 0, 0.54)" />
              </IconButton>
            </ButtonWrapper>
            <StyledPopover
              data-testid={`${type}-details-header-popover`}
              id={`popover-${type}-details`}
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <MenuItem id="remove-button" onClick={openModal}>
                Delete
              </MenuItem>
            </StyledPopover>

            {openDeleteModal && (
              <RemoveEntityModal
                onClose={closeModal}
                handleRemoveEntity={onDelete}
                entityType={entityTranslation.singular}
              />
            )}
          </>
        }
      />
      {columns && (
        <DetailsHeaderWrapper>
          {columns.map((column, index) => (
            <div key={index} id={`${type}-details-header-${column.name.toLowerCase()}`}>
              <h1>{column.name}</h1>
              {column.value}
            </div>
          ))}
        </DetailsHeaderWrapper>
      )}
    </>
  );
};

export default EntityDetailsHeader;
