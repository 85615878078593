import React from 'react';

import DialogActions from '@mui/material/DialogActions';
import { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar';

import { Button } from 'components/Button';

function CustomActionBar({ onAccept }: PickersActionBarProps): React.ReactElement {
  return (
    <DialogActions
      style={{
        gridColumn: '1/4',
        gridRow: '4',
        padding: '0',
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
      }}
    >
      <Button onClick={onAccept} size="large">
        OK
      </Button>
    </DialogActions>
  );
}

export default CustomActionBar;
