import React from 'react';
import styled from 'styled-components';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import vatixTheme from '../../../theme/vatix';

export const StyledTableRow = styled(TableRow)`
  cursor: pointer;
`;

export const EnhancedTableRowCell = styled(({ ...props }) => <TableCell {...props} />).attrs({
  align: 'left',
})`
  color: ${vatixTheme.palette.secondary['100']};
  font-size: 1.3rem;
  flex-wrap: wrap;
  letter-spacing: -0.31px;
  line-height: 1.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`;
