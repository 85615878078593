import React from 'react';
import { observer } from 'mobx-react';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { Button } from 'components/Button';

import { InformationContainer } from './styles';
import { StyledModalActions } from '../UploadCSVStep/styles';

type SuccessModalProps = {
  onClose: () => void;
};

const SuccessModal: React.FC<SuccessModalProps> = ({ onClose }): React.ReactElement => (
  <>
    <InformationContainer $error={false}>
      <CheckCircleOutlineIcon style={{ color: 'rgba(46, 125, 50)' }} />
      <div>
        <h1>Import Successful</h1>
        <p>All users have been successfully imported.</p>
      </div>
    </InformationContainer>
    <StyledModalActions $justify="flex-end">
      <Button
        type="submit"
        id="ok-button"
        variant="contained"
        size="large"
        style={{ marginLeft: '8px' }}
        onClick={onClose}
      >
        Close
      </Button>
    </StyledModalActions>
  </>
);

export default observer(SuccessModal);
